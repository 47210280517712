<template>
<!-- Content wrapper scroll start -->
 <div class="content-wrapper-scroll">

    <!-- Content wrapper start -->
    <div class="content-wrapper">

      <!-- form row -->
        <div class="row">
            <div class="col-sm-4">
                <div class="card">
                <div class="card-header">
                            <h5>New Component</h5>
                        </div>
                    <div class="card-body">
                      <!-- Field wrapper start -->
                        <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.component_type_name}" placeholder="Name" v-model="componentType.component_type_name" ref="component_type_name">
                            <span v-if="errors.component_type_name" class="invalid-feedback">{{ errors.component_type_name[0] }}</span>
                            <div class="field-placeholder">Component Name <span class="text-danger">*</span>
                            </div>
                          
                        </div>
                        <!-- Field wrapper end -->


                        <!-- Field wrapper start -->
                        <div class="field-wrapper">
                            <textarea class="form-control" rows="2" :class="{'is-invalid': errors.description}" placeholder="description" v-model="componentType.description" ref="description"></textarea>
                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                            <div class="field-placeholder">Description <span class="text-danger">*</span>
                            </div>
                           
                        </div>
                        <!-- Field wrapper end -->
                      

                         <div class="form-actions-footer">
                                <div class="text-end">
                                    <button class="btn btn-danger" @click="reset">Discard</button>
                                    <button v-can="'component_types.create'" class="btn btn-primary ms-1" v-if="status" @click="addcomponent">Submit</button>
                                    <button v-can="'component_types.update'" class="btn btn-primary ms-1" v-else @click="updatecomponent">Update</button>
                                </div>
                        </div>    
                                              
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="card">
                 <div class="card-header">
                            <h5>Component Types</h5>
                        </div>
                    <div class="card-body">
                        <div class="col-sm-12">
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                            </div>
                        </div>

                        <div class="table-responsive" style="margin-top: 10px;">
                            <div id="copy-print-csv_wrapper"
                                class="dataTables_wrapper dt-bootstrap4 no-footer">
                               
                                   
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('component_type_name')">
                                                Component
                                            <span>
                                                    <i v-if="meta.field=='component_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='component_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th @click="sort('description')">
                                                Description
                                             <span>
                                                    <i v-if="meta.field=='description' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='description' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="componentss.length==0">
                                            <td  colspan="4" class="text-center">No records found</td>
                                        </tr>
                                   <tr v-for="(componentType,key) in componentss" :key="componentType.component_type_id">
                                           
                                           <td>{{ meta.from+key }}</td>
                                            <td>{{componentType.component_type_name}}</td>                                        
                                            <td>{{componentType.description}}</td>
                                            
                                            <td class="td-actions">
                                                <a href="#" v-can="'component_types.update'" class="icon green" @click.prevent="edit(componentType)">
                                                    <i class="icon-pencil"></i>
                                                </a>
                                                <a href="#" v-can="'component_types.delete'" class="icon red" @click.prevent="deletecomponent(componentType.component_type_id)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                            
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>    
                             <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                  </div>
       </div>
   </div>

</div>
</div>

    <!-- form row end -->

</div>

</template>
<script>
import Pagination from '@/components/Pagination.vue';
export default{
    components: { Pagination },
data(){
    return{
         status: true,
        componentType:{
            component_type_name:'',
            description:'',
            component_type_id:'',
        },
        errors:[],
        componentss:[],
         meta: {
                    search: "",
                    order_by: "asc",
                    field: "component_type_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
    }
},
    mounted(){
            this.index()
        },
        methods: {

            index() {
                let vm = this;
                let uri = "componentTypes?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                 "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + "&per_page=" + vm.meta.per_page;
               // let uri = "componentTypes?page=" + vm.meta.currentPage + "&search=" + vm.meta.search + "&order_by=" + vm.meta.order_by + "&keyword=" + vm.meta.keyword + "&per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri:uri})
                    .then(function (response) {
                        response.data.data;
                        vm.componentss = response.data.data;
                        vm.meta.totalRows = response.data.total;
                        vm.meta.lastPage = response.data.last_page;
                        vm.meta.from = response.data.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    console.log(vm.componentss);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

             addcomponent() {
              
                let vm = this;
                let loader = vm.$loading.show();
                //    console.log(vm.unit)
                vm.$store
                    .dispatch("post", { uri: "addComponentType", data: vm.componentType })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Component Added successfully");
                       vm.index();
                        vm.reset();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },


            updatecomponent() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateComponentType/" + vm.componentType.component_type_id, data: vm.componentType };
                vm.$store.dispatch("patch", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Component is successfully updated");
                        vm.index();
                         vm.reset();
                         vm.errors=[];
                    })
                     
                    .catch(function (error) {
                     
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deletecomponent(componentss) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteComponentType/" + componentss };
                    vm.$store.dispatch("delete", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "component is successfully deleted");
                            vm.index()
                            vm.reset();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            edit(componentType) {
                let vm = this;
                (vm.componentType = componentType), (vm.status = false), vm.$refs.name.focus();
            },
             reset(){
                let vm = this;
                vm.componentType.component_type_name = "";
                vm.componentType.description = "";
                vm.$refs.component_type_name.focus();
                vm.index();
                vm.errors=[];
                vm.status = true;
            },

             onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },
            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
            search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			} 
        },

}
</script>
<style>
.btn {
            
            margin-right: 10px;
        }
</style>