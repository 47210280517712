import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-select/dist/vue-select.css';
//----------------------
// import { abilitiesPlugin } from '@casl/vue';
// import ability from 'store.getters.ability';
//----------------------


const app = createApp(App);
app.use(store);
app.use(router);
app.use(VueLoading);
// app.use(abilitiesPlugin, ability, {
//     useGlobalProperties: true
//   })

// let inactivityTime = function () {
//     let time;
//     window.onload = resetTimer;
//     document.onmousemove = resetTimer;
//     document.onkeydown = resetTimer;
//     function logout() {
//         sessionStorage.removeItem('user')
//         store.dispatch('logout');
//         router.push('/login')
//     }
//     function resetTimer() {
//      clearTimeout(time);
//          time = setTimeout(logout, 1000*60*1) //10 minutes
//      }
//     };
//     inactivityTime();


app.directive('can', {
    mounted: function (el, binding) {
        let permissions = JSON.parse(localStorage.getItem('permissions'));
        if(permissions && permissions.length!=0) {
            let permission = permissions.filter(function (el) {
                return el == binding.value;
            });
            console.log("in main .js "+permission)
            if (!permission[0]) {
                el.parentNode.removeChild(el);
            }
        }
        else {
            el.parentNode.removeChild(el);
        }
    }
});

app.mount('#app');

