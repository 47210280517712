<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Escalation Emails</h5>
                        </div>
                        <div class="card-body">
                            <div class="row gutters">                              
                                
                              
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Section </div>
                                        <search
                                            :class="{ 'is-invalid': errors.section_id }" 
                                            :customClass="{ 'is-invalid': errors.section_id }" 
                                            :initialize="escalationConfiguration.section_id" 
                                            id="section_id"
                                            label="section_name"
                                            placeholder="Select Sections"
                                            :data="sections"
                                            @input="sections => escalationConfiguration.section_id = sections">
                                        </search>  
                                      <span v-if="errors.section_id" class="invalid-feedback">{{ errors.section_id[0] }}</span>
                                    </div>
                                </div>
                               <div class="col-sm-4 ">
                                      <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.department_id }"
                                            :customClass="{ 'is-invalid': errors.department_id }"
                                            :initialize="escalationConfiguration.department_id"
                                            id="department_id"
                                            label="department_name"
                                            placeholder="Select Department"
                                            :data="departments"
                                            @input="departments => escalationConfiguration.department_id = departments"
                                        >
                                        </search>
                                        <span v-if="errors.department_id" class="invalid-feedback">{{ errors.department_id[0] }}</span>
                                        <div class="field-placeholder">Department</div>
                                    </div>
                                 </div>
                                 <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.functional_id }"
                                            :customClass="{ 'is-invalid': errors.functional_id }"
                                            :initialize="escalationConfiguration.functional_id"
                                            id="functional_id"
                                            label="functional_name"
                                            placeholder="Select Functional"
                                            :data="functionals"
                                            @input="functionals => escalationConfiguration.functional_id = functionals"
                                        >
                                        </search>
                                        <span v-if="errors.functional_id" class="invalid-feedback">{{ errors.functional_id[0] }}</span>
                                        <div class="field-placeholder">Functional</div>
                                    </div>
                                 </div> 
                                 <div class="col-sm-4">
                                      <div class="field-wrapper"> 
                                             <div class="field-placeholder" >Notification To </div>
                                             <select class="form-control form-control-sm" style="margin-top: 19px;" :class="{ 'is-invalid': errors.escalation_matrix_id }" v-model="escalationConfiguration.escalation_matrix_id" >
                                                <option value="">Select Escalation</option>
                                                <option v-for="(escalationMat,key) in escalationMatrix" :key="key" :value="escalationMat.escalation_matrix_id">{{ escalationMat.escalation_notification}}</option>
                                            </select>
                                            <span v-if="errors.escalation_matrix_id" class="invalid-feedback">{{ errors.escalation_matrix_id[0] }}</span>
                                    </div>
                                </div>
                                 
                                <div class="col-sm-7">
                                     <div class="field-placeholder">Email </div>
                                        <input class="form-control" v-model="escalationConfiguration.email" :class="{'is-invalid': errors.email}"  type="text" placeholder="Enter email"  />
                                        <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                </div>
                                <div class="col-sm-1">
                                    <button v-if="escalationConfiguration.status" class="btn btn-primary" style="margin-top: 19px;" @click="addEscalationConfiguration">
                                                            <i class="fa fa-plus"></i>
                                                        </button>
                                                        <button v-else class="btn btn-success" @click="updateEscalationConfiguration">
                                                            <i class="icon-pencil"></i>
                                                        </button>
                                </div>
                                <div class="col-sm-12">
                                        <div class="input-group">
                                            <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                            <span class="input-group-text">
                                                <i class="fas fa-search"></i>
                                            </span>
                                        </div>
                                </div>
                                <div class="col-sm-12">
                                     <div class="table-responsive tabletop">
                                        <table class="table table-sm table-bordered">
                                            <thead>
                                                <tr style="margin-top:5px">
                                                    <th>Escalations</th>
                                                    <th>Section </th>
                                                    <th>Department </th>
                                                    <th>Functional</th>
                                                    <th>Email</th>
                                                    <th class="text-center">Action</th>
                                                   
                                                   
                                                 </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(escalationConfiguration,key) in escalationConfigurations" :key="key">
                                                    <!-- <td>{{ escalationConfiguration}}</td> -->
                                                     <td >{{ escalationConfiguration.escalation_matrix.escalation_notification }}</td>
                                                     <td v-if="escalationConfiguration.section_id!=NULL" >{{ escalationConfiguration.section.section_name }}</td>
                                                     <td v-else></td>
                                                     <td v-if="escalationConfiguration.department_id!=NULL" >{{ escalationConfiguration.department.department_name }}</td>
                                                      <td v-else></td>
                                                    <td v-if="escalationConfiguration.functional_id!=NULL">{{ escalationConfiguration.functional.functional_name }}</td>
                                                    <td v-else></td>
                                                    <td>{{ escalationConfiguration.email }}</td>
                                                   
                                                     
                                                    <td class="td-actions">
                                                        <a href="#" class="icon green" @click.prevent="editRow(escalationConfiguration,key)">
                                                            <i class="icon-pencil"></i>
                                                        </a>
                                                        <a href="#" class="icon red" @click.prevent="deleteRow(escalationConfiguration,key)">
                                                            <i class="icon-cancel"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody> 
                                        </table>
                                        <div class="form-actions-footer">
                                            <div class="text-end row">
                                                <div class="col-sm-2">
                                                    <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                                        <option>10</option>
                                                        <option>15</option>
                                                        <option>20</option>
                                                        <option>25</option>
                                                        <option>30</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-10">
                                                    <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                       
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let Search = require('@/components/Search.vue').default;
import Pagination from "@/components/Pagination.vue";
    export default {
        components: {Search,Pagination},
        data() {
            return {
                escalationConfiguration: {
                    plant_id: this.$store.getters.user.plant_id,
                    escalation_matrix_id: "",
                    section_id: "",
                    department_id: "",
                    functional_id: "",
                    email: "",
                    status:true,
                    escalation_configuration_id:""
                },
                escalationConfigurations: [],
                sections: [],
                departments: [],
                functionals: [],
                escalationMatrix: [],               
                errors: [],
                status: true,
                meta: {
					search: "",					
					order_by: "asc",
					field: "escalation_configuration_id",
					per_page: 10,
					totalRows: 0,
					page: 1,
					lastPage: 1,
					from: 1,
					to: 1,
					maxPage: 1,
					plant_id: this.$store.getters.user.plant_id,

                  

				},
            };
        },

        mounted() {
            let vm = this;
            vm.getEscalationMatrices();
            
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (to.name == "EscalationConfiguration") {
                    vm.status = true;
                    vm.escalationConfiguration.plant_id = "";
                    vm.escalationConfiguration.escalation_matrix_id = "";
                    vm.escalationConfiguration.department_id = '';
                    vm.escalationConfiguration.functional_id = "";
                    vm.escalationConfiguration.email = "";
                    vm.index();
                }
                else if (to.name == "EscalationConfiguration.Copy") {
                    vm.status = true;
                    let uri = { uri: "showEquipment/" + vm.$route.params.equipment_id };
                    vm.$store.dispatch("get", uri)
                    .then(function (response) {
                        let equipment = response.data.data;
                        vm.equipment.equipment_components = equipment.equipment_components;
                        vm.equipment.deleted_equipment_components = [];                       
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                } else {
                    vm.status = false;
                    let uri = { uri: "showEscalationConfiguration/" + vm.$route.params.equipment_id };
                    vm.$store.dispatch("get", uri)
                    .then(function (response) {
                        vm.equipment = response.data.data;
                        vm.equipment.deleted_equipment_components = [];
                       /* vm.getSections();
                        vm.getequipmentGroup();
                        vm.getequipmentType();
                        vm.getcomponentTypes();
                        // vm.getComponent();
                        vm.getCheck_Type();
                        vm.equipment_groups();
                        vm.getcomponentdata();*/
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },

        methods: {
             getEscalationMatrices() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "escalationMatrices" })
                    .then(function (response) {
                        response.data.data;
                        vm.escalationMatrix = response.data.data;
                        vm.getSections()
                       
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            
            getSections() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getSectionsPlantWise", data: vm.meta })
                    .then(function (response) {


                        vm.sections = response.data.data;
                        vm.getDepartment();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

             getDepartment() {
                let vm = this;
                vm.$store
                //.dispatch("post", { uri: "getDepartments" })
                .dispatch("post", { uri: "getDepartmentsPlantWise", data: vm.meta })
                .then(function (response) {
                    vm.departments = response.data.data;
                    vm.getFunctions();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
              

             getFunctions() {
                let vm = this;
                vm.$store
                //.dispatch("post", { uri: "getFunctionals" })
                .dispatch("post", { uri: "getFunctionalsPlantWise", data: vm.meta })
                .then(function (response) {
                    vm.functionals = response.data.data;                   
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },


            // addRow() {
            //     let vm = this;
            //     vm.$store
            //         .dispatch("post", { uri: "validationOfEquipmentComponent", data: vm.equipment_component })
            //         .then(function (response) {
            //             vm.equipment.equipment_components.push(response.data);
            //            vm.discard();
            //         })
            //         .catch(function (error) {
            //             vm.errors = error.response.data.errors;
            //             vm.$store.dispatch("error", error.response.data.message);
            //         });
            // },

             index() {
                let vm = this;
                vm.meta.plant_id=vm.$store.getters.user.plant_id              
                vm.$store
                    .dispatch("post", { uri: "escalationConfigurations", data: vm.meta })
                    .then(function (response) {
                        response.data.data;
                        vm.escalationConfigurations = response.data.data;                        
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                         vm.meta.to = response.data.meta.to;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;                      
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            editRow(escalationConfiguration, key) {
                let vm = this;
                vm.escalationConfiguration.escalation_configuration_id = escalationConfiguration.escalation_configuration_id;
                vm.escalationConfiguration.plant_id = escalationConfiguration.plant_id;
                vm.escalationConfiguration.escalation_matrix_id = escalationConfiguration.escalation_matrix_id;
                vm.escalationConfiguration.section_id = escalationConfiguration.section_id;
                vm.escalationConfiguration.department_id = escalationConfiguration.department_id;
                vm.escalationConfiguration.functional_id = escalationConfiguration.functional_id;
                vm.escalationConfiguration.email = escalationConfiguration.email;
                vm.escalationConfiguration.key = key;
                vm.escalationConfiguration.status = false;
                //console.log(vm.equipment_component);
               //vm.getcomponentdata();
            },

            // updateRow() {
            //     let vm = this;
            //     vm.changeCheckType()
            //     vm.getComponents()
            //     vm.$store
            //         .dispatch("post", { uri: "validationOfEquipmentComponent", data: vm.equipment_component })
            //         .then(function (response) {
            //             vm.equipment.equipment_components.splice(response.data.key, 1, response.data), vm.discard();
            //         })
            //         .catch(function (error) {
            //             vm.errors = error.response.data.errors;
            //             vm.$store.dispatch("error", error.response.data.message);
            //         });
            // },

            deleteRow(escalationConfiguration,key) {
               let vm = this;
               console.log(key)
                let loader = vm.$loading.show();
                let uri = { uri: "deleteEscalationConfiguration/" + escalationConfiguration.escalation_configuration_id, data: escalationConfiguration };
                vm.$store
                    .dispatch("delete", uri)
                    .then(function () {
                        loader.hide();
                    vm.$store.dispatch("success", "EscalationConfiguration deleted successfully.");
                    vm.index();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addEscalationConfiguration() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.escalationConfiguration.plant_id=this.$store.getters.user.plant_id;
                if(vm.escalationConfiguration.department_id=="")
                {
                    delete(vm.escalationConfiguration.department_id)
                }
                if(vm.escalationConfiguration.section_id=="")
                {
                    delete(vm.escalationConfiguration.section_id)
                }
                if(vm.escalationConfiguration.functional_id=="")
                {
                    delete(vm.escalationConfiguration.functional_id)
                }
                
                
                vm.$store
                    .dispatch("post", { uri: "addEscalationConfiguration", data: vm.escalationConfiguration })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "EscalationConfiguration is successfully created");
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            updateEscalationConfiguration() {
               
                let vm = this;
                console.log(vm.escalationConfiguration);
                 //alert(vm.escalationConfiguration.department_id);
                  if(vm.escalationConfiguration.department_id=="" || vm.escalationConfiguration.department_id==null  )
                {                   
                    delete(vm.escalationConfiguration.department_id )
                }
                if(vm.escalationConfiguration.section_id=="" || vm.escalationConfiguration.section_id==null)
                {
                    delete(vm.escalationConfiguration.section_id)
                }
                if(vm.escalationConfiguration.functional_id=="" || vm.escalationConfiguration.functional_id==null)
                {
                    delete(vm.escalationConfiguration.functional_id)
                }

                let loader = vm.$loading.show();
                let uri = { uri: "updateEscalationConfiguration/" + vm.escalationConfiguration.escalation_configuration_id, data: vm.escalationConfiguration };
                vm.$store
                    .dispatch("patch", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "EscalationConfiguration is successfully updated");
                        vm.index();
                       // vm.$router.push("/equipments");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            
            discard() {
                let vm = this;
                vm.equipment_component.equipment_id = "";                
                vm.equipment_component.check_type_id = "";
                vm.equipment_component.status = true;
                vm.equipment_component.key = "";
                vm.errors = [];
            },
            
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            search() {
                let vm = this;
                vm.meta.page = 1;
                vm.index();
            },

        

        },
    };
</script>
<style></style>