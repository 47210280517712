<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 v-if="status">New Template | Checklist</h5>
                            <h5 v-else>Update Template | Checklist</h5>
                        </div>
                        <div class="card-body">
                            <div class="row gutters">
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Template Name *</div>
                                        <input class="form-control" v-model="template.template_name" :class="{'is-invalid': errors.template_name}" ref="template_name" type="text" placeholder="Enter Template Name" />
                                        <span v-if="errors.template_name" class="invalid-feedback">{{ errors.template_name[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Plant *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.plant_id }" 
                                            :customClass="{ 'is-invalid': errors.plant_id }" 
                                            :initialize="template.plant_id" 
                                            id="plant_id"
                                            label="plant_name"
                                            placeholder="Select Plant"
                                            :data="plants"
                                            @input="plant => template.plant_id = plant"
                                            disabled="disabled">
                                        </search>
                                     
                                        <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Department *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.department_id }" 
                                            :customClass="{ 'is-invalid': errors.department_id }" 
                                            :initialize="template.department_id" 
                                            id="department_id"
                                            label="department_name"
                                            placeholder="Select Department"
                                            :data="departments"
                                            @input="department => template.department_id = department">
                                        </search>
                                     
                                        <span v-if="errors.department_id" class="invalid-feedback">{{ errors.department_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Functional *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.functional_id }" 
                                            :customClass="{ 'is-invalid': errors.functional_id }" 
                                            :initialize="template.functional_id" 
                                            id="functional_id"
                                            label="functional_name"
                                            placeholder="Select Functional"
                                            :data="functionals"
                                            @input="functional => template.functional_id = functional">
                                        </search>
                                     <span v-if="errors.functional_id" class="invalid-feedback">{{ errors.functional_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Category *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.category_id }" 
                                            :customClass="{ 'is-invalid': errors.category_id }" 
                                            :initialize="template.category_id" 
                                            id="category_id"
                                            label="category_name"
                                            placeholder="Select Category"
                                            :data="categories"
                                            @input="categorie => template.category_id = categorie">
                                        </search>
                                      
                                        <span v-if="errors.category_id" class="invalid-feedback">{{ errors.category_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Frequency *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.frequency_id }" 
                                            :customClass="{ 'is-invalid': errors.frequency_id }" 
                                            :initialize="template.frequency_id" 
                                            id="frequency_id"
                                            label="frequency_name"
                                            placeholder="Select Frequency"
                                            :data="frequencies"
                                            @input="frequencie => template.frequency_id = frequencie">
                                        </search>
                                      
                                        <span v-if="errors.frequency_id" class="invalid-feedback">{{ errors.frequency_id[0] }}</span>
                                    </div>
                                </div>
                                 <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Latitude</div> 
                                        <input class="form-control" v-model="template.lat" :class="{'is-invalid': errors.lat}"  type="text" placeholder="Enter Latitude" />
                                        <span v-if="errors.lat" class="invalid-feedback">{{ errors.lat[0] }}</span>                                        
                                    </div>
                                </div>
                                 <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Longitude</div> 
                                        <input class="form-control" v-model="template.long" :class="{'is-invalid': errors.long}"  type="text" placeholder="Enter Longitude" />
                                        <span v-if="errors.long" class="invalid-feedback">{{ errors.long[0] }}</span>
                                    </div>
                                </div>
                                 <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Radius</div> 
                                        <input class="form-control" v-model="template.radius" :class="{'is-invalid': errors.radius}"  type="text" placeholder="Enter Radius" />
                                        <span v-if="errors.radius" class="invalid-feedback">{{ errors.radius[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Section </div>
                                        <search 
                                            v-on:filter="sections"
                                            :class="{ 'is-invalid': errors.section_id }" 
                                            :customClass="{ 'is-invalid': errors.section_id }" 
                                            :initialize="template.section_id" 
                                            id="section_id"
                                            label="section_name"
                                            placeholder="Select Section"
                                            :data="sections"
                                            @input="selectSection($event)">
                                        </search>
                                       
                                        <span v-if="errors.section_id" class="invalid-feedback">{{ errors.section_id[0] }}</span>
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Equipment Group </div>
                                        <search 
                                           
                                            :class="{ 'is-invalid': errors.equipment_group_id }" 
                                            :customClass="{ 'is-invalid': errors.equipment_group_id }" 
                                            :initialize="template.equipment_group_id" 
                                            id="equipment_group_id"
                                            label="equipment_group_name"
                                            placeholder="Select Equipment Group"
                                            :data="equipment_groups"
                                            @input="selectEquipment_group($event)">
                                        </search>
                                    
                                        <span v-if="errors.equipment_group_id" class="invalid-feedback">{{ errors.equipment_group_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Equipment</div>
                                        <select class="form-control" :class="{'is-invalid': errors.equipment_id}" v-model="template.equipment_id" @change="addEquipment">
                                            <option value="">Select Equipment</option>
                                            <option v-for="equipment,key in equipments" :key="key" :value="equipment.equipment_id">
                                                {{ equipment.equipment_uid }} - {{ equipment.equipment_name }}
                                            </option>
                                        </select>
                                        <span v-if="errors.equipment_id" class="invalid-feedback">{{ errors.equipment_id[0] }}</span>
                                    </div>
                                </div>
                               

                            </div>
                            <div class="row gutters">
                                <div class="col-sm-12">
                                    <div class="accordion" id="myAccordion">
                                        <div class="accordion-item" v-for="(equipment,i) in selected_equipments" :key="i">
                                            
                                            <h2 class="accordion-header row" id="headingOne">
                                                <div class="col-sm-1" style="margin-top:8px">
                                                    <a href="#" @click.prevent="deleteRow(equipment,i)" style="padding:10px;color:red">
                                                        <i class="icon-cancel"></i>
                                                    </a>
                                                </div>
                                                <div class="col-sm-11">
                                                    <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" :data-bs-target="'#collapse'+i">{{i+1}}. {{equipment.equipment_uid}} - {{ equipment.equipment_name }}</button>
                                                </div>
                                            </h2>
                                            <div :id="'collapse'+i" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table class="table m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Eq. Group</th>
                                                                    <th>Eq. Type</th>
                                                                    <th>Com. Type</th>
                                                                    <th>Check Type</th>
                                                                    <th>Value</th>
                                                                    <th>LCL</th>
                                                                    <th>UCL</th>
                                                                    <th>Severity</th>
                                                                    <th class="text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                    <tr v-for="equipment_component,j in equipment.equipment_components" :key="j">
                                                                    <td>{{equipment_component.equipment_group.equipment_group_name}}</td>
                                                                    <td>{{equipment_component.equipment_type.equipment_type_name}}</td>
                                                                    <td>{{equipment_component.component_type.component_type_name}}</td>
                                                                    <td>{{equipment_component.check_type.check_type_name}}</td>
                                                                    <td v-if="equipment_component.check_type.field_type=='Number'">
                                                                        <input class="form-control" v-model="equipment_component.default_value" type="number">
                                                                    </td>
                                                                    <td v-else-if="equipment_component.check_type.field_type=='Select'">
                                                                        <div class="field-wrapper">
                                                                       
                                                                        <select class="form-control" v-model="equipment_component.default_value">
                                                                            <option value="">Select One</option>
                                                                            <option v-for="value in equipment_component.check_type.description.split(',')" :key="value">{{ value }}</option>
                                                                        </select>
                                                                        </div>
                                                                    </td>
                                                                    <td v-else>
                                                                        <input class="form-control" v-model="equipment_component.default_value" type="text">
                                                                    </td>
                                                                    <td>
                                                                        <input class="form-control" v-model="equipment_component.lcl" type="text">
                                                                    </td>
                                                                    <td>
                                                                        <input class="form-control" v-model="equipment_component.ucl" type="text">
                                                                    </td>
                                                                    <td>
                                                                        <!-- <input class="form-control" v-model="equipment_component.severity" type="text"> -->
                                                                        <select class="form-control" v-model="equipment_component.severity">
                                                                            <option value="">Select One</option>
                                                                            <option value="1">1</option>
                                                                            <option value="2">2 </option>
                                                                            <option value="3">3 </option>
                                                                            <option value="4">4 </option>
                                                                            <option value="5">5 </option>
                                                                        </select>
                                                                    </td>
                                                                    <td class="text-center">
                                                                        <input class="form-check-input" type="checkbox" disabled="disabled" :id="'check'+equipment_component.equipment_component_id" :value="equipment_component.equipment_component_id" v-model="equipment_component.status">
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions-footer mt-2">
                                <div class="text-end">
                                    <router-link to="/templates" class="btn btn-danger">Discard</router-link>
                                    <button class="btn btn-primary ms-1" v-if="status" @click="addTemplate">Submit</button>
                                    <button class="btn btn-primary ms-1" v-else @click="updateTemplate">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let Search = require('@/components/Search.vue').default;

    export default {
        components: {Search },
        data() {
            return {
                template: {
                    template_name: "",
                    category_id:"",
                    plant_id: "",
                    department_id: "",
                    functional_id: "",
                    section_id: "",
                    frequency_id: "",
                    equipment_group_id: "",
                    equipment_id:"",
                    lat:"",
                    long:"",
                    radius:"",
                    template_equipments: [],
                    deleted_template_equipments: [],
                },
                categories: [],
                plants: [],
                departments: [],
                functionals: [],
                sections: [],
                frequencies: [],
                equipment_groups: [],
                equipments: [],
                selected_equipments: [],
                errors: [],
                status: true
            };
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                if(to.name=='Templates.Create') {
                    vm.getPlats(); 
                }
                else {
                    vm.status = false;
                    vm.$store.dispatch("get", { uri: `showTemplate/${to.params.template_id}` })
                    .then(function (response) {
                        vm.getPlats();
                        Object.assign(vm.template, response.data.data);
                        Object.assign(vm.selected_equipments, vm.template.template_equipments);
                        vm.template.deleted_template_equipments = [];
                        vm.template.template_equipments = [];
                        vm.template.section_id = "";
                        vm.template.equipment_id = "";
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },

        methods: {
            getPlats() {
                let vm = this;
                vm.$store .dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data;
                    vm.getCategories();
                    if(vm.status) {
                        vm.template.plant_id = vm.$store.getters.user.plant_id
                    }
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getCategories() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getCategories" })
                .then(function (response) {
                    vm.categories = response.data.data;
                    vm.getFunctions();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getFunctions() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getFunctionals" })
                .then(function (response) {
                    vm.functionals = response.data.data;
                    vm.getDepartment();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getDepartment() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getDepartments" })
                .then(function (response) {
                    vm.departments = response.data.data;
                    vm.getSection();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getSection() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getSections" })
                .then(function (response) {
                    vm.sections = response.data.data;
                    vm.getFrequencies();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getFrequencies() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getFrequencies" })
                .then(function (response) {
                    vm.frequencies = response.data.data;
                    vm.getMasterEquipmentGroups()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getMasterEquipmentGroups() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getMasterEquipmentGroups", data: vm.template })
                .then(function (response) {
                    vm.equipment_groups = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getEquipments() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getEquipments",data:vm.template })
                .then(function (response) {
                    vm.equipments = response.data;                  
                })
                .catch(function (error) {
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addEquipment() {
                let vm = this;
                if(vm.template.equipment_id!=''){
                    let equipment = vm.equipments.filter(function (el) {
                        return el.equipment_id == vm.template.equipment_id;
                    });
                    let selected_equipment = vm.selected_equipments.filter(function (el) {
                        return el.equipment_id == vm.template.equipment_id;
                    });
                    
                    if(selected_equipment.length==0 && equipment.length!=0) {
                        equipment[0].equipment_components.map(function (el) {
                            el.status = true;
                            el.default_value = el.check_type.default_value;
                            el.lcl = el.check_type.lcl;
                            el.ucl = el.check_type.ucl;
                            el.severity = el.check_type.severity;
                        });
                        vm.validationOfTemplateEquipmentComponent(equipment[0]);
                    }
                }
            },

            validationOfTemplateEquipmentComponent(equipment) {                
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "validationOfTemplateEquipmentComponent",data: equipment })
                .then(function () {
                    loader.hide();
                    vm.selected_equipments.push(equipment);          
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteRow(equipment,key) {
                let vm = this;
                vm.template.deleted_template_equipments.push(equipment);
                vm.selected_equipments.splice(key, 1);
            },

            async addTemplate() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.template.template_equipments = [];
                await vm.selected_equipments.forEach((equipment) => {
                    let template_equipment_components = [];
                    equipment.equipment_components.forEach((equipment_component) => {
                        if(equipment_component.status==true) {
                            template_equipment_components.push(equipment_component);
                        }
                    });    
                    if(template_equipment_components.length!=0) {
                        let template_equipment = {
                            template_id:'',
                            equipment_id:equipment.equipment_id,
                            template_equipment_components:template_equipment_components,
                        }
                        vm.template.template_equipments.push(template_equipment);
                    }
                });


                //console.log('tempalte : '+vm.template)

               // let template_validate=vm.validationOfTemplateEquipmentComponent(vm.template)
               // console.log(template_validate);
             

                await vm.$store.dispatch("post", { uri: "addTemplate",data:vm.template })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch('success','Template is successfully created');
                    vm.$router.push('/templates');
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            async updateTemplate() {
                let vm = this;
                let loader = vm.$loading.show();
                await vm.selected_equipments.forEach((equipment) => {
                    let template_equipment_components = [];
                    equipment.equipment_components.forEach((equipment_component) => {
                        template_equipment_components.push(equipment_component);
                    });    
                    if(template_equipment_components.length!=0) {
                        let template_equipment = {
                            template_id:vm.template.template_id,
                            template_equipment_id:equipment.template_equipment_id,
                            equipment_id:equipment.equipment_id,
                            template_equipment_components:template_equipment_components,
                        }
                        vm.template.template_equipments.push(template_equipment);
                    }
                });
                await vm.$store.dispatch("patch", { uri: "updateTemplate/"+vm.template.template_id,data:vm.template })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch('success','Template is successfully updated');
                    vm.$router.push('/templates');
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

             selectSection(e) {
                let vm = this;
                vm.template.section_id = e;
                vm.getEquipments()
            },
              selectEquipment_group(e){
                let vm = this;
                vm.template.equipment_group_id = e;
                vm.getEquipments()
            },
        },
    };
</script>
<style></style>
