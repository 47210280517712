<template>
    <!-- Content wrapper scroll start -->
    <div class="content-wrapper-scroll">
        <!-- Content wrapper start -->
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-8">
                    <div class="card">
                        <div class="card-body">
                            <h4 class="card-title card-header-lg">Profile Update</h4>
                            <div class="row gutters">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                    <div id="dropzone-sm1" class="mb-3">
                                        <div class="text-center" v-if="user.avatar">
                                            <img width="180" :src="user.avatar" />
                                        </div>
                                        <div class="field-wrapper">
                                            <input type="file" class="form-control" id="customFile" :class="{'is-invalid': errors.avatar}" @change="onImageChange($event)" accept="image/*" />
                                        </div>
                                        <span v-if="errors.avatar" class="invalid-feedback">{{ errors.avatar[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12">
                                    <div class="row gutters">
                                        <div class="col-6">
                                            <!-- Field wrapper start -->
                                            <div class="field-wrapper">
                                                <input type="text" class="form-control" placeholder="Enter Name" :class="{'is-invalid': errors.name}" v-model="userProfile.name" ref="name" />
                                                <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                                <div class="field-placeholder">Name</div>
                                            </div>
                                            <!-- Field wrapper end -->
                                            <!-- Field wrapper start -->
                                            <div class="field-wrapper">
                                                <input type="email" class="form-control" placeholder="Enter email" :class="{'is-invalid': errors.email}" v-model="userProfile.email" ref="email" />
                                                <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                                <div class="field-placeholder">Email</div>
                                            </div>

                                            <!-- Field wrapper end -->
                                        </div>
                                        <div class="col-6">
                                            <!-- Field wrapper start -->
                                            <div class="field-wrapper">
                                                <input type="text" class="form-control" placeholder="Enter mobile number" :class="{'is-invalid': errors.mobile_number}" v-model="userProfile.mobile_number" ref="mobile_number" />
                                                <span v-if="errors.mobile_number" class="invalid-feedback">{{ errors.mobile_number[0] }}</span>
                                                <div class="field-placeholder">Mobile No.</div>
                                            </div>
                                            <!-- Field wrapper end -->
                                            <!-- Field wrapper start -->

                                            <!-- Field wrapper end -->
                                        </div>
                                    </div>
                                </div>

                                <div class="text-end">
                                    <button class="btn btn-primary ms-1 mb-3" @click.prevent="updateProfile()">Save Settings</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title card-header-lg">Update Password</h5>
                            <div class="login-wrapper">
                                <div class="login-screen" style="min-width: 0px;">
                                    <div class="login-body ">
                                        <!-- <a href="#" class="logo">
                                            <img src="/img/ultratech-cement-logo.984d6063.png" height="50" alt="Ultra Tech Cement" />
                                        </a> -->
                                        <h6>In order to update your password.</h6>
                                        <div class="field-wrapper mb-3">
                                            <input type="password" :class="{'is-invalid': errors.current_password}" autocomplete="off"  placeholder="Current Password" v-model="password.current_password" ref="current_password" />
                                            <span v-if="errors.current_password" class="invalid-feedback">{{ errors.current_password[0] }}</span>
                                            <div class="field-placeholder">Enter Old Password</div>
                                        </div>
                                        <div class="field-wrapper mb-3">
                                            <input type="password" :class="{'is-invalid': errors.new_password}" placeholder="New Password" v-model="password.new_password" ref="new_password" />
                                            <span v-if="errors.new_password" class="invalid-feedback">{{ errors.new_password[0] }}</span>
                                            <div class="field-placeholder">Enter New Password</div>
                                        </div>
                                        <div class="field-wrapper mb-3">
                                            <input type="password" :class="{'is-invalid': errors.confirm_password}" placeholder="Confirm Password" v-model="password.confirm_password" ref="confirm_password" />
                                            <span v-if="errors.confirm_password" class="invalid-feedback">{{ errors.confirm_password[0] }}</span>
                                            <div class="field-placeholder">Confirm Password</div>
                                        </div>
                                        <div class="actions">
                                            <button type="button" @click="updatepassword" class="btn btn-danger ms-auto mt-20">Update Password</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--  form end  -->

            <!-- Row end -->
        </div>
    </div>
    <!-- Content wrapper scroll end -->
</template>
<script>
    // let Search = require("@/components/Search.vue").default;
    export default {
        // components: { Search },
        data() {
            return {
                user: {
                    name: "",
                    user_id: "",
                    email: "",
                    role: "",
                    mobile_number: "",
                    role_name: "",
                    plant_name: "",
                    department_name: "",
                    avatar: "",
                },
                password: {
                    user_id: "",
                    current_password: "",
                    new_password: "",
                    confirm_password: "",
                },
                userProfile:
                {
                     user_id: "",
                     email: "",
                     name: "",
                     mobile_number: "",
                     avatar:""
                },
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getRoles();
               
               
                let loader = vm.$loading.show();
                let uri = { uri: "showUser/" + vm.$store.getters.user.user_id };
                //alert(uri);
                vm.$store
                    .dispatch("get", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.user = response.data.data;

                        vm.password.user_id = vm.user.user_id;
                        vm.userProfile.user_id=vm.user.user_id;
                        vm.userProfile.email=vm.user.email;
                        vm.userProfile.name=vm.user.name;
                        vm.userProfile.mobile_number=vm.user.mobile_number;
                        vm.userProfile.avatar=vm.user.avatar;                       
                        vm.user.avatar = vm.$store.getters.assetUrl + "/users/" + vm.user.avatar;  
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            });
        },
        methods: {
          
            updateProfile() {
                let vm = this;
                let loader = vm.$loading.show();               
                let uri = { uri: "updateProfile", data: vm.userProfile };              
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Profile is successfully updated");                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getRoles() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getRoles", data: {} })
                    .then(function (response) {
                        vm.roles = response.data.data;
                         vm.getPlants();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getPlants() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getPlants", data: {} })
                    .then(function (response) {
                        vm.plants = response.data.data;
                         vm.getDepartments();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getDepartments() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getDepartments", data: {} })
                    .then(function (response) {
                        vm.departments = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            onImageChange(e) {
                e.preventDefault();
                let vm = this;
                let image = e.target.files[0];
                let reader = new FileReader();
                reader.readAsDataURL(image);
                reader.onload = (e) => {
                    vm.user.avatar = e.target.result;
                    vm.userProfile.avatar = e.target.result;
                };
            },

            updatepassword() {
                let vm = this;
                let loader = vm.$loading.show();                
                let uri = { uri: "updatePassword", data: vm.password };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Password is successfully updated");
                        vm.$router.push("/login");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
        },
    };
</script>
<style></style>
