<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-4">
					<ul class="list-group">
						<li class="list-group-item">
							<router-link v-can="'plants.view'" :to="{ path: '/plant' }">Plants</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'departments.view'" :to="{ path: '/department' }">Departments</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'functionals.view'" :to="{ path: '/function' }">Functional</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'sections.view'" :to="{ path: '/section' }">Section</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'frequencies.view'" :to="{ path: '/frequency' }">Frequency</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'roles.view'" :to="{ path: '/role' }">Role</router-link>
						</li>
					</ul>
                </div>
                <div class="col-sm-4">
					<ul class="list-group">
						<li class="list-group-item">
							<router-link v-can="'equipment_types.view'" :to="{ path: '/equipment_type' }"> Equipment Types </router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'equipment_groups.view'" :to="{ path: '/equipment_group' }"> Equipment Groups</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'component_types.view'" :to="{ path: '/component_type' }"> Component Types</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'check_types.view'" :to="{ path: '/check_type' }">Check Type</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'categories.view'" :to="{ path: '/categories' }">Categories</router-link>
						</li>
					</ul>
                </div>
				<div class="col-sm-4">
					<ul class="list-group">
						<li class="list-group-item">
							<router-link v-can="'permissions.view'" :to="{ path: '/permissionsnew' }"> Permissions</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'escalations.view'" :to="{ path: '/escalation' }">Escalation Matrix</router-link>
						</li>
						<li class="list-group-item">
							<router-link v-can="'escalationConfigurations.view'" :to="{ path: '/escalationConfiguration' }">Escalation Emails</router-link>
						</li>
					</ul>
                </div>
            </div>
        </div>
    </div>
</template>
