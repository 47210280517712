<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div  style="text-align: center; margin-top:50px">
                <a href="#" >
                    <img src="../assets/AccessDeniedError.png" alt="AccessDenied" />
                </a>
            </div>
        </div>
    </div>
</template>
