<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Plants</h5>
                        </div>
                        <div class="card-body">
                              <!-- <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9"> -->
                        <div class="col-sm-12">
                            
                            <div class="input-group">
                                
                                <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                                
                            </div>
                        </div>         
               
            <!-- </div> -->
                            <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('palnt')">
                                                Name
                                            <span>
                                                    <i v-if="meta.field=='name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                                                                     
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="plants.length==0">
                                            <td  colspan="8" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(plant,key) in plants" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{ plant.plant_name }}</td>
                                          
                                            <td class="td-actions">
                                                <!-- <router-link v-can="'plants.update'" :to="'/plants/'+plant.plant_id+'/edit'" class="icon blue">
                                                   Continue
                                                </router-link> -->

                                                 <button type="submit" v-if="status" @click="login_to_plant(plant)" class="btn btn-sm btn-primary btn-block btnn">Continue</button>
                                               
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                status: true,
                errors: [],
                plants: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "plant_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    plant_id: this.$store.getters.user.plant_id,
                },
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },
        methods: {

           index() {
				let vm = this;
				let uri = 'paginatePlants?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&field='+vm.meta.field+'&per_page='+vm.meta.per_page+'&plant_id='+vm.meta.plant_id;
				vm.$store.dispatch('post',{'uri':uri})
				.then(function (response) {
					vm.plants = response.data.data;					
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},            
        
        login_to_plant(plant)
        {          
             let vm = this;             
             vm.$store.getters.user.plant_id = plant.plant_id;
             vm.$store.getters.user.plant= plant;
             vm.meta.plant_id=plant.plant_id;
             window.location.reload();
             //vm.index();

        }, 

            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },

             search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			}  
        },
    };
</script>
