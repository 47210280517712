<template>
    <!-- Content wrapper scroll start -->
    <div class="content-wrapper-scroll">
        <!-- Content wrapper start -->
        <div class="content-wrapper">
            <!-- form row -->
            <div class="row">
                <div class="col-sm-4">
                    <div class="card">
                        <div class="card-header">
                            <h5>New Department</h5>
                        </div>
                        <div class="card-body">
                            <!-- Field wrapper start -->
                            <div class="field-wrapper">
                                <input class="form-control" type="text" :class="{'is-invalid': errors.department_name}" placeholder="Name" v-model="department.department_name" ref="department_name" />
                                <span v-if="errors.department_name" class="invalid-feedback">{{ errors.department_name[0] }}</span>
                                <div class="field-placeholder">Department Name <span class="text-danger">*</span></div>
                            </div>
                            <!-- Field wrapper end -->
                            <div class="field-wrapper">
                                <input class="form-control" type="text" :class="{'is-invalid': errors.department_head}" placeholder="Department Head" v-model="department.department_head" ref="department_head" />
                                <span v-if="errors.department_head" class="invalid-feedback">{{ errors.department_head[0] }}</span>
                                <div class="field-placeholder">Department Head</div>
                            </div>

                            <div class="field-wrapper">
                                <input class="form-control" type="text" :class="{'is-invalid': errors.department_head_phone}" placeholder="Department Head Phone" v-model="department.department_head_phone" ref="department_head_phone" />
                                <span v-if="errors.department_head_phone" class="invalid-feedback">{{ errors.department_head_phone[0] }}</span>
                                <div class="field-placeholder">Phone no</div>
                            </div>
                            <div class="field-wrapper">
                                <input class="form-control" type="email" :class="{'is-invalid': errors.department_head_email}" placeholder="Department Head Email" v-model="department.department_head_email" ref="department_head_email" />
                                <span v-if="errors.department_head_email" class="invalid-feedback">{{ errors.department_head_email[0] }}</span>
                                <div class="field-placeholder">Email Id</div>
                            </div>

                            <!-- Field wrapper start -->

                            <!-- Field wrapper start -->

                            <!-- Field wrapper end -->
                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <button class="btn btn-danger" @click="reset">Discard</button>
                                    <button v-can="'departments.create'"  class="btn btn-primary ms-1" v-if="status" @click="addDepartment">Submit</button>
                                    <button v-can="'departments.update'" class="btn btn-primary ms-1" v-else @click="updateDepartment">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="card">
                        <div class="card-header">
                            <h5>Departments</h5>
                        </div>
                        <div class="card-body">
                            <div class="col-sm-12">
                                <div class="input-group">
                                    <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                    <span class="input-group-text">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>

                            <div class="table-responsive" style="margin-top: 10px;">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('department_name')">
                                                Department
                                                <span>
                                                    <i v-if="meta.field=='department_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='department_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('department_head')">
                                                Head
                                                <span>
                                                    <i v-if="meta.field=='department_head' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='department_head' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('department_head_phone')">
                                                Phone No
                                                <span>
                                                    <i v-if="meta.field=='department_head_phone' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='department_head_phone' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('department_head_email')">
                                                Email
                                                <span>
                                                    <i v-if="meta.field=='department_head_email' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='department_head_email' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="departments.length==0">
                                            <td colspan="6" class="text-center">No records found</td>
                                        </tr>

                                        <tr v-for="(departments,key) in departments" :key="departments.department_id">
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{departments.department_name}}</td>
                                            <td>{{departments.department_head}}</td>
                                            <td>{{departments.department_head_phone}}</td>
                                            <td>{{departments.department_head_email}}</td>

                                            <td class="td-actions">
                                                <a href="#"  v-can="'departments.update'"  class="icon green" @click.prevent="edit(departments)">
                                                    <i class="icon-pencil"></i>
                                                </a>
                                                <a href="#"  v-can="'departments.delete'"  class="icon red" @click.prevent="deleteDepartment(departments.department_id)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                status: true,
                department: {
                    department_id: "",
                    department_name: "",
                    department_head: "",
                    department_head_phone: "",
                    department_head_email: "",
                },
                errors: [],
                departments: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "department_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
            };
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                let vm = this;
                let uri = "departments?page=" + vm.meta.currentPage + "&search=" + vm.meta.search + "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + "&per_page=" + vm.meta.per_page;
                // vm.$store.dispatch("post", { uri:'departments',data:vm.meta})
                vm.$store
                    .dispatch("post", { uri: uri })
                    .then(function (response) {
                        response.data.data;
                        vm.departments = response.data.data;
                        vm.meta.totalRows = response.data.total;
                        vm.meta.lastPage = response.data.last_page;
                        vm.meta.from = response.data.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                        console.log(vm.departments);
                    })
                    .catch(function (error) {
                        // console.log(error);
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            addDepartment() {
                let vm = this;
                let loader = vm.$loading.show();
                //    console.log(vm.unit)
                vm.$store
                    .dispatch("post", { uri: "addDepartment", data: vm.department })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Department Added successfully");
                        vm.index();
                        vm.reset();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            updateDepartment() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateDepartment/" + vm.department.department_id, data: vm.department };
                vm.$store
                    .dispatch("patch", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Department is successfully updated");
                        vm.index();
                        vm.reset();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deleteDepartment(department) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteDepartment/" + department };
                    vm.$store
                        .dispatch("delete", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Department is successfully deleted");
                            vm.index();
                            vm.reset();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            edit(departments) {
                let vm = this;
                (vm.department = departments), (vm.status = false), vm.$refs.department_name.focus();
            },

            reset() {
                let vm = this;
                vm.department.department_name = "";
                vm.department.department_head = "";
                vm.department.department_head_phone = "";
                vm.department.department_head_email = "";
                vm.$refs.department_name.focus();
                vm.index();
                vm.errors = [];
                vm.status = true;
            },

            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },

            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
            search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			} 
        },
    };
</script>
<style>
    .btn {
        margin-right: 10px;
    }
</style>
