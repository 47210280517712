<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Checklist</h5>
                        </div>
                        <div class="card-body">
                           <div class="row gutters">
                                 <div class="col-sm-3 ">
                                      <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.department_id }"
                                            :customClass="{ 'is-invalid': errors.department_id }"
                                            :initialize="meta.department_id"
                                            id="department_id"
                                            label="department_name"
                                            placeholder="Select Department"
                                            :data="departments"
                                            @input="departments => meta.department_id = departments"
                                        >
                                        </search>
                                        <span v-if="errors.department_id" class="invalid-feedback">{{ errors.department_id[0] }}</span>
                                        <div class="field-placeholder">Department</div>
                                    </div>
                                 </div>
                                 <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.functional_id }"
                                            :customClass="{ 'is-invalid': errors.functional_id }"
                                            :initialize="meta.functional_id"
                                            id="functional_id"
                                            label="functional_name"
                                            placeholder="Select Functional"
                                            :data="functionals"
                                            @input="functionals => meta.functional_id = functionals"
                                        >
                                        </search>
                                        <span v-if="errors.functional_id" class="invalid-feedback">{{ errors.functional_id[0] }}</span>
                                        <div class="field-placeholder">Functional</div>
                                    </div>
                                 </div> 
                                 <div class="col-sm-3">  
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.category_id }"
                                            :customClass="{ 'is-invalid': errors.category_id }"
                                            :initialize="meta.category_id"
                                            id="category_id"
                                            label="category_name"
                                            placeholder="Select Category"
                                            :data="categories"
                                            @input="categories => meta.category_id = categories"
                                        >
                                        </search>
                                        <span v-if="errors.category_id" class="invalid-feedback">{{ errors.category_id[0] }}</span>
                                        <div class="field-placeholder">Category</div>
                                    </div>                                     
                                </div>
                                <div class="col-sm-3">  
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.frequency_id }"
                                            :customClass="{ 'is-invalid': errors.frequency_id }"
                                            :initialize="meta.frequency_id"
                                            id="frequency_id"
                                            label="frequency_name"
                                            placeholder="Select Frequency"
                                            :data="frequencies"
                                            @input="frequencies => meta.frequency_id = frequencies"
                                        >
                                        </search>
                                        <span v-if="errors.frequency_id" class="invalid-feedback">{{ errors.frequency_id[0] }}</span>
                                        <div class="field-placeholder">Frequency</div>
                                    </div>                                    
                                </div>

                                 <div class="col-sm-6">  
                                    <div class="input-group field-wrapper">
                                       <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search" />
                                            <span class="input-group-text">
                                                <i class="fas fa-search"></i>
                                            </span>
                                    </div>                            
                                </div>

                                <div class="col-sm-1 field-wrapper">
                                   <button type="submit" v-if="status" @click="index" class="btn btn-primary btn-block">Search</button>
                                </div>

                                <div class="col-sm-1 field-wrapper">
                                   <button type="submit" v-if="status" @click="reset" class="btn btn-primary btn-block">Reset</button>
                                </div>

                            </div>
                           
                            <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('job_no')">
                                                Job No
                                                <span>
                                                    <i v-if="meta.field=='job_no' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='job_no' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('date_time')">
                                                Date & Time
                                                <span>
                                                    <i v-if="meta.field=='date_time' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='date_time' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('template_name')">
                                                Template Name
                                                <span>
                                                    <i v-if="meta.field=='template_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='template_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th>Frequency</th>
                                            <th @click="sort('category_name')">
                                                Category
                                                <span>
                                                    <i v-if="meta.field=='category_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='category_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('department_name')">
                                                Department
                                                <span>
                                                    <i v-if="meta.field=='department_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='department_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('functional_name')">
                                                Functional
                                                <span>
                                                    <i v-if="meta.field=='functional_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='functional_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <!-- <th>Category</th> -->
                                            <!-- <th>Frequency</th> -->
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="user_templates.length==0">
                                            <td colspan="9" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(template,key) in user_templates" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{ template.job_no }}</td>
                                            <td>{{ template.date_time }}</td>
                                            <td>{{ template.template_name }}</td>
                                            <td>{{ template.frequency.frequency_name }}</td>
                                            <td>{{ template.category.category_name }}</td>
                                            <td>{{ template.department.department_name }}</td>
                                            <td>{{ template.functional.functional_name }}</td>
                                            <!-- <td>{{ template.category.category_name }}</td> -->
                                            <!-- <td>{{ template.frequency.frequency_name }}</td> -->
                                            <td class="td-actions">
                                                <a target="_blank" :href="$store.getters.pdfUrl+'getUserTemplatePDF/'+template.user_template_id" class="icon green">
                                                    <i class="fa fa-file-pdf"></i>
                                                </a>
                                                <a target="_blank" :href="$store.getters.pdfUrl+'getUserTemplateExcel/'+template.user_template_id" class="icon blue">
                                                    <i class="fa fa-file-excel"></i>
                                                </a>
                                                <!-- <a :href="$store.getters.pdfUrl+'barcodeEquipment?equipment_id='+equipment.equipment_id" target="_blank" class="icon blue">
                                                    <i class="icon-file"></i>
                                                </a> -->
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    let Search = require("@/components/Search.vue").default;
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination, Search },
        data() {
            return {
                status: true,
                errors: [],
                user_templates: [],
                departments: [],
                functionals: [],
                categories: [],
                frequencies: [],
                meta: {
                    department_id: "",
                    functional_id: "",
                    category_id: "",
                    frequency_id: "",
                    search: "",
                    order_by: "desc",
                    field: "date_time",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    plant_id: this.$store.getters.user.plant_id,
                },
            };
        },
        mounted() {
            this.getDepartment();
            this.index();
        },
        methods: {
            index() {
                let vm = this;
                let uri =
                    "checklist?page=" +
                    vm.meta.currentPage +
                    "&search=" +
                    vm.meta.search +
                    "&order_by=" +
                    vm.meta.order_by +
                    "&field=" +
                    vm.meta.field +
                    "&per_page=" +
                    vm.meta.per_page +
                    "&plant_id=" +
                    vm.meta.plant_id +
                    "&department_id=" +
                    vm.meta.department_id +
                    "&functional_id=" +
                    vm.meta.functional_id +
                    "&category_id=" +
                    vm.meta.category_id +
                    "&frequency_id=" +
                    vm.meta.frequency_id;
                vm.$store
                    .dispatch("post", { uri: uri })
                    .then(function (response) {
                        response.data.data;
                        vm.user_templates = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            reset()
            {
                let vm = this;
                vm.meta.department_id="";
                vm.meta.functional_id="";
                vm.meta.category_id="";
                vm.meta.frequency_id="";
                vm.meta.search="";
                vm.index();
            },
           
            getDepartment() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getDepartments" })
                    .then(function (response) {
                        vm.departments = response.data.data;
                        vm.getFunctions();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
           
            getFunctions() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getFunctionals" })
                    .then(function (response) {
                        vm.functionals = response.data.data;
                        vm.getCategories();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
           
            getCategories() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getCategories" })
                    .then(function (response) {
                        vm.categories = response.data.data;
                        vm.getFrequencies();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
           
            getFrequencies() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getFrequencies" })
                    .then(function (response) {
                        vm.frequencies = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            search() {
                let vm = this;
                vm.meta.currentPage = 1;
                vm.index();
            },


        },
    };
</script>
