<template>
 <div class="content-wrapper-scroll">

                <!-- Content wrapper start -->
                <div class="content-wrapper">

                    <!-- Row start -->
                    <div class="row gutters">
                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                            <!-- Card start -->
                            <div class="card">
                                <div class="card-body">
                                <span class="float-end"><router-link to="/equipments" class="btn btn-primary">
                                <i class="fas fa-arrow-left"></i><i class="fas fa-th-list"></i>
                                Equipments
                            </router-link></span>
                                   
                                    <form>
                                        <div class="row gutters">
                                            <h4><label class="col-sm-6 col-form-label">Equipment</label></h4>
                                        </div>
                                        <div class="row gutters">

                                            <label class="col-sm-2 col-form-label fw-bold">Equipment UID:</label>
                                            <div class="col-sm-5 ">
                                                <label class="col-sm-4  col-form-label ">{{equipment_Components.equipment_uid}} </label>
                                            </div>

                                           
                                        </div>

                                            <div class="row gutters ">

                                        <label class="col-sm-2 col-form-label fw-bold">UID:</label>
                                            <div class="col-sm-5">
                                                <label class="col-sm-4 col-form-label">{{equipment_Components.uid}} </label>
                                            </div>
                                        </div>
                                        <div class="row gutters ">

                                            <label class="col-sm-2 col-form-label fw-bold">Plant Name:</label>
                                            <div class="col-sm-4">
                                                <label class="col-sm-4 col-form-label" v-if="equipment_Components.plant">{{equipment_Components.plant.plant_name}} </label>
                                            </div>

                                             <label class="col-sm-2 col-form-label fw-bold" >Section Name:</label>
                                            <div class="col-sm-4">
                                                <label class="col-sm-4 col-form-label" v-if="equipment_Components.section">{{equipment_Components.section.section_name}} </label>
                                            </div>
                                        </div>


                                    </form>

                                </div>
                            </div>
                            <!-- Card end -->

                        </div>
                        <!-- table start -->

                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">

                            <div class="card">
                                <div class="card-body">
                                    <div class="row gutters ">
                                        <h4><label class="col-sm-3 col-form-label">Component</label></h4>
                                    </div>

                                    <div class="table-responsive">
                                        <div id="copy-print-csv_wrapper"
                                            class="dataTables_wrapper dt-bootstrap4 no-footer">

                                            <table id="copy-print-csv" class="table v-middle dataTable no-footer"
                                                role="grid" aria-describedby="copy-print-csv_info">
                                                <thead>
                                                    <tr role="row">
                                                    <th class="sorting_asc" tabindex="0"
                                                            aria-controls="copy-print-csv" rowspan="1" colspan="1"
                                                            aria-sort="ascending"
                                                            aria-label="Products: activate to sort column descending"
                                                            style="width: 50.844px;">#</th>
                                                        <th class="sorting_asc" tabindex="0"
                                                            aria-controls="copy-print-csv" rowspan="1" colspan="1"
                                                            aria-sort="ascending"
                                                            aria-label="Products: activate to sort column descending"
                                                            style="width: 178.844px;">Equipment_Group</th>
                                                         <th class="sorting_asc" tabindex="0"
                                                            aria-controls="copy-print-csv" rowspan="1" colspan="1"
                                                            aria-sort="ascending"
                                                            aria-label="Products: activate to sort column descending"
                                                            style="width: 178.844px;">Equipment_Type</th>
                                                            <th class="sorting_asc" tabindex="0"
                                                            aria-controls="copy-print-csv" rowspan="1" colspan="1"
                                                            aria-sort="ascending"
                                                            aria-label="Products: activate to sort column descending"
                                                            style="width: 178.844px;">Component_Type</th>

                                                        <th class="sorting" tabindex="0" aria-controls="copy-print-csv"
                                                            rowspan="1" colspan="1"
                                                            aria-label="Ratings: activate to sort column ascending"
                                                            style="width: 77.25px;">Check_Type</th>
                                                        
                                                        
                                                        
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                      
                                               <tr role="row" class="even"  v-for="(equipment_Component,key) in equipment_Components.equipment_components" :key="equipment_Component.equipment_component_id"  >
                                                      
                                                                                               
                                                        <td>{{meta.from+key}}</td>
                                                        <td>{{equipment_Component.equipment_group.equipment_group_name}}</td> 
                                                        <td>{{equipment_Component.equipment_type.equipment_type_name}}</td>
                                                        <td >{{equipment_Component.component_type.component_type_name}}</td>
                                                        <td >{{equipment_Component.check_type.check_type_name}}</td>
                                                      
                                                                                              
                                             
                                                        
                                                       
                                                    </tr>
                                                    
                                                    
                                                    
                                                    
                                                    
                                </tbody>
                                            </table>

                                     <!-- pageing template-->

           
            <!-- pageing end -->



                                        </div>

                                    </div>
                                </div>

                            </div>

                            <!-- table end -->



                        </div>
                        <!-- Row end -->



                    </div>
         
                </div>
                <!-- Content wrapper scroll end -->

            </div>
</template>
<script>

export default{
    components: { },
    data(){
        return{
            errors:[],
            equipment_Components:[],
           meta: {
            
            from: 1,
           
            
        },
        }
    },
    mounted(){
       
         this.index();
    },

    methods:{
         index() {
                let vm = this;
               // showOrder/'+ vm.$route.params.equipment_id
                let uri ='showEquipment/'+ vm.$route.params.equipment_id;
                vm.$store.dispatch("get", { uri:uri})
                    .then(function (response) {
                 
                        vm.equipment_Components = response.data.data;
                       
                   console.log(vm.equipment_Components);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

           
             onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },
            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
    }

}
</script>
<style>
</style>