<template>
 <div class="login-container">

        <div class="container-fluid h-100">

            <!-- Row start -->
            <div class="row no-gutters h-100">
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="login-about">
                        <div class="slogan">
                            <span>Design</span>
                            <span>Made</span>
                            <span>Simple.</span>
                        </div>
                        <div class="about-desc">
                            UniPro a data dashboard is an information management tool that visually tracks, analyzes and
                            displays key performance indicators (KPI), metrics and key data points to monitor the health
                            of a business, department or specific process.
                        </div>
                        <a href="reports.html" class="know-more">Know More <img src="img/right-arrow.svg"
                                alt="Uni Pro Admin"></a>

                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="login-wrapper">
                        <form action="" @submit.prevent="register">
                            <div class="login-screen">
                                <div class="login-body">
                                    <a href="reports.html" class="login-logo">
                                        <img src="img/ultratech-cement-logo.png" class="center" alt="Uni Pro Admin">
                                    </a>
                                    <h6>Welcome to UniPro dashboard,<br>Create your account.</h6>

                                    <div class="field-wrapper">
                                        <input type="text" class="form-control" :class="{'is-invalid': errors.name}" placeholder="Name" v-model="user.name" ref="name">
                                        <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                        <div class="field-placeholder">Name</div>
                                    </div>
                                    <div class="field-wrapper">
                                        <input type="email" class="form-control" :class="{'is-invalid': errors.email}" placeholder="Email" v-model="user.email" ref="email">
                                        <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        <div class="field-placeholder">Email ID</div>
                                    </div>
                                    <div class="field-wrapper">
                                        <input type="password" class="form-control" :class="{'is-invalid': errors.password}" placeholder="Password" v-model="user.password" ref="password">
                                          <span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
                                        <div class="field-placeholder">Password</div>
                                    </div>
                                    <div class="field-wrapper mb-3">
                                        <input type="password" class="form-control" :class="{'is-invalid': errors.confirm_password}" placeholder="Re-Enter Password" v-model="user.confirm_password" ref="confirm_password">
                                        <span v-if="errors.confirm_password" class="invalid-feedback">{{ errors.confirm_password[0] }}</span>
                                        <div class="field-placeholder">Confirm Password</div>
                                    </div>

                                    <div class="field-wrapper" >
                                        <select class="select-single js-states select2-hidden-accessible" tabindex="-1"
                                                        aria-hidden="true"
                                                        :class="{'is-invalid':errors.role_id}"  v-model="user.role_id" >
                                                        <option value="">Select Role</option>
                                                        <option v-for="userroles,key in userroles" :key="key" :value="userroles.role_id">{{userroles.role_name}}</option>
                                                        console.log(userroles.role_id)
                                                   </select>
                                                   <span v-if="errors.frequency_id" class="invalid-feedback">{{ errors.frequency_id[0] }}</span>
                                                    <div class="field-placeholder">Role</div>
                                    </div>

                                   <div class="field-wrapper">
                                        <input type="text" class="form-control" :class="{'is-invalid': errors.mobile_number}" placeholder="Mobile no" v-model="user.mobile_number" ref="mobile_number" >
                                        <span v-if="errors.mobile_number" class="invalid-feedback">{{ errors.mobile_number[0] }}</span>
                                        <div class="field-placeholder">Mobile Number</div>
                                    </div>

                                    <div class="actions">
                                        <button type="submit" class="btn btn-primary ms-auto">Sign Up</button>
                                    </div>
                                </div>
                                <div class="login-footer">
                                    <span class="additional-link">Have an account? <a href="login.html"
                                            class="btn btn-light">Login</a></span>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                
            </div>
            <!-- Row end -->


        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{
                user:{
                    name:'',
                    email:'',
                    mobile_number:'',
                    password:'',
                    confirm_password:'',
                    role_id:'',
                    role_name:'',
                    avatar:'avatar.png',
                   
                },
                meta: {
            search: "",
            order_by: "asc",
            field: "role_id",
            per_page: 10,
            totalRows: 0,
            currentPage: 1,
            lastPage: 1,
            from: 1,
            maxPage: 1,
            
        },
               errors:[],
               userroles:[],
            }
    },
    mounted(){
            let vm = this;
            vm.$refs.name.focus();
            vm.getrole();
           
        },
methods: {


    getrole(){
        let vm = this;
                // let uri = "roles?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + "&per_page=" + vm.meta.per_page;
                vm.$store.dispatch("post", { uri:"getRoles"})
                    .then(function (response) {
                        response.data.data;
                        vm.userroles = response.data.data;
                        // vm.meta.totalRows = response.data.total;
                        // vm.meta.lastPage = response.data.last_page;
                        // vm.meta.from = response.data.from;
                        // vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    console.log(vm.userroles);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
    },

        register() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch('auth',{'uri':'register','data':vm.user})
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch('success','Thank you for registering with us');
                    vm.$store.dispatch('setUser',response.data);
                    vm.$router.push('/');
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch('error',error.response.data.message);
                });
            }

},
}
</script>
<style>
</style>