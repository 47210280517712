<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Escalation Matrix</h5>
                        </div>
                        <div class="card-body">
                  

                        <div class="table-responsive" style="margin-top: 10px;">
                            
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>                                          
                                            <th>#</th>
                                            <th>Escalation Range</th>
                                            <th>Notification To</th>
                                            <!-- <th>Emails</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        

                                        <tr v-if="escalationMatrices.length==0">
                                            <td  colspan="4" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(escalationMatrix,key) in escalationMatrices" :key="key">
                                            <td class="text-center">{{ meta.from+key }}</td>
                                            <!-- <td>{{ escalationMatrix.escalation_range }}</td> -->
                                            <td><input class="form-control" type="text" v-model="escalationMatrix.escalation_range" /></td> 
                                            <td> 
                                                <select class="form-control" v-model="escalationMatrix.escalation_notification" @change="onPerPageChange">
                                                    <option>SH</option>
                                                    <option>FH-SH</option>
                                                    <option>DH</option>
                                                    <option>FH</option>
                                                    <option>UH</option>                                           
                                                </select>
                                        </td>
                                           
                                        </tr>
                                        <tr>
                                            <td  colspan="4" >                                               
                                                <button v-can="'roles.update'"  class="btn btn-primary ms-1"  @click="updateEscalationMatrices">Update</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

    export default {
        data() {
            return {
                status: true,
                escalationMatrices: {
                    escalation_matrix_id: "",
                    escalation_range: "",
                    escalation_notification: "",
                },
               
                errors: [],
                
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "role_name",
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    to: 1,
                    maxPage: 1,
                },
            };
        },
        mounted() {
            this.index();
        },
        methods: {
            discard() {
                let vm = this;
                vm.$refs.role_name.focus();
                vm.role.role_name = "";
                vm.role.description = "";
                vm.status = true;
                vm.errors = [];
                //vm.index();
            },

            index() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "escalationMatrices", data: vm.meta })
                    .then(function (response) {
                        vm.escalationMatrices = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.to = response.data.meta.to;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

             updateEscalationMatrices() {
                let vm = this;
                let loader = vm.$loading.show();
                // let uri = { uri: "updateEscalationMatrices/" +  data: {'escalationMatrices':vm.escalationMatrices} } ;
                // vm.$store.dispatch("patch", uri)

                 vm.$store.dispatch("post", { uri: 'updateEscalationMatrices', data: {'escalationMatrices':vm.escalationMatrices} })
                        .then(function (){

                // .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "EscalationMatrices is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
        },
    };
</script>



