<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 v-if="status">New User</h5>
                            <h5 v-else>Update User</h5>
                        </div>
                        <div class="card-body">
                            <div class="row gutters">
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Name *</div>
                                        <input class="form-control" v-model="user.name" :class="{'is-invalid': errors.name}" ref="name" type="text" placeholder="Enter User Name" />
                                        <span v-if="errors.name" class="invalid-feedback">{{ errors.name[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Email *</div>
                                        <input class="form-control" v-model="user.email" :class="{'is-invalid': errors.email}" type="text" placeholder="Enter User Email" />
                                        <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                    </div>
                                </div>
                                <div  v-if="status" class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Password *</div>
                                        <input type="password" class="form-control" v-model="user.password" :class="{'is-invalid': errors.password}" placeholder="Enter Password" />
                                        <span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Mobile Number *</div>
                                        <input class="form-control" v-model="user.mobile_number" :class="{'is-invalid': errors.mobile_number}" type="text" placeholder="Enter Mobile Number" />
                                        <span v-if="errors.mobile_number" class="invalid-feedback">{{ errors.mobile_number[0] }}</span>
                                    </div>
                                </div>
                                 <div class="col-sm-4">
									<div class="field-wrapper">
                                            <div class="field-placeholder">Role *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.role_id }" 
                                            :customClass="{ 'is-invalid': errors.role_id }" 
                                            :initialize="user.role_id" 
                                            id="role_id"
                                            label="role_name"
                                            placeholder="Select Role"
                                            :data="roles"
                                            @input="role => user.role_id = role"                                           
                                            :disabled="!status"
                                            >
                                        </search> 
                                    <span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
                                        </div>
									</div>

                                  <div class="col-sm-4" >
										<div class="field-wrapper">
                                        <div class="field-placeholder">Plant *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.plant_id }" 
                                            :customClass="{ 'is-invalid': errors.plant_id }" 
                                            :initialize="user.plant_id" 
                                            id="plant_id"
                                            label="plant_name"
                                            placeholder="Select Plant"
                                            :data="plants"
                                            @input="plant => user.plant_id = plant"                                            
                                            :disabled="!status"
                                            >
                                        </search> 
                                        <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                                        </div>
									</div>
                                    <div class="col-sm-4">
											<div class="field-wrapper">
                                            <div class="field-placeholder">Department *</div>

                                        <search 
                                            :class="{ 'is-invalid': errors.department_id }" 
                                            :customClass="{ 'is-invalid': errors.department_id }" 
                                            :initialize="user.department_id" 
                                            id="department_id"
                                            label="department_name"
                                            placeholder="Select Department"
                                            :data="departments"
                                            @input="department => user.department_id = department"
                                            >
                                        </search> 
                                     <span v-if="errors.department_id" class="invalid-feedback">{{ errors.department_id[0] }}</span>
                                        </div>
									</div>

                                     <div class="col-sm-4">
                                        <div class="field-wrapper">
                                            <div class="field-placeholder">Functional *</div>
                                            <search 
                                                :class="{ 'is-invalid': errors.functional_id }" 
                                                :customClass="{ 'is-invalid': errors.functional_id }" 
                                                :initialize="user.functional_id" 
                                                id="functional_id"
                                                label="functional_name"
                                                placeholder="Select Functional"
                                                :data="functionals"
                                                @input="functional => user.functional_id = functional">
                                            </search>
                                        <span v-if="errors.functional_id" class="invalid-feedback">{{ errors.functional_id[0] }}</span>
                                        </div>
                                    </div>


                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <router-link to="/users" class="btn btn-danger">Discard</router-link>
                                    <button type="button" class="btn btn-primary ms-1" v-if="status" @click="addUser">Submit</button>
                                    <button type="button" class="btn btn-primary ms-1" v-else @click="updateUser">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 let Search = require('@/components/Search.vue').default;
    export default {
        components: { Search },
        data() {
            return {
                user: {
                    user_id: "",
                    name: "",
                    email: "",
                    password: "",
                    mobile_number: "",
                    role_id:"",
                    plant_id:"",
                    department_id:"",
                    functional_id:""
                },
                users: [],
                roles:[],
                plants:[],
                departments:[],
                functionals: [],
                errors: [],
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getRoles();                
                if (to.name == "User.Create") {
                    vm.status = true;
                }
                else {
                    vm.status = false;
                    let uri = { uri: "showUser/" + vm.$route.params.user_id };
                    vm.$store.dispatch("get", uri)
                    .then(function (response) {
                        vm.user = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },

        methods: {
            
            getRoles() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getRoles",data:{}})
                .then(function (response) {
                    vm.roles = response.data.data;
                     vm.getPlants();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getPlants() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getPlants",data:{}})
                .then(function (response) {
                    vm.plants = response.data.data;
                    vm.getDepartments();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

             getDepartments() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getDepartments",data:{}})
                .then(function (response) {
                    vm.departments = response.data.data;
                    vm.getFunctions();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

              getFunctions() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getFunctionals" })
                .then(function (response) {
                    vm.functionals = response.data.data;                   
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },


            addUser() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addUser", data: vm.user })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "User is successfully created");
                        vm.$router.push("/users");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            updateUser() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateUser/" + vm.user.user_id, data: vm.user };
                vm.$store
                    .dispatch("patch", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "User is successfully updated");
                        vm.$router.push("/users");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            }
        }
    };
</script>
<style></style>
