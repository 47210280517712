<template>
	<div class="content-wrapper">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<i class="fa fa-home"></i>
					Dashboard
				</li>
				<li class="breadcrumb-item active">Permissions</li>
                <li class="breadcrumb-right">
                  
					<button  @click="addRoles" class="btn btn-success">
								<i class="fa fa-plus"></i>
								Add Permissions
					</button>
                </li>
			</ol>
		</nav>
		<div class="content">
			<div class="container">
				<div class="card">
					<div class="card-header">
						<div class="card-title">
							<i class="fas fa-lock"></i>
							Permissions
						</div>
						<div class="card-tools">
							<select class="form-control form-control-sm" :class="{'is-invalid': errors.role_id}" v-model="permission.role_id" @change="getPermissions">
								<option value="">Select Role</option>
								<option v-for="role,key in roles" :key="key" :value="role.role_id">{{ role.role }}</option>
							</select>
							<span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
						</div>
					</div>
					<div class="card-body">
						<table class="table table-responsive-sm  table-hover table-sm">
							<thead>
								<tr>
									<th class="text-center">#</th>
									<th>Module</th>
									<th class="text-center">View</th>
									<th class="text-center">Create</th>
									<th class="text-center">Update</th>
									<th class="text-center">Delete</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="permission,key in permission.permissions" :key="key">
									<td class="text-center">{{ key+1 }}</td>
									
									<td>{{ permission.module }}</td>
									<td class="text-center" v-for="access,key in permission.access" :key="key">
										<input type="checkbox" v-model="access.access" @click="updatePermission($event,permission.module,access.permission)"> 	
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	</div>

</template>

<script>
	// import Pagination from '@/components/Pagination.vue';
	export default {
		name: 'Permissions.Index',
		// components: { Pagination },
		data(){
            return{
				meta: {
                    search:'',
					order_by:'asc',
					keyword:'module',
					per_page:10,
                    totalRows:0,
                    currentPage:1,
                    lastPage:1,
                    from: 1,
                    maxPage:1,
                },
				status:true,
				permission:{
					permission_id:'',
					role_id:'',
					permissions:[],
					access:'',
				},
				access:[],
				roles:[],
				errors:[]
            }
        },
		mounted() {
			let vm = this;
			vm.getRoles();
			vm.check();
		},
		methods:{

			getRoles() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'getRoles','data':vm.permission};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.roles = response.data.data;
					if(vm.roles.length!=0) {
						vm.permission.role_id=vm.roles[0].role_id;
						vm.getPermissions();
					}
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			getPermissions() {
				let vm = this;
				let loader = vm.$loading.show();
				let uri = {'uri':'paginatePermissions?role_id='+vm.permission.role_id};
				vm.$store.dispatch('get',uri)
				.then(function (response) {
					loader.hide();
					vm.permission.permissions = response.data;
					console.log(response.data);
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

			updatePermission(event, module, permission) {
				let vm = this;
				let data = {
					permission_id:permission.permission_id,
					role_id:vm.permission.role_id,
					module:module,
					permission:permission,
					access:event.target.checked
				};
				let uri = {'uri':'addPermission','data':data};
				vm.$store.dispatch('post',uri)
				.then(function (response) {
					console.log(response)
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
				
			},

			updateCheck(permission) {
				let vm = this;
				let loader = vm.$loading.show();
                let uri = {'uri':'makeTaxDefault','data':permission};
				vm.$store.dispatch('post',uri)
				.then(function () {
					loader.hide();
					vm.index();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
				},
				

				check() {
            if (this.permission.access == 1) {
                this.ischeckAll = true;
            } else {
                this.ischeckAll = false;
            }
        },
		addRoles() {
			let vm = this;
			let loader = vm.$loading.show();
			let uri = {'uri':'add_permission_roles?role_id='+vm.permission.role_id};
			vm.$store.dispatch('post',uri)
			.then(function () {
			loader.hide();
			vm.getPermissions();
		})
		.catch(function (error) {
		loader.hide();
		vm.errors = error.response.data.errors;
		vm.$store.dispatch('error',error.response.data.message);
		});
		}
		}
	}
</script>
