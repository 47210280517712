<template>
    <!-- Content wrapper scroll start -->
    <div class="content-wrapper-scroll">
        <!-- Content wrapper start -->
        <div class="content-wrapper"> 
            <!-- bar graph starts -->
            <div class="row gutters">
                <!---- uper portion start -->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">                
                     <div class="card"> 
                        <div class="card-body">
                            <div class="row gutters mb-3">
                                <div class="col-sm-10">
                                    <h4><label> Compliance Report</label></h4>
                                </div>                                
                            </div>

                          <div class="row gutters">                                
                                <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">From Date</div>
                                        <input class="form-control form-control-sm" type="date" placeholder="Date" v-model="meta.from_date" @change="changeDate" />
                                        <span v-if="errors.from_date" class="invalid-feedback">{{ errors.from_date[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">To Date</div>
                                        <input class="form-control form-control-sm" type="date" disabled="disabled" placeholder="Date" v-model="meta.to_date" />
                                        <span v-if="errors.to_date" class="invalid-feedback">{{ errors.to_date[0] }}</span>
                                    </div>
                                </div>
                                 <div class="col-sm-3 ">
                                      <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.department_id }"
                                            :customClass="{ 'is-invalid': errors.department_id }"
                                            :initialize="meta.department_id"
                                            id="department_id"
                                            label="department_name"
                                            placeholder="Select Department"
                                            :data="departments"
                                            @input="departments => meta.department_id = departments"
                                        >
                                        </search>
                                        <span v-if="errors.department_id" class="invalid-feedback">{{ errors.department_id[0] }}</span>
                                        <div class="field-placeholder">Department</div>
                                    </div>
                                 </div>
                                <div class="col-sm-3">  
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.frequency_id }"
                                            :customClass="{ 'is-invalid': errors.frequency_id }"
                                            :initialize="meta.frequency_id"
                                            id="frequency_id"
                                            label="frequency_name"
                                            placeholder="Select Frequency"
                                            :data="frequencies"
                                            @input="frequencies => meta.frequency_id = frequencies"
                                        >
                                        </search>
                                        <span v-if="errors.frequency_id" class="invalid-feedback">{{ errors.frequency_id[0] }}</span>
                                        <div class="field-placeholder">Frequency</div>
                                    </div>                                    
                                </div>


                                <div class="col-sm-12 " style="justify-content: space-between;">
                                     <a v-can="'compliance_report.view'"  :href="$store.getters.pdfUrl+'getComplianceReportExcel?plant_id='+meta.plant_id+'&from_date='+meta.from_date+'&to_date='+meta.to_date+'&department_id='+meta.department_id+'&frequency_id='+meta.frequency_id" target="_blank" class="btn btn-primary btn-block" style="margin-left: 5px;">Excel</a>  

                                     <button type="submit" v-if="status" @click="getComplianceReport" class="btn btn-primary btn-block">Filter</button>

                                      <button type="submit" v-if="status" @click="getComplianceReportPrev7Days" class="btn btn-primary btn-block">Prev 7 Days</button> 

                                       <button type="submit" v-if="status" @click="getComplianceReportNext7Days" class="btn btn-primary btn-block">Next 7 Days</button>
                                </div>
                                <br>
                          </div>    
                         
                        <div>
                              <div>
                                 <br>
                                   <iframe :src="meta.source" style="width:100%; height:800px;" frameborder="0"></iframe>
                            </div>
                        </div>
                    </div>
                    </div>
                    <!-- table end -->
                </div>
                <!-- Row end -->
               
            </div>
            <!-- bar graph ends -->

        </div>
    </div>
    <!-- Content wrapper scroll end -->
</template>
<script> 
 
    import moment from 'moment' 
    let Search = require("@/components/Search.vue").default; 
    export default { 
         components: { Search },     
        data() {
            return {              
                meta: {
                    plant_id: this.$store.getters.user.plant_id,                   
                    to_date:moment().format('YYYY-MM-DD'),
                    from_date:moment().add(-6, 'days').format('YYYY-MM-DD'),                    
                    source:"",
                    department_id:"",
                    frequency_id:"",
                },
                departments: [],
                frequencies:[],
                errors: [],
                status: true,
            };
        },
        mounted() {
            let vm = this; 
            vm.getDepartment();
            
        },
           beforeRouteEnter(to, from, next) {
            next(vm => {
                if(to.name=='Report.ComplianceReport.Edit') {
                    vm.meta.department_id=to.params.department_id;
                    
                }
                 });
            },
        methods: {         
            getComplianceReportPrev7Days()  {                
                let vm = this;
                vm.meta.from_date=moment(vm.meta.from_date).add(-6, 'days').format('YYYY-MM-DD');
                vm.meta.to_date=moment(vm.meta.to_date).add(-6, 'days').format('YYYY-MM-DD');
                console.log(vm.meta.from_date)
                console.log(vm.meta.to_date)
                vm.getData();
             },

             getComplianceReportNext7Days()
             {
               let vm = this;
                vm.meta.from_date=moment(vm.meta.from_date).add(6, 'days').format('YYYY-MM-DD');
                vm.meta.to_date=moment(vm.meta.to_date).add(6, 'days').format('YYYY-MM-DD');
                vm.getData();
             }, 
             
             getData() {
                let vm = this;
                let source = vm.$store.state.pdfUrl+"getComplianceReportPDF?from_date=" + vm.meta.from_date + '&to_date=' + vm.meta.to_date + '&plant_id=' + vm.meta.plant_id + '&department_id=' + vm.meta.department_id + '&frequency_id=' + vm.meta.frequency_id ;
                vm.meta.source = source;
                console.log(source);
            },  
             getDepartment() {
                let vm = this;
                vm.$store             
                    .dispatch("post", { uri: "getDepartmentsPlantWise", data: vm.meta })
                    .then(function (response) {
                        vm.departments = response.data.data;
                        vm.getFrequencies();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getFrequencies() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getFrequenciesPlantWise", data: vm.meta  })
                .then(function (response) {
                    vm.frequencies = response.data.data;
                    vm.meta.frequency_id = vm.frequencies[0].frequency_id;
                    vm.getData();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            getComplianceReport()
            {
                let vm = this;
                 vm.getData();
            },
            changeDate()
            {
                let vm = this;
                //alert(vm.meta.from_date);
                vm.meta.to_date=moment(vm.meta.from_date).add(6, 'days').format('YYYY-MM-DD');
            }                        
        },
    };
</script>