<template>
    <nav class="sidebar-wrapper">
        <div class="default-sidebar-wrapper">
            <div class="default-sidebar-brand">
                <a href="index.html" class="logo">
                    <img class="center-block d-block" style="margin-right:10px;margin-left:5px" src="../assets/ultratech-cement-logo.png" alt="Akxa tech" />
                    <h4 style="margin-top:11px">digiCHECK - v2.3</h4>
                </a>
            </div>
            <div class="defaultSidebarMenuScroll">
                <div class="default-sidebar-menu">
                    <ul>
                        <li class="default-sidebar">
                            <router-link :to="{ path: '/' }"><i class="icon-home2"></i><span class="menu-text">Dashboard</span></router-link>
                        </li>
                        <li class="default-sidebar" >
                            <router-link :to="{ path: '/configuration' }"><i class="icon-settings1"></i><span class="menu-text">Configuration</span></router-link>
                        </li>  
                        <li class="default-sidebar-dropdown" v-can="'users.view'">
                            <a href="javascript:;">
                                <i class="icon-users"></i>
                                <span class="menu-text">Users</span>
                            </a>
                            <div class="default-sidebar-submenu">
                                <ul>
                                    <li class="default-sidebar" v-can="'users.create'">
                                        <router-link :to="{ path: '/users/create' }">
                                            <span class="menu-text">New User</span>
                                        </router-link>
                                    </li>
                                    <li class="default-sidebar" v-can="'users.view'">
                                        <router-link :to="{ path: '/users' }">
                                            <span class="menu-text">User List</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li> 
                        <li class="default-sidebar" v-can="'masters.view'">
                            <router-link :to="{ path: '/master' }"><i class="icon-layers2"></i><span class="menu-text">Master</span></router-link>
                        </li>
                        <li class="default-sidebar-dropdown" v-can="'components.view'">
                            <a href="javascript:;">
                                <i class="icon-settings1"></i>
                                <span class="menu-text">Motor Tracking</span>
                            </a>
                            <div class="default-sidebar-submenu">
                                <ul>
                                    <li class="default-sidebar" v-can="'components.create'">
                                        <router-link :to="{ path: '/components/create' }">
                                            <span class="menu-text">New Motor</span>
                                        </router-link>
                                    </li>
                                    <li class="default-sidebar" v-can="'components.view'">
                                        <router-link :to="{ path: '/components' }">
                                            <span class="menu-text">Motor List</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li> 
                        <li class="default-sidebar-dropdown" v-can="'equipments.view'">
                            <a href="javascript:;">
                                <i class="icon-box"></i>
                                <span class="menu-text">Equipments</span>
                            </a>
                            <div class="default-sidebar-submenu">
                                <ul>
                                    <li class="default-sidebar" v-can="'equipments.create'">
                                        <router-link :to="{ path: '/equipments/create' }">
                                            <span class="menu-text">New Equipment</span>
                                        </router-link>
                                    </li>
                                    <li class="default-sidebar" v-can="'equipments.view'">
                                        <router-link :to="{ path: '/equipments' }">
                                            <span class="menu-text">Equipment List</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li> 
                        <li class="default-sidebar-dropdown" v-can="'templates.view'">
                            <a href="javascript:;">
                                <i class="icon-book"></i>
                                <span class="menu-text">Templates</span>
                            </a>
                            <div class="default-sidebar-submenu">
                                <ul>
                                    <li class="default-sidebar" v-can="'templates.create'">
                                        <router-link :to="{ path: '/templates/create' }">
                                            <span class="menu-text">New Template</span>
                                        </router-link>
                                    </li>
                                    <li class="default-sidebar" v-can="'templates.view'">
                                        <router-link :to="{ path: '/templates' }">
                                            <span class="menu-text">Template List</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li> 
                         <li class="default-sidebar"  v-can="'checklists.view'">
                            <router-link :to="{ path: '/checklist' }"><i class="icon-layers"></i><span class="menu-text">Checklist</span></router-link>
                        </li>

                        <li class="default-sidebar-dropdown">
                            <a href="javascript:;">
                                <i class="icon-settings1"></i>
                                <span class="menu-text">Reports</span>
                            </a>
                            <div class="default-sidebar-submenu">
                                <ul>
                                    <li class="default-sidebar"  v-can="'severity_report.view'">
                                        <router-link :to="{ path: '/severityReport' }"><span class="menu-text">Severity Report</span></router-link>
                                    </li>
                                    <li class="default-sidebar"  v-can="'deviated_component_report.view'">
                                        <router-link :to="{ path: '/deviatedComponentReport' }"><span class="menu-text">Deviated Component</span></router-link>
                                    </li>
                                    <li class="default-sidebar"  v-can="'compliance_report.view'">
                                        <router-link :to="{ path: '/complianceReport' }"><span class="menu-text">Compliance Report</span></router-link>
                                    </li>
                                    <li class="default-sidebar"  v-can="'daily_report.view'">
                                        <router-link :to="{ path: '/dailyReport' }"><span class="menu-text">Daily Report</span></router-link>
                                    </li>
                                </ul>
                            </div>
                        </li> 

                         <li class="default-sidebar-dropdown" >
                            <a href="javascript:;">
                                <i class="icon-users"></i>
                                <span class="menu-text">Tickets</span>
                            </a>
                            <div class="default-sidebar-submenu">
                                <ul>
                                    <li class="default-sidebar">
                                        <router-link :to="{ path: '/tickets/create' }">
                                            <span class="menu-text">New Ticket</span>
                                        </router-link>
                                    </li>
                                    <li class="default-sidebar">
                                        <router-link :to="{ path: '/tickets' }">
                                            <span class="menu-text">Tickets List</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </li> 
                    </ul>
                </div>
            </div>
        </div>
    </nav>

    <div class="page-header">
        <div class="row gutters">
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-9">
                <div class="search-container">
                    <div class="toggle-sidebar" id="toggle-sidebar">
                        <i class="icon-menu"></i>
                    </div>
                    <div class="ui fluid category ">
                        <div class="ui icon input">
                             <h4 style="margin-top:10px">{{ $store.getters.user?.plant?.plant_name }}</h4>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-3">
                <ul class="header-actions">
                    
                    <li class="dropdown">
                        <a href="#" id="userSettings" class="user-settings" data-toggle="dropdown" aria-haspopup="true">
                            <h6>{{ $store.getters.user?.name }}-</h6>
                            <h6>{{ $store.getters.user?.role?.role_name }} </h6>
                             
                            <span class="avatar">
                                <img v-if="$store.getters?.user?.avatar" :src="$store.getters.assetUrl+'/users/'+$store.getters?.user?.avatar" alt="Logo" class="rounded-circle " /> 
                                <img v-else src="img/user.svg" alt="User Avatar" />
                                <span class="status busy"></span>
                            </span>
                        </a>
                        <div class="dropdown-menu dropdown-menu-end md" aria-labelledby="userSettings">
                            <div class="header-profile-actions">
                               <router-link to="/switchtoplant" v-can="'switchPlant.view'"> 
                                    <i class="icon-user1"></i>
                                    Switch to Plant
                                </router-link>
                                
                                <router-link to="/user/updateprofile">
                                    <i class="icon-user1"></i>
                                    Profile Update
                                </router-link>
                                <a href="#" @click.prevent="logout"><i class="icon-log-out1"></i>Logout</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                //active: true
            };
        },
        mounted(){
            
        },
        methods: {
            logout() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("auth", { uri: "logout", data: vm.$store.getters.user })
				.then(function () {
					loader.hide();
					vm.$store.dispatch("logout");
					
                    location.reload();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },
        },
    };
</script>
