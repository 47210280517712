<template>
    <ul class="pagination mb-0">
        <li class="pagination-item">
            <button
				class="btn"
                type="button"
                @click="onClickFirstPage"
                :disabled="isInFirstPage"
            >
                First
            </button>
        </li>

        <li class="pagination-item">
			<button
				class="btn"
				type="button"
				@click="onClickPreviousPage"
				:disabled="isInFirstPage"
			>
				Previous
			</button>
        </li>

        <li
        v-for="(page,key) in pages"
        class="pagination-item"
        :key="key">
			<button
				class="btn"
				type="button"
				@click="onClickPage(page.name)"
				:disabled="page.isDisabled"
				:class="{ active: isPageActive(page.name) }"
			>
				{{ page.name }}
			</button>
        </li>

        <li class="pagination-item">
			<button
				class="btn"
				type="button"
				@click="onClickNextPage"
				:disabled="isInLastPage"
			>
				Next
			</button>
        </li>

        <li class="pagination-item">
			<button
				class="btn"
				type="button"
				@click="onClickLastPage"
				:disabled="isInLastPage"
			>
				Last
			</button>
        </li>
    </ul>
</template>

<script>
    export default {
        name:'Pagination',
        props: {
            maxPage: {
                type: Number,
                required: true
            },
            totalPages: {
                type: Number,
                required: true
            },
            currentPage: {
                type: Number,
                required: true
            },
        },

        computed: {
            startPage() {
                if (this.currentPage === 1) {
                    return 1;
                }
                if (this.currentPage === this.totalPages) { 
                    return this.totalPages - this.maxPage + 1;
                }
                return this.currentPage - 1;
            },
            endPage() {
                return Math.min(this.startPage + this.maxPage - 1, this.totalPages);
            },
            pages() {
                const range = [];
                for(let i = this.startPage; i <= this.endPage; i+= 1 ) {
                    range.push({
                        name: i,
                        isDisabled: i === this.currentPage 
                    });
                }
                return range;
            },
            isInFirstPage() {
                return this.currentPage === 1;
            },
            isInLastPage() {
                return this.currentPage === this.totalPages;
            },
        },
        methods: {
            onClickFirstPage() {
                this.$emit('pagechanged', 1);
            },
            onClickPreviousPage() {
                this.$emit('pagechanged', this.currentPage - 1);
            },
            onClickPage(page) {
                this.$emit('pagechanged', page);
            },
            onClickNextPage() {
                this.$emit('pagechanged', this.currentPage + 1);
            },
            onClickLastPage() {
                this.$emit('pagechanged', this.totalPages);    
            },
            isPageActive(page) {
                return this.currentPage === page;
            },
        }
    }
</script>

<style>
    .pagination {
        display: flex;
        padding-left: 0;
        list-style: none;
        float:right;
    }

    .pagination .pagination-item {
        margin-right: 5px;
    }

    .pagination .pagination-item button {
        border: 1px solid #DFE3E7;
    }

    .pagination .pagination-item .active {
        background-color: #0d6efd;
        color: #fff;
    }
</style>