<template>
    <div class="page-wrapper" v-if="$store.getters.user">
        <div class="main-container">
            <Header></Header>
            <router-view />
            <div class="app-footer">
				© <a href="http://akxatech.com" target="_blank">AKXA Tech Private Limited.</a> {{ year }}
			</div>
        </div>
    </div>
    <div class="login-container" v-else>
        <router-view />
    </div>
</template>

<script>
    import Header from "@/components/Header.vue";
    import moment from 'moment'
    export default {
        name: "App",
        components: { Header },
        data() {
            return {
                year: moment(new Date()).format('YYYY')
            }
        },
        created(){
            //Read the status information in sessionStorage when the page is loaded
            if (sessionStorage.getItem("user") ) {
                this.$store.dispatch('autoLogin', JSON.parse(sessionStorage.getItem("user")))
            }
            //Save the information in vuex to sessionStorage when the page is refreshed
            window.addEventListener("beforeunload",()=>{
                let user = this.$store?.getters?.user
                sessionStorage.setItem("user",  JSON.stringify(user))
            })
        },
    };
</script>

<style></style>
