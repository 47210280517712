import { createStore } from "vuex";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";
import axios from "axios";

export default createStore({
    state: {
        // apiUrl: "https://digicheck.akxatech.com/api/",
        // assetUrl:"https://digicheck.akxatech.com/storage",
        // pdfUrl:"https://digicheck.akxatech.com/",

        apiUrl: "https://hexaqore.com/api/",
		assetUrl: "https://hexaqore.com/storage",
		pdfUrl: "https://hexaqore.com/",
        
        authenticated: false,
        user: null,
        permissions:[],
    },
    mutations: {
        setAuthentication(state, data) {
            state.authenticated = data;
        },
        setUser(state, user) {
            state.user = user;
        },
        setPermissions(state, permissions){
			state.permissions = permissions;
		},
    },
    getters: {
        authenticated(state) {
            return state.authenticated;
        },
        user(state) {
            return state.user;
        },
        apiUrl(state) {
            return state.apiUrl;
        },
        assetUrl(state) {
            return state.assetUrl;
        },
        pdfUrl(state) {
            return state.pdfUrl;
        },
        permissions(state){
			return state.permissions;
		},
    },

    actions: {
        async setUser(context, payload) {
            await context.commit("setUser", payload);
            await context.commit("setAuthentication", true);
            await context.commit('setPermissions',true);
        },

        async setPermissions(context,payload) {
			localStorage.setItem('permissions',JSON.stringify(payload))
			await context.commit('setPermissions',payload);
		},

        async logout(context) {
			sessionStorage.removeItem('user');
			await context.commit('setUser', null);
			await context.commit('setAuthentication',false);
            await context.commit('setPermissions', []);
		},

        async autoLogin(context,payload) {
			// const user = JSON.parse(localStorage.getItem('user'))s'))
            if(localStorage.getItem('permissions'))
            {
                let permissions =localStorage.getItem('permissions')
				await context.commit('setUser',payload);
				await context.commit('setAuthentication',true);
                if(permissions && permissions.length!=0)
                {
                    permissions = JSON.parse(permissions)
                    await context.commit('setPermissions',permissions);
                }
            }
			
		},

        // async autoLogin(context) {
		// 	const user = JSON.parse(localStorage.getItem('user'))
		// 	const permissions = JSON.parse(localStorage.getItem('permissions'))
		// 	if(user && permissions) {
		// 		await context.commit('setUser',user);
		// 		await context.commit('setAuthentication',true);
		// 		await context.commit('setPermissions',permissions);
		// 	}
		// },


        auth(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + payload.uri, payload.data)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
            });
        },

        get(context, payload) {
            return new Promise((resolve, reject) => {
                axios.get(this.state.apiUrl + payload.uri, {
                    headers: {
                        Authorization: "Bearer" + ' ' + context.getters.user.token
                    },
                })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
            });
        },

        post(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + payload.uri, payload.data, {
                    headers: {
                        Authorization: "Bearer" + ' ' + context.getters.user.token
                    },
                })
                .then(function (response) {
                    resolve(response);
                   
                })
                .catch(function (error) {
                    reject(error);
                    
                });
            });
        },

        uploadFile(context, payload) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + payload.uri, payload.data, {
                    headers: {
                        'Authorization': "Bearer" + ' ' + context.getters.user.token,
                        'Content-Type': "multipart/form-data",
                    },
                })
                .then(function (response) {
                    resolve(response);
                   
                })
                .catch(function (error) {
                    reject(error);
                    
                });
            });
        },

        
        downloadFile(context, payload,response_type) {
            return new Promise((resolve, reject) => {
                axios.post(this.state.apiUrl + payload.uri, payload.data, {
                    headers: {
                        Authorization: "Bearer" + ' ' + context.getters.user.token
                    },
                })
                axios.responseType(response_type)
                .then(function (response) {
                    resolve(response);
                   
                })
                .catch(function (error) {
                    reject(error);
                    
                });
            });
        },
        
        patch(context, payload) {
            return new Promise((resolve, reject) => {
                axios.patch(this.state.apiUrl + payload.uri, payload.data, {
                    headers: {
                        Authorization: "Bearer" + ' ' + context.getters.user.token
                    },
                })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
            });
        },

        delete(context, payload) {
            return new Promise((resolve, reject) => {
                axios.delete(this.state.apiUrl + payload.uri, {
                    headers: {
                        Authorization: "Bearer" + ' ' + context.getters.user.token
                    },
                })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
            });
        },

        async error(context, description) {
            await createToast(
                {
                    title: "Error",
                    description: description || "The given data was invalid.",
                },
                {
                    showIcon: true,
                    hideProgressBar: true,
                    type: "danger",
                    position: "top-right",
                }
            );
        },
        async success(context, description) {
            await createToast(
                {
                    title: "Success",
                    description: description || "Data is successfuly subbmited.",
                },
                {
                    showIcon: true,
                    hideProgressBar: true,
                    type: "success",
                    position: "top-right",
                }
            );
        }





    },

    modules: {},
});
