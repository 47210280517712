<template>
<!-- Content wrapper scroll start -->
 <div class="content-wrapper-scroll">

    <!-- Content wrapper start -->
    <div class="content-wrapper">

      <!-- form row -->
        <div class="row">
            <div class="col-sm-4">
                <div class="card">
                <div class="card-header">
                            <h5>New Plant</h5>
                        </div>
                    <div class="card-body">
                      <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.plant_code}" placeholder="Code" v-model="plant.plant_code" ref="plant_code">
                            <span v-if="errors.plant_code" class="invalid-feedback">{{ errors.plant_code[0] }}</span>
                            <div class="field-placeholder">Plant Code <span class="text-danger">*</span>
                            </div>
                           
                        </div>
                        <!-- Field wrapper start -->
                        <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.plant_name}" placeholder="Name" v-model="plant.plant_name" ref="plant_name">
                            <span v-if="errors.plant_name" class="invalid-feedback">{{ errors.plant_name[0] }}</span>
                            <div class="field-placeholder">Plant Name <span class="text-danger">*</span>
                            </div>
                           
                        </div>
                        <!-- Field wrapper end -->
                              <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.plant_head}" placeholder="Plant Head" v-model="plant.plant_head" ref="plant_head">
                            <span v-if="errors.plant_head" class="invalid-feedback">{{ errors.plant_head[0] }}</span>
                            <div class="field-placeholder">Plant Head
                            </div>
                            
                        </div>

                        <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.plant_head_phone}" placeholder="Plant Head Phone" v-model="plant.plant_head_phone" ref="plant_head_phone">
                            <span v-if="errors.plant_head_phone" class="invalid-feedback">{{ errors.plant_head_phone[0] }}</span>
                            <div class="field-placeholder">Phone no</div>
                          
                        </div>
                         <div class="field-wrapper">
                            <input class="form-control" type="email" :class="{'is-invalid': errors.plant_head_email}" placeholder="Plant Head Email" v-model="plant.plant_head_email" ref="plant_head_email">
                            <span v-if="errors.plant_head_email" class="invalid-feedback">{{ errors.plant_head_email[0] }}</span>
                            <div class="field-placeholder">Email Id
                            </div>
                           
                        </div>
                        <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.plant_longitude}" placeholder="Plant Longitude" v-model="plant.plant_longitude" ref="longitude">
                            <span v-if="errors.plant_longitude" class="invalid-feedback">{{ errors.plant_longitude[0] }}</span>
                            <div class="field-placeholder">Longitude </div>
                          
                        </div>
                         <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.plant_latitude}" placeholder="Plant Latitude" v-model="plant.plant_latitude" ref="latitude">
                            <span v-if="errors.plant_latitude" class="invalid-feedback">{{ errors.plant_latitude[0] }}</span>
                            <div class="field-placeholder">Latitude </div>
                          
                        </div>
                         <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.plant_radius}" placeholder="Plant Radius" v-model="plant.plant_radius" ref="radius">
                            <span v-if="errors.plant_radius" class="invalid-feedback">{{ errors.plant_radius[0] }}</span>
                            <div class="field-placeholder">Radius </div>
                          
                        </div>
                        
                        <!-- Field wrapper start -->

                        <!-- Field wrapper start -->
                       
                        <!-- Field wrapper end -->
                       
                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <button class="btn btn-danger" @click="reset">Discard</button>
                                    <button v-can="'plants.create'"  class="btn btn-primary ms-1" v-if="status" @click="addPlant">Submit</button>
                                    <button v-can="'plants.update'" class="btn btn-primary ms-1" v-else @click="updatePlant">Update</button>
                                </div>
                            </div>
                                               
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="card">
                <div class="card-header">
                            <h5>Plants</h5>
                        </div>
                    <div class="card-body">
                      <div class="col-sm-12">
                            <div class="input-group">
                                
                                 <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                            </div>
                        </div>

                        <div class="table-responsive" style="margin-top: 10px;">
                            
                                   
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            
                                            <th @click="sort('plant_code')">
                                                Code
                                            <span>
                                                    <i v-if="meta.field=='plant_code' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_code' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th @click="sort('plant_name')">
                                                Name
                                            <span>
                                                    <i v-if="meta.field=='plant_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th @click="sort('plant_head')">
                                                Head
                                            <span>
                                                    <i v-if="meta.field=='plant_head' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_head' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th @click="sort('plant_head_phone')">
                                                Phone
                                            <span>
                                                    <i v-if="meta.field=='plant_head_phone' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_head_phone' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('plant_head_email')">
                                                Email
                                            <span>
                                                    <i v-if="meta.field=='plant_head_email' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_head_email' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('plant_longitude')">
                                                Longitude
                                            <span>
                                                    <i v-if="meta.field=='plant_longitude' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_longitude' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>     
                                            </th>
                                            <th @click="sort('plant_latitude')">
                                                Latitude
                                            <span>
                                                    <i v-if="meta.field=='plant_latitude' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_latitude' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('plant_radius')">
                                                Radius
                                            <span>
                                                    <i v-if="meta.field=='plant_radius' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_radius' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th>Actions</th>
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                         <tr v-if="plants.length==0">
                                            <td  colspan="10" class="text-center">No records found</td>
                                        </tr>

                                                               
                                        <tr role="row" class="even" v-for="(plants,key) in plants" :key="plants.plant_id">
                                           
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{plants.plant_code}}</td> 
                                            <td>{{plants.plant_name}}</td>                                        
                                            <td>{{plants.plant_head}}</td>
                                            <td>{{plants.plant_head_phone}}</td>
                                            <td>{{plants.plant_head_email}}</td>
                                            <td>{{plants.plant_longitude}}</td>
                                            <td>{{plants.plant_latitude}}</td>
                                            <td>{{plants.plant_radius}}</td>
                                            <td class="td-actions">
                                                <a href="#" v-can="'plants.update'" class="icon green" @click.prevent="edit(plants)">
                                                    <i class="icon-pencil"></i>
                                                </a>
                                                <a href="#" v-can="'plants.delete'" class="icon red" @click.prevent="deletePlant(plants.plant_id)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                        </div>
                        <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                    </div>  
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from '@/components/Pagination.vue';
export default{
    components: { Pagination },
data(){
    return{
        
         status: true,
        plant:{
                    plant_id:'',
                    plant_code:'' ,
                    plant_name:'',
                    department_head:'',
                    department_head_phone:'',
                    department_head_email:'',
                    plant_latitude:'',
                    plant_longitude:'',
                    plant_radius:''


        
        },
        errors:[],
        plants:[],
         meta: {
                    search: "",
                    order_by: "asc",
                    field: "plant_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
    }
},
    mounted(){
            this.index()
        },
        methods: {

            index() {
                let vm = this;
                let uri = "plants?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                 "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + "&per_page=" + vm.meta.per_page;
                vm.$store.dispatch("post", { uri:uri})
                    .then(function (response) {
                        response.data.data;
                        vm.plants = response.data.data;
                        vm.meta.totalRows = response.data.total;
                        vm.meta.lastPage = response.data.last_page;
                        vm.meta.from = response.data.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                   
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
             addPlant() {
                 
                let vm = this;
                let loader = vm.$loading.show();
                //    console.log(vm.unit)
                vm.$store.dispatch("post", { uri: "addPlant", data: vm.plant })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Plant Added successfully");
                        vm.index();
                        vm.reset();
                        vm.errors=[];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },


            updatePlant() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updatePlant/" + vm.plant.plant_id, data: vm.plant };
                vm.$store
                    .dispatch("patch", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Plant is successfully updated");
                        vm.index();
                        vm.reset();
                        vm.errors=[];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deletePlant(plants) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    let uri = { uri: "deletePlant/" + plants };
                    vm.$store
                        .dispatch("delete", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Plant is successfully deleted");
                            vm.index()
                            vm.reset();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            edit(plants) {
                let vm = this;
                (vm.plant = plants), (vm.status = false), vm.$refs.plant_code.focus();
            },

            reset(){
                let vm = this;
                vm.plant.plant_code = "";
                vm.plant.plant_name = "";
                vm.plant.plant_head = "";
                vm.plant.plant_head_phone = "";
                vm.plant.plant_head_email = "";
                vm.plant.plant_latitude="";
                vm.plant.plant_longitude="";
                vm.plant.plant_radius="";
                vm.$refs.plant_code.focus();
                vm.status = true;
                vm.errors=[];
                vm.index()
            },


             onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },
            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
             search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			}  

        },

}
</script>
<style>
.btn {
            
            margin-right: 10px;
        }
</style>