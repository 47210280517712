<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-4">
                    <div class="card">
                        <div class="card-header">
                            <h5>New Role</h5>
                        </div>
                        <div class="card-body">
                            <div class="field-wrapper">
                                <div class="field-placeholder">Role Name <span class="text-danger">*</span></div>
                                <input class="form-control" type="text" :class="{'is-invalid': errors.role_name}" placeholder="Role Name" v-model="role.role_name" ref="role_name" />
                                <span v-if="errors.role_name" class="invalid-feedback">{{ errors.role_name[0] }}</span>
                            </div>
                            <div class="field-wrapper">
                                <div class="field-placeholder">Description <span class="text-danger">*</span></div>
                                <textarea class="form-control" rows="2" :class="{'is-invalid': errors.description}" placeholder="Description" v-model="role.description"></textarea>
                                <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <button class="btn btn-danger" @click="discard">Discard</button>
                                    <button v-can="'roles.create'" class="btn btn-primary ms-1" v-if="status" @click="addRole">Submit</button>
                                    <button v-can="'roles.update'"  class="btn btn-primary ms-1" v-else @click="updateRole">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="card">
                        <div class="card-header">
                            <h5>Roles</h5>
                        </div>
                        <div class="card-body">
                      <div class="col-sm-12">
                            <div class="input-group">
                                
                                 <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                            </div>
                        </div>

                        <div class="table-responsive" style="margin-top: 10px;">
                            
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('role_name')">
                                                Role
                                            <span>
                                                    <i v-if="meta.field=='role_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='role_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('description')">
                                                Description
                                            <span>
                                                    <i v-if="meta.field=='description' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='description' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="roles.length==0">
                                            <td  colspan="4" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(role,key) in roles" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{ role.role_name }}</td>
                                            <td>{{ role.description }}</td>
                                            <td class="td-actions">
                                                <a href="#" v-can="'roles.update'"  class="icon green" @click.prevent="editRole(role)">
                                                    <i class="icon-pencil"></i>
                                                </a>
                                                <a href="#" v-can="'roles.delete'"  class="icon red" @click.prevent="deleteRole(role)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                status: true,
                role: {
                    role_name: "",
                    description: "",
                    role_id: "",
                },
                errors: [],
                roles: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "role_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
            };
        },
        mounted() {
            this.discard();
        },
        methods: {

            discard() {
                let vm = this;
                vm.role.role_name = "";
                vm.role.description = "";
                vm.$refs.role_name.focus();
                vm.status = true;
                vm.errors = [];
                vm.index();
            },

            index() {
                let vm = this;
                let uri = "roles?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                 "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + 
                 "&per_page=" + vm.meta.per_page;
                vm.$store.dispatch("post", { uri:uri})
                .then(function (response) {
                    response.data.data;
                    vm.roles = response.data.data;
                    vm.meta.totalRows = response.data.total;
                    vm.meta.lastPage = response.data.last_page;
                    vm.meta.from = response.data.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addRole() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "addRole", data: vm.role })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Role Added successfully");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            editRole(role) {
                let vm = this;
                vm.role = role;
                vm.status = false;
                vm.$refs.role_name.focus();
            },

            updateRole() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateRole/" + vm.role.role_id, data: vm.role };
                vm.$store.dispatch("patch", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Role is successfully updated");
                    vm.discard();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteRole(role) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteRole/" + role.role_id };
                    vm.$store.dispatch("delete", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Role is successfully deleted");
                        vm.index();
                        vm.discard();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },
            
            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
            search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			} 

        },
    };
</script>
