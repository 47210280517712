<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 v-if="status">New Motor</h5>
                            <h5 v-else>Update Motor</h5>
                        </div>
                        <div class="card-body">                          
                            <div class="row gutters">                             
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Section *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.section_id }" 
                                            :customClass="{ 'is-invalid': errors.section_id }" 
                                            :initialize="component.section_id" 
                                            id="section_id"
                                            label="section_name"
                                            placeholder="Select Section"
                                            :data="sections"
                                            @input="section => component.section_id = section">
                                        </search> 
                                    <span v-if="errors.section_id" class="invalid-feedback">{{ errors.section_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Location *</div>
                                        <select class="form-control" v-model="component.location" :class="{'is-invalid': errors.location}">
                                            <option value="">Select One</option>
                                            <option value="Plant">Plant</option>
                                            <option value="Store">Store</option>
                                            <option value="Scrap Yard">Scrap Yard</option>
                                            <option value="Warehouse">Warehouse</option>
                                        </select>
                                        <span v-if="errors.location" class="invalid-feedback">{{ errors.location[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Component Type *</div>
                                        <select class="form-control" v-model="component.component_type" :class="{'is-invalid': errors.component_type}">
                                            <option value="">Select One</option>
                                            <option value="HT Motor">HT Motor</option>
                                            <option value="LT Motor">LT Motor</option>
                                        </select>
                                        <span v-if="errors.component_type" class="invalid-feedback">{{ errors.component_type[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Serial Number *</div>
                                        <input class="form-control" v-model="component.serial_number" :class="{'is-invalid': errors.serial_number}" ref="serial_number" type="text" placeholder="Enter Serial Number" />
                                        <span v-if="errors.serial_number" class="invalid-feedback">{{ errors.serial_number[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Component Name *</div>
                                        <input class="form-control" v-model="component.component_name" :class="{'is-invalid': errors.component_name}" type="text" placeholder="Enter Component Name" />
                                        <span v-if="errors.component_name" class="invalid-feedback">{{ errors.component_name[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Make *</div>
                                        <input class="form-control" v-model="component.make" :class="{'is-invalid': errors.make}" type="text" placeholder="Enter Make" />
                                        <span v-if="errors.make" class="invalid-feedback">{{ errors.make[0] }}</span>
                                    </div>
                                </div>
                                 <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Model *</div>
                                        <input class="form-control" v-model="component.model" :class="{'is-invalid': errors.model}" type="text" placeholder="Enter Model" />
                                        <span v-if="errors.model" class="invalid-feedback">{{ errors.model[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Condition *</div>
                                        <select class="form-control" v-model="component.condition" :class="{'is-invalid': errors.condition}">
                                            <option value="">Select One</option>
                                            <option value="Clean">Clean</option>
                                            <option value="Dirty">Dirty</option>
                                            <option value="Rusted">Rusted</option>
                                            <option value="Damaged">Damaged</option>
                                            <option value="Repairable">Repairable</option>
                                            <option value="Scrap">Scrap</option>
                                        </select>
                                        <span v-if="errors.condition" class="invalid-feedback">{{ errors.condition[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Power Rating (KW Only) *</div>
                                        <input class="form-control" v-model="component.power_rating" :class="{'is-invalid': errors.power_rating}" type="text" placeholder="Enter Power Rating" />
                                        <span v-if="errors.power_rating" class="invalid-feedback">{{ errors.power_rating[0] }}</span>
                                    </div>
                                </div>
                                 <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Input Voltage (Volt Only) *</div>
                                        <input class="form-control" v-model="component.input_voltage" :class="{'is-invalid': errors.input_voltage}" type="text" placeholder="Enter Input Voltage" />
                                        <span v-if="errors.input_voltage" class="invalid-feedback">{{ errors.input_voltage[0] }}</span>
                                    </div>
                                </div>
                                 <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Frame Size *</div>
                                        <input class="form-control" v-model="component.frame_size" :class="{'is-invalid': errors.frame_size}" type="text" placeholder="Enter Frame Size" />
                                        <span v-if="errors.frame_size" class="invalid-feedback">{{ errors.frame_size[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">RPM *</div>
                                        <input class="form-control" v-model="component.rpm" :class="{'is-invalid': errors.rpm}" type="text" placeholder="RPM" />
                                        <span v-if="errors.rpm" class="invalid-feedback">{{ errors.rpm[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Mounting *</div>
                                        <input class="form-control" v-model="component.mounting" :class="{'is-invalid': errors.mounting}" type="text" placeholder="Mounting" />
                                        <span v-if="errors.mounting" class="invalid-feedback">{{ errors.mounting[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Status *</div>
                                        <select class="form-control" v-model="component.status" :class="{'is-invalid': errors.status}">
                                            <option value="">Select One</option>
                                            <option value="In Use">In Use</option>
                                            <option value="Expired">Expired</option>
                                            <option value="Ready">Ready</option>
                                            <option value="Standby">Standby</option>
                                            <option value="Overhauled">Overhauled</option>
                                        </select>
                                        <span v-if="errors.status" class="invalid-feedback">{{ errors.status[0] }}</span>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Expected Life (Month Only) *</div>
                                        <input class="form-control" v-model="component.expected_life" :class="{'is-invalid': errors.expected_life}" type="text" placeholder="Enter Expected Life" />
                                        <span v-if="errors.expected_life" class="invalid-feedback">{{ errors.expected_life[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Purchase Rate (Rs.) *</div>
                                        <input class="form-control" v-model="component.purchase_rate" :class="{'is-invalid': errors.purchase_rate}" type="number" placeholder="Enter Purcahse Rate" />
                                        <span v-if="errors.purchase_rate" class="invalid-feedback">{{ errors.purchase_rate[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Type of Purchase *</div>
                                        <select class="form-control" v-model="component.purchase_type" :class="{'is-invalid': errors.purchase_type}">
                                            <option value="">Select One</option>
                                            <option value="New">New</option>
                                            <option value="Refurbished">Refurbished</option>
                                            <option value="Repaired">Repaired</option>
                                            <option value="Recondition">Recondition</option>
                                        </select>
                                        <span v-if="errors.purchase_type" class="invalid-feedback">{{ errors.purchase_type[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Date of Purchase (DOP) *</div>
                                        <input class="form-control" v-model="component.purchase_at" :class="{'is-invalid': errors.purchase_at}" type="date" />
                                        <span v-if="errors.purchase_at" class="invalid-feedback">{{ errors.purchase_at[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <router-link to="/components" class="btn btn-danger">Discard</router-link>
                                    <button type="button" class="btn btn-primary ms-1" v-if="status" @click="addComponent">Submit</button>
                                    <button type="button" class="btn btn-primary ms-1" v-else @click="updateComponent">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
 let Search = require('@/components/Search.vue').default;
    export default {
    components: { Search },
        data() {
            return {
                component: {
                    component_id: "",
                    plant_id: this.$store.getters.user.plant_id,
                    section_id: "",
                    component_type: "",
                    serial_number: "",
                    component_name: "",
                    make: "",
                    condition: "",
                    power_rating: "",
                    expected_life: "",
                    purchase_rate: "",
                    purchase_type: "",
                    repairable: "NAN",
                    status: "",
                    location: "",
                    purchase_at: "",
                    rpm: "",
                    mounting: "",
                    repair_at: "",
                    removal_at: "",
                    scrapping_at: "",
                    model: "",
                    input_voltage: "",
                    frame_size: "",
                },
                plants: [],
                sections:[],
                errors: [],
                status: true,
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getSections();
                if (to.name == "Component.Create") {
                    vm.status = true;
                }
                else if(to.name == "Component.Copy") {
                    vm.status = true;
                    let uri = { uri: "showComponent/" + vm.$route.params.component_id };
                    vm.$store.dispatch("get", uri)
                    .then(function (response) {
                        vm.component = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
                else {
                    vm.status = false;
                    let uri = { uri: "showComponent/" + vm.$route.params.component_id };
                    vm.$store.dispatch("get", uri)
                    .then(function (response) {
                        vm.component = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },

        methods: {

            getSections() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getSections" })
                .then(function (response) {
                    vm.sections = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addComponent() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "addComponent", data: vm.component })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Motor is successfully created");
                    vm.$router.push("/components");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            updateComponent() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateComponent/" + vm.component.component_id, data: vm.component };
                vm.$store.dispatch("patch", uri)
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Motor is successfully updated");
                    vm.$router.push("/components");
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
        }
    };
</script>
<style></style>
