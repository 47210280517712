<template>
    <div class="content-wrapper-scroll">
        <!-- Content wrapper start -->
        <div class="content-wrapper">
            <!-- Row start -->
            <div class="row gutters">
                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                    <!-- Card start -->

                    <div class="card mb-4">
                        <div class="card-body">
                            <form>
                                <div class="row gutters">
                                    <h4><label class="col-sm-6 col-form-label">Ticket Details</label></h4>
                                </div>
                                <div class="row gutters">
                                    <label class="col-sm-12 col-form-label fw-bold">Ticket No: {{ticket?.ticket_no}}</label>
                                    <label class="col-sm-12 col-form-label fw-bold">Token Date: {{ticket?.ticket_date}}</label>
                                    <label class="col-sm-12 col-form-label fw-bold">Priority: {{ticket?.priority?.priority_name}}</label>
                                    <label class="col-sm-12 col-form-label fw-bold">Status: {{ticket?.ticket_status?.status?.status_name}}</label>
                                    <label class="col-sm-12 col-form-label fw-bold">Subject: {{ticket?.subject}}</label>
                                    <label class="col-sm-12 col-form-label fw-bold">Description: {{ticket?.description}}</label>
                                    <div class="col-sm-12">
                                        <label class="fw-bold me-2">Images:</label>
                                        <div v-for="(ticket_attachment,key) in ticket.ticket_attachments" :key="key">
                                            <a target="_blank" :href="ticket_attachment.attachment_path" class="fw-normal text-info me-2"> {{ticket_attachment.attachment_name}}</a><br />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-body">
                            <h4><label class="col-sm-6 col-form-label">Status</label></h4>
                        </div>

                        <ul class="feeds ps-0" v-for="(ticket_status,key) in ticket.ticket_statuses" :key="key">
                            <div class="feed-item mb-2 py-2 ps-4 pe-3">
                                <div class="border-start border-2 border-primary d-md-flex align-items-center">
                                    <div class="d-flex align-items-center" style="margin-left: 2rem;">
                                        <span class="badge badge-lg" :class="ticket_status?.status?.color" style="font-size: 12px;">{{ticket_status?.status?.status_name}}</span>
                                        <div class="ms-3 text-truncate float-end">
                                            <span class="text-dark font-weight-medium">{{convertDate(ticket_status?.created_at)}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ul>
                    </div>

                    <!-- Card end -->
                </div>

                <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                    <!-- Card start -->
                    <div class="card">
                        <div class="card-body">
                            <form>
                                <div class="row gutters">
                                    <h4><label class="col-sm-6 col-form-label">Replies</label></h4>
                                    <div class="card card-widget">
                                        <div class="card-body card-comments">
                                            <div v-for="(reply,key) in ticket.ticket_replies" :key="key">
                                                <div class="card-comment">
                                                    <div>
                                                        <img class="img-circle img-sm me-2" :src="$store.getters.assetUrl+'/users/'+reply.user.avatar" alt="User Image" style="height: 40px; border-radius: 50%;" />
                                                        <span class="fw-bold me-2"> {{reply?.user?.name }}</span><span>{{reply?.date_time}}</span>
                                                        <button type="button" class="btn btn-outline-danger btn-sm float-end" @click="deleteMessage(reply)">
                                                            <i class="far fa-trash-alt"></i>
                                                        </button>
                                                    </div>
                                                    <div>
                                                        <div class="comment-text">
                                                            {{ reply?.message }}
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <!-- try ends -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="card-footer">
                                        <img class="img-fluid img-circle img-sm mb-2 me-2" :src="$store.getters.assetUrl+'/users/'+$store.getters.user.avatar" alt="Alt Text" style="height: 40px; border-radius: 50%;" />
                                        <span class="fw-bold me-2"> {{this.$store.getters.user.name}}</span>
                                        <div class="img-push">
                                            <input
                                                type="text"
                                                class="form-control form-control-sm"
                                                :class="{'is-invalid':errors.message}"
                                                placeholder="Press enter to post message"
                                                v-model="ticket.message"
                                                ref="message"
                                                @keyup.enter="sendReply()"
                                            />
                                            <span v-if="errors.message" class="invalid-feedback">{{ errors.message[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <!-- Card end -->
                </div>

                <!-- table start -->

                <!-- Row end -->
            </div>
        </div>
    </div>
    <!-- Content wrapper scroll end -->
</template>
<script>
    import moment from "moment";
    export default {
        components: {},
        data() {
            return {
                errors: [],
                ticket: {
                    ticket_id: "",
                    user_id: "",
                    ticket_replies: [],
                    ticket_attachments: [],
                    ticket_statuses: [],
                    message: "",
                },
            };
        },
        mounted() {
            this.index();
        },

        methods: {
            index() {
                let vm = this;
                // showOrder/'+ vm.$route.params.equipment_id
                let uri = "showTicket/" + vm.$route.params.ticket_id;
                vm.$store
                    .dispatch("get", { uri: uri })
                    .then(function (response) {
                        vm.ticket = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            sendReply() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.ticket.ticket_id = vm.$route.params.ticket_id;
                vm.ticket.user_id = vm.$store.getters.user.user_id;
                let uri = { uri: "sendReply", data: vm.ticket };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Reply is successfully sent");
                        vm.ticket.message = "";
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            convertDate(date) {
                return moment(date).fromNow();
            },

            deleteMessage(reply) {
                if (confirm("Do you want to continue... ?")) {
                    let vm = this;

                    console.log(reply);
                    let uri = { uri: "deleteReply", data: reply };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function () {
                            vm.$store.dispatch("success", "Message is successfully deleted");
                            vm.index();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
        },
    };
</script>


