<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Tickets</h5>
                        </div>
                        <div class="card-body">
                        <div class="col-sm-12">
                            
                            <div class="input-group">
                                
                                <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                                
                            </div>
                        </div>     
                            <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('ticket_no')">
                                                Ticket No
                                            <span>
                                                    <i v-if="meta.field=='ticket_no' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='ticket_no' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th @click="sort('date')">
                                                Date
                                            <span>
                                                    <i v-if="meta.field=='date' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='date' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('proirity_id')">
                                                Priority
                                            <span>
                                                    <i v-if="meta.field=='priority_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='priority_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>     
                                            </th>
                                            <th @click="sort('status_id')">
                                                Status
                                            <span>
                                                    <i v-if="meta.field=='status_id' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='status_id' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                           
                                           
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="tickets.length==0">
                                            <td  colspan="8" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(ticket,key) in tickets" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td @click="viewTicket(ticket)" >{{ ticket.ticket_no}}</td>
                                            <td>{{ ticket.ticket_date }}</td>
                                            <td v-if="ticket.priority">
                                                    <span class="badge me-2" :class="ticket.priority?.color">
                                                {{ ticket.priority?.priority_name }}
                                            </span>
                                            </td>
                                            <td v-else>-</td>
                                            <td v-if="ticket.ticket_status" data-bs-toggle="modal" data-bs-target="#statusModal"  @click="view(ticket)">

                                                <span class="badge bg-success me-2">
                                                {{ ticket.ticket_status?.status?.status_name }}
                                            </span>


                                            </td>
                                            <td v-else>-</td>
                                            <td class="td-actions">
                                                <!-- <a href="#" class="icon green">
                                                    <i class="icon-lock"></i>
                                                </a> -->
                                                 <router-link v-can="'tickets.update'" :to="'/tickets/'+ticket.ticket_id+'/edit'" class="icon blue">
                                                    <i class="icon-pencil"></i>
                                                </router-link>
                                                <a href="#" v-can="'tickets.delete'" class="icon red" @click.prevent="deleteRow(ticket)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="modal fade" id="statusModal" tabindex="-1">
            <div class="modal-dialog" >
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Update Status</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <!-- <div class="field-wrapper">
                            <div class="field-placeholder">Status *</div>
                            <textarea class="form-control" :class="{'is-invalid': errors.remark}" placeholder="Enter Remark"></textarea>
                            <span v-if="errors.remark" class="invalid-feedback">{{ errors.remark[0] }}</span>
                        </div> -->
                        <!-- <div class="col-sm-4"> -->
									<div class="field-wrapper">
                                            <div class="field-placeholder">Status *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.status_id }" 
                                            :customClass="{ 'is-invalid': errors.status_id }" 
                                            :initialize="ticket.status_id" 
                                            id="status_id"
                                            label="status_name"
                                            placeholder="Select Status"
                                            :data="statuses"
                                            @input="status => ticket.status_id = status"                                           
                                            >
                                        </search> 
                                    <span v-if="errors.status_id" class="invalid-feedback">{{ errors.status_id[0] }}</span>
                                        </div>
									<!-- </div> -->
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" @click="updateStatus()" class="btn btn-primary" data-bs-dismiss="modal">Update</button>
                    </div>
                </div>
            </div>
        </div>
                            <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let Search = require('@/components/Search.vue').default;
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination, Search },
        data() {
            return {
                status: true,
                errors: [],
                tickets:[],
                statuses:[],
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "ticket_no",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    plant_id: this.$store.getters.user.plant_id,
                },
                ticket:{
                    status_id:"",
                    ticket_id:"",
                    user_id:this.$store.getters.user.user_id,
                }
            };
        },
        mounted() {
            this.index();
        },
        methods: {

           index() {
            
				let vm = this;
                vm.getStatuses();
				let uri = 'paginateTickets?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&field='+vm.meta.field+'&per_page='+vm.meta.per_page+'&plant_id='+vm.meta.plant_id;
				vm.$store.dispatch('post',{'uri':uri})
				.then(function (response) {
					vm.tickets = response.data.data;
				
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
                
			},  

            viewTicket(ticket) {
                this.$router.push("/tickets/" + ticket.ticket_id + "/view");
            },

            
              getStatuses() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getStatuses",data:{}})
                .then(function (response) {
                    vm.statuses = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            view(ticket)
            {
                let vm = this;
                vm.ticket.ticket_id = ticket.ticket_id;
            },
           


            updateStatus() {
                let vm = this;
                if (confirm("Do you want to update this entry ?")) {
                    // let loader = vm.$loading.show();
                    vm.$store.dispatch("post", { uri: "updateStatus" ,data:vm.ticket })
                    .then(function () {
                        // loader.hide();
                        vm.$store.dispatch("success", "Status is successfully updated");
                        vm.index();
                    })
                    .catch(function (error) {
                        // loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

                      

            deleteRow(ticket) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    // let loader = vm.$loading.show();
                    vm.$store.dispatch("delete", { uri: "deleteTicket/" + ticket.ticket_id })
                    .then(function () {
                        // loader.hide();
                        vm.$store.dispatch("success", "Ticket is successfully deleted");
                        vm.index();
                    })
                    .catch(function (error) {
                        // loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            // resetPassword(user) {
            //     let vm = this;
            //     if (confirm("Do you want to reset the password ?")) {
            //         let loader = vm.$loading.show();
            //         vm.$store.dispatch("post", { uri: "resetUserPassword",data:user})
            //         .then(function (response) {
            //             loader.hide();
            //             vm.$store.dispatch("success", "User password has been successfully updated");
            //             alert("New Password : " + response.data)
            //         })
            //         .catch(function (error) {
            //             loader.hide();
            //             vm.errors = error.response.data.errors;
            //             vm.$store.dispatch("error", error.response.data.message);
            //         });
            //     }
            // },

            // onPageChange(page) {
            //     this.meta.currentPage = page;
            //     this.index();
            // },

            // onPerPageChange() {
            //     this.meta.currentPage = 1;
            //     this.index();
            // },
            // sort(field) {
            //     this.meta.field = field;
            //     this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
            //     this.index();
            // },

            // search() {
			// 	let vm = this;
            //     vm.meta.currentPage=1;
            //     vm.index();
			// }  
        },
    };
</script>

<style scoped>
.modal .modal-body {
    padding: 1.2rem;
    max-height: calc(100vh - 212px);
}
</style>
