<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 v-if="status">New Ticket</h5>
                            <h5 v-else>Update Ticket</h5>
                        </div>
                        <div class="card-body">
                            <div class="row gutters">
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Ticket No *</div>
                                        <input class="form-control" v-model="ticket.ticket_no" :class="{'is-invalid': errors.ticket_no}" type="text" placeholder="Ticket No" />
                                        <span v-if="errors.ticket_no" class="invalid-feedback">{{ errors.ticket_no[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-wrapper">
                                            <div class="field-placeholder">Date *</div>
                                            <input class="form-control form-control-sm" type="date" placeholder="Date" v-model="ticket.ticket_date" />
                                            <span v-if="errors.ticket_date" class="invalid-feedback">{{ errors.ticket_date[0] }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Priority *</div>
                                        <search
                                            :class="{ 'is-invalid': errors.priority_id }"
                                            :customClass="{ 'is-invalid': errors.priority_id }"
                                            :initialize="ticket.priority_id"
                                            id="priority_id"
                                            label="priority_name"
                                            placeholder="Select Priority"
                                            :data="priorities"
                                            @input="priority => ticket.priority_id = priority"
                                        >
                                        </search>
                                        <span v-if="errors.priority_id" class="invalid-feedback">{{ errors.priority_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Subject *</div>
                                        <input class="form-control" v-model="ticket.subject" :class="{'is-invalid': errors.subject}" type="text" placeholder="Enter Subject" />
                                        <span v-if="errors.subject" class="invalid-feedback">{{ errors.subject[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Choose Documents <span v-if="status">*</span></div>
                                        <input multiple type="file" class="form-control" :class="{'is-invalid': errors.images}" @change="handleImageUpload($event)" ref="images" />
                                        <span v-if="errors.images" class="invalid-feedback">{{ errors.images[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Description *</div>
                                        <textarea class="form-control" v-model="ticket.description" :class="{'is-invalid': errors.description}" type="text" placeholder="Description" row="3" />
                                        <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <router-link to="/users" class="btn btn-danger">Discard</router-link>
                                    <button type="button" class="btn btn-primary ms-1" v-if="status" @click="addTicket">Submit</button>
                                    <button type="button" class="btn btn-primary ms-1" v-else @click="updateTicket()">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    let Search = require("@/components/Search.vue").default;
    export default {
        components: { Search },
        data() {
            return {
                ticket: {
                    user_id: this.$store.getters.user.user_id,
                    ticket_id: "",
                    ticket_no: "",
                    ticket_date: moment().format("YYYY-MM-DD"),
                    priority_id: "",
                    subject: "",
                    description: "",
                    images: [],
                },
                priorities: [],
                tickets: [],
                errors: [],
                
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getPriorities();
                vm.getTicketNo();
                if (to.name == "Ticket.Create") {
                    vm.status = true;
                } else {
                    vm.status = false;
                    let uri = { uri: "showTicket/" + vm.$route.params.ticket_id };
                    vm.$store
                        .dispatch("get", uri)
                        .then(function (response) {
                            vm.ticket = response.data.data;
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            });
        },

        methods: {
            getTicketNo() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getTicketNo" })
                    .then(function (response) {
                        vm.ticket.ticket_no = response.data["ticket_no"];
                        vm.ticket.priority_id = response.data["priority_id"];
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getPriorities() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getPriorities", data: {} })
                    .then(function (response) {
                        vm.priorities = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            addTicket() {
                let vm = this;
                // let loader = vm.$loading.show();
                let formData = new FormData();
                formData.append("user_id", vm.ticket.user_id);
                formData.append("ticket_no", vm.ticket.ticket_no);
                formData.append("ticket_date", vm.ticket.ticket_date);
                formData.append("priority_id", vm.ticket.priority_id);
                formData.append("subject", vm.ticket.subject);
                formData.append("description", vm.ticket.description);
                formData.append("images", vm.ticket.images);
                for (let i = 0; i < vm.ticket.images.length; i++) {
                    formData.append(`images[${i}]`, vm.ticket.images[i]);
                }
                vm.$store
                    .dispatch("post", { uri: "addTicket", data: formData })
                    .then(function () {
                        // loader.hide();
                        vm.$store.dispatch("success", "Ticket is successfully Created");
                        vm.$router.push("/tickets");
                    })
                    .catch(function (error) {
                        // loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            handleImageUpload(e) {
                e.preventDefault();
                let vm = this;
                vm.ticket.images = vm.$refs.images.files;
            },

            updateTicket() {
                let vm = this;
                vm.ticket.ticket_id = vm.$route.params.ticket_id;
                // let loader = vm.$loading.show();
                let formData = new FormData();
                formData.append("ticket_id", vm.ticket.ticket_id);
                formData.append("user_id", vm.ticket.user_id);
                formData.append("ticket_no", vm.ticket.ticket_no);
                formData.append("ticket_date", vm.ticket.ticket_date);
                formData.append("priority_id", vm.ticket.priority_id);
                formData.append("subject", vm.ticket.subject);
                formData.append("description", vm.ticket.description);
                // formData.append("images", vm.ticket.images);
                if(vm.ticket.images.length>0)
                {
                for (let i = 0; i < vm.ticket.images.length; i++) {
                    formData.append(`images[${i}]`, vm.ticket.images[i]);
                }
                vm.$store
                    .dispatch("post", { uri: "updateTicket", data: formData })
                    .then(function () {
                        // loader.hide();
                        vm.$store.dispatch("success", "Ticket is successfully Updated");
                        vm.$router.push("/tickets");
                    })
                    .catch(function (error) {
                        // loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },
        },
    };
</script>
<style></style>
