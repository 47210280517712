<template>
    <!-- Content wrapper scroll start -->
    <div class="content-wrapper-scroll">
        <!-- Content wrapper start -->
        <div class="content-wrapper">
            <!--Row start-->
            <!-- bar graph starts -->
            <div class="row gutters">
                <!---- uper portion start -->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <!-- Card start -->
                    <div class="card">
                        <div class="card-body">
                            <!-- <div class="row gutters mb-3">
                                <div class="col-sm-10">
                                    <h4><label> Severity Reports</label></h4>
                                </div>
                                <div class="col-sm-2">
                                    <button type="submit" v-if="status" @click="index" class="btn btn-primary btn-block">Search</button>
                                </div>
                            </div> -->
                            <!-- select box1 start -->
                            <div class="row gutters">
                                <div class="col-sm-10">
                                    <h4><label> Severity Reports</label></h4>
                                </div>
                                
                                <div class="col-sm-2">                                    
                                        <div class="field-wrapper"> 
                                             <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.escalation_matrix_id }" v-model="meta.escalation_matrix_id"  @change="index">
                                                <option value="">Select Escalation</option>
                                                <option v-for="(escalationMat,key) in escalationMatrix" :key="key" :value="escalationMat.escalation_matrix_id">{{ escalationMat.escalation_notification}}</option>
                                            </select>
                                    </div>
                                </div>

                                 
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('equipment_group_name')">
                                                Equipment Group
                                                <span>
                                                    <i v-if="meta.field=='equipment_group_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='equipment_group_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('equipment_type_name')">
                                                Equipment Type
                                            <span>
                                                    <i v-if="meta.field=='equipment_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='equipment_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('component_type_name')">
                                                Component Type
                                            <span>
                                                    <i v-if="meta.field=='component_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='component_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('check_type_name')" >
                                                Check Type
                                            <span>
                                                    <i v-if="meta.field=='check_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='check_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('default_value')">
                                                Default
                                            <span>
                                                    <i v-if="meta.field=='default_value' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='default_value' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('auto_fill')">
                                                Auto Fill
                                            <span>
                                                    <i v-if="meta.field=='auto_fill' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='auto_fill' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>     
                                            </th>
                                            <th @click="sort('ucl')">
                                                UCL
                                             <span>
                                                    <i v-if="meta.field=='ucl' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='ucl' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>       
                                            </th>
                                            <th @click="sort('ucl')">
                                                LCL
                                            <span>
                                                    <i v-if="meta.field=='lcl' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='lcl' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>  
                                            </th>
                                            <th @click="sort('value')">
                                                Value
                                            <span>
                                                    <i v-if="meta.field=='value' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='value' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                              <th >Occurence </th>
                                              <th >Severity </th>
                                              <th >Occrence*severity</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="user_equipment_components.length==0">
                                            <td  colspan="13" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(user_equipment_components,key) in user_equipment_components" :key="user_equipment_components.user_equipment_component_id">
                                            <td>{{meta.from+key}}</td>
                                            <td>{{user_equipment_components.equipment_group.equipment_group_name}}</td>
                                            <td>{{user_equipment_components.equipment_type.equipment_type_name}}</td>
                                            <td>{{user_equipment_components.component_type.component_type_name}}</td>
                                            <td>{{user_equipment_components.check_type.check_type_name}}</td>
                                            <td>{{user_equipment_components.default_value}}</td>
                                            <td v-if="user_equipment_components.auto_fill === 1">Yes</td>
                                            <td v-else>No</td>
                                            <td>{{user_equipment_components.ucl}}</td>
                                            <td>{{user_equipment_components.lcl}}</td>
                                            <td>{{user_equipment_components.value}}</td>
                                            <td>{{user_equipment_components.occurrence}}</td>
                                            <td>{{user_equipment_components.severity}}</td>
                                            <td>{{user_equipment_components.occurrence_severity}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="form-actions-footer">
                                    <div class="text-end row">
                                        <div class="col-sm-2">
                                            <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                                <option>10</option>
                                                <option>15</option>
                                                <option>20</option>
                                                <option>25</option>
                                                <option>30</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-10">
                                            <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- summary -->
                  

                    <!-- table end -->
                </div>
                <!-- Row end -->
               
            </div>
            <!-- bar graph ends -->
        </div>
    </div>
    <!-- Content wrapper scroll end -->
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
   // import moment from 'moment'
   // let Search = require("@/components/Search.vue").default;
    export default {
        components: { Pagination },
        data() {
            return {
                user_equipment_component: {
                    plant_id: "",
                    user_equipment_component_id: "",
                    user_template_id: "",
                    user_equipment_id: "",
                    equipment_id: "",
                    equipment_component_id: "",
                    equipment_group_id: "",
                    equipment_type_id: "",
                    component_type_id: "",
                    check_type_id: "",
                    default_value: "",
                    value: "",
                    auto_fill: "",
                    component_id: "",
                    ucl: "",
                    lcl: "",
                },
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "user_equipment_component_id",                   
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    plant_id:"",

                    escalation_matrix_id: "",
                    // equipment_id: "",
                    // equipment_component_id: "",
                    // equipment_group_id: "",
                    // equipment_type_id: "",
                    // component_type_id: "",
                    // check_type_id: "",
                    // from_date: "",
                    // to_date: "",
                },
                escalationMatrix: [],
                user_equipment_components: [],
                errors: [],
                status: true,
            };
        },
        mounted() {
            let vm = this;
            vm.getEscalationMatrices();
           // vm.getequipmentGroup();
           // vm.getequipmentType();
            //vm.getcomponentTypes();
           // vm.getCheck_Type();
            vm.index();
        },
        methods: {
            index() {
                let vm = this;
                vm.meta.plant_id=vm.$store.getters.user.plant_id              
                vm.$store
                    .dispatch("post", { uri: "getCheckTypeOccurrenceReport", data: vm.meta })
                    .then(function (response) {
                        response.data.data;
                        vm.user_equipment_components = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                       // vm.getChecklistSummary();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

             getEscalationMatrices() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "escalationMatrices" })
                    .then(function (response) {
                        response.data.data;
                        vm.escalationMatrix = response.data.data;
                        vm.meta.totalRows = response.data.total;
                        vm.meta.lastPage = response.data.last_page;
                        vm.meta.from = response.data.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                       
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            reset() {
                let vm = this;
                vm.master.master_id = "";
                vm.master.plant_id = "";
                vm.master.functional_id = "";
                vm.master.department_id = "";
                vm.master.section_id = "";
                vm.master.equipment_group_id = "";
                vm.master.equipment_type_id = "";
                vm.master.component_type_id = "";
                vm.master.check_type_id = "";
                vm.status = true;
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
            sort(field) {
                this.meta = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },

        //     sort_summary(field_summary) {
        //         this.meta = field_summary;
        //         this.meta.order_by_summary = this.meta.order_by_summary=='asc' ? 'desc' : 'asc';
        //         this.getChecklistSummary();
        //     },

        // search_summary() {
		// 		let vm = this;
        //         vm.meta.currentPage_summary=1;
        //         vm.getChecklistSummary();
		// 	},  
        //      onPageChange_summary(page_summary) {
        //         this.meta.page_summary = page_summary;
        //         this.getChecklistSummary();
        //     },
        //     onPerPageChange_summary() {
        //         this.meta.page_summary = 1;
        //         this.getChecklistSummary();
        //     },
          

        },
    };
</script>
