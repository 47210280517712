<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <router-link to="/users" class="col-sm-2">
                    <div class="stats-tile2">
                        <div class="sale-icon">
                            <i class="icon-user1"></i>
                        </div>
                        <div class="sale-details">
                            <h2>{{ count.users }}</h2>
                            <p>Users</p>
                        </div>
                    </div>
                </router-link>
                <router-link to="/department" class="col-sm-2">
                    <div class="stats-tile2">
                        <div class="sale-icon">
                            <i class="icon-tonality"></i>
                        </div>
                        <div class="sale-details">
                            <h2>{{ count.departments }}</h2>
                            <p>Departments</p>
                        </div>
                    </div>
                </router-link>
                <router-link to="/components" class="col-sm-2">
                    <div class="stats-tile2">
                        <div class="sale-icon">
                            <i class="icon-book"></i>
                        </div>
                        <div class="sale-details">
                            <h2>{{ count.components }}</h2>
                            <p>Motors</p>
                        </div>
                    </div>
                </router-link>
                <router-link to="/equipments" class="col-sm-2">
                    <div class="stats-tile2">
                        <div class="sale-icon">
                            <i class="icon-box"></i>
                        </div>
                        <div class="sale-details">
                            <h2>{{ count.equipments }}</h2>
                            <p>Equipments</p>
                        </div>
                    </div>
                </router-link>
                <router-link to="/templates" class="col-sm-2">
                    <div class="stats-tile2">
                        <div class="sale-icon">
                            <i class="icon-book"></i>
                        </div>
                        <div class="sale-details">
                            <h2>{{ count.templates }}</h2>
                            <p>Templates</p>
                        </div>
                    </div>
                </router-link>
                <router-link to="/checklist" class="col-sm-2">
                    <div class="stats-tile2">
                        <div class="sale-icon">
                            <i class="icon-layers"></i>
                        </div>
                        <div class="sale-details">
                            <h2>{{ count.user_templates }}</h2>
                            <p>Checklist</p>
                        </div>
                    </div>
                </router-link>
            </div>
            <div class="row gutters">
                <div class="col-sm-4">
                    <div class="row gutters">
                        <div class="col-sm-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Frequency</div>
                                        <search 
                                            :initialize="meta.frequency_id" 
                                            id="frequency_id"
                                            label="frequency_name"
                                            placeholder="Select Frequency"
                                            :data="frequencies"
                                            @input="changeFrequency($event)">
                                        </search>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" @click="getDepartmentCompliance">
                            <div class="stats-tile2" :style="{background:getColor(count.compliance)}">
                                <div class="sale-details">
                                    <h2 style="color: #fff;">{{ count.compliance }}%</h2>
                                    <p style="color: #fff;">Checks Compliance</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" @click="getDepartmentTemplateCompliance">
                            <div class="stats-tile2" :style="{background:getColor(count.template_compliance)}">
                                <div class="sale-details">
                                    <h2 style="color: #fff;">{{ count.template_compliance }}%</h2>
                                    <p style="color: #fff;">Checklist Compliance</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" @click="getDeviatedComponents">
                            <div class="stats-tile2" style="background: #7E8274;">
                                <div class="sale-details">
                                    <h2 style="color: #fff;">{{ count.deviated }}</h2>
                                    <p style="color: #fff;">Deviated Checks</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6" @click="getResolvedComponents">
                            <div class="stats-tile2" style="background: #28a745;">
                                <div class="sale-details">
                                    <h2 style="color: #fff;">{{ count.resolved }}</h2>
                                    <p style="color: #fff;">Resolved Deviations</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8" v-if="departments.length!=0">
                    <h5 class="mb-3">Department Compliance</h5>
                    <div class="row gutters">
                        <router-link :to="'/complianceReport/'+department.department_id" class="col-sm-3" v-for="(department,key) in departments" :key="key">
                            <div class="stats-tile2" :style="{background:getColor(department.compliance)}">
                                <div class="sale-details">
                                    <h2 style="color: #fff;">{{ department.compliance }}%</h2>
                                    <p style="color: #fff;">{{ department.department_name }}</p>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-sm-8" v-if="deviated_components.length!=0">
                    <div class="card">
                        <div class="card-header">
                            <h5>Deviated Components</h5>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th class="text-center">Action</th>
                                            <th>Template Name</th>
                                            <th>Date Time</th>
                                            <th>Component Type</th>
                                            <th>Check Type</th>
                                            <th>Default</th>
                                            <th>Value</th>
                                            <th>Note</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(deviated_component,key) in deviated_components" :key="key">
                                            <td>{{meta.from+key}}</td>
                                            <td class="text-center">
                                                <button data-bs-toggle="modal" data-bs-target="#remarkModal" @click="addRemark(deviated_component)" class="btn btn-xs btn-primary">
                                                    <i class="fa fa-add"></i>
                                                </button>
                                            </td>
                                            <td>{{deviated_component.template_name}}</td>
                                            <td>{{deviated_component.date_time}}</td>
                                            <td>{{deviated_component.component_type?.component_type_name}}</td>
                                            <td>{{deviated_component.check_type?.check_type_name}}</td>
                                            <td>{{deviated_component.default_value}}</td>
                                            <td>{{deviated_component.value}}</td>
                                            <td>{{deviated_component.note}}</td>
                                        </tr>
                                    </tbody>
                                </table>                               
                            </div>
                             <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-12">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8" v-if="resolved_components.length!=0">
                    <div class="card">
                        <div class="card-header">
                            <h5>Resolved Deviations</h5>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Template Name</th>
                                            <th>Date Time</th>
                                            <th>Component Type</th>
                                            <th>Check Type</th>
                                            <th>Default</th>
                                            <th>Value</th>
                                            <th>Note</th>
                                            <th>Remark</th>
                                            <th>Remarked By</th>
                                            <th>Remark Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(resolved_component,key) in resolved_components" :key="key">
                                            <td>{{meta.from+key}}</td>
                                            <td>{{resolved_component.template_name}}</td>
                                            <td>{{resolved_component.date_time}}</td>
                                            <td>{{resolved_component.component_type?.component_type_name}}</td>
                                            <td>{{resolved_component.check_type?.check_type_name}}</td>
                                            <td>{{resolved_component.default_value}}</td>
                                            <td>{{resolved_component.value}}</td>
                                            <td>{{resolved_component.note}}</td>
                                            <td>{{resolved_component.remark}}</td>
                                            <td>{{resolved_component?.remark_user?.name}}</td>
                                            <td>{{resolved_component.remark_date_time}}</td>
                                        </tr>
                                    </tbody>
                                </table>                               
                            </div>
                             <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-12">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="remarkModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Update Remark</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="field-wrapper">
                            <div class="field-placeholder">Remark *</div>
                            <textarea class="form-control" v-model="remark.remark" :class="{'is-invalid': errors.remark}" placeholder="Enter Remark"></textarea>
                            <span v-if="errors.remark" class="invalid-feedback">{{ errors.remark[0] }}</span>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" @click="updateRemark()" class="btn btn-primary" data-bs-dismiss="modal">Update</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    let Search = require("@/components/Search.vue").default;
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Search,Pagination },
        data() {
            return {
                meta:{
                    user_id:this.$store.getters.user?.user_id,
                    role_id:this.$store.getters.user?.role_id,
                    plant_id:this.$store.getters.user?.plant_id,
                    frequency_id:'',
                    department_id:'',

                    search: "",
                    order_by: "asc",
                    //field: "user_equipment_component_id",                   
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                count:{
                    users:0,
                    departments:0,
                    components:0,
                    equipments:0,
                    templates:0,
                    user_templates:0,
                    compliance:0,
                    deviated:0,
                    resolved:0,
                    template_compliance:0
                },
                remark: {
                    user_id:this.$store.getters.user?.user_id,
                    user_equipment_component_id:'',
                    remark:''
                },
                frequencies:[],
                deviated_components:[],
                resolved_components: [],
                departments:[],
                errors: [],
            };
        },
        mounted() {
            let vm = this;
            vm.getCounts();
        },
        methods: {
            getColor(value) {
                if(value >= 80) {
                    return "green";
                }
                else if(value >= 50) {
                    return "orange";
                }
                else {
                    return "red";
                }
            },

            getCounts() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getCounts", data:vm.meta })
                .then(function (response) {
                    vm.count.users = response.data['users'];
                    vm.count.departments = response.data['departments'];
                    vm.count.components = response.data['components'];
                    vm.count.equipments = response.data['equipments'];
                    vm.count.templates = response.data['templates'];
                    vm.count.user_templates = response.data['user_templates'];
                    vm.getFrequencies()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getFrequencies() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getFrequenciesPlantWise", data:vm.meta })
                .then(function (response) {
                    vm.frequencies = response.data.data;
                    if(vm.frequencies.length!=0) {
                        vm.meta.frequency_id = vm.frequencies[0].frequency_id;
                        vm.getComplianceCount()
                    } 
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            changeFrequency(frequency_id) {
                let vm = this;
                vm.meta.frequency_id = frequency_id;
                vm.getComplianceCount()
            },

            getComplianceCount() {
                let vm = this;
                vm.departments = [];
                vm.deviated_components = [];
                vm.$store.dispatch("post", { uri: "getComplianceCount", data:vm.meta })
                .then(function (response) {
                    vm.count.compliance = response.data['compliance'];
                    vm.count.deviated = response.data['deviated'];
                    vm.count.resolved = response.data['resolved'];
                    vm.count.template_compliance = response.data['template_compliance'];
                    vm.deviated_components = []
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getDeviatedComponents() {
                let vm = this;
                vm.departments = [];
                vm.deviated_components = [];
                vm.resolved_components = [];
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "getDeviatedComponents", data:vm.meta })
                .then(function (response) {
                    loader.hide();
                    vm.deviated_components = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getResolvedComponents() {
                let vm = this;
                vm.departments = [];
                vm.deviated_components = [];
                vm.resolved_components = [];
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "getResolvedComponents", data:vm.meta })
                .then(function (response) {
                    loader.hide();
                    vm.resolved_components = response.data.data;
                    vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },


            getDepartmentTemplateCompliance() {
                let vm = this;
                vm.departments = [];
                vm.deviated_components = [];
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "getDepartmentTemplateCompliance", data:vm.meta })
                .then(function (response) {
                    loader.hide();
                   vm.departments = response.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getDepartmentCompliance() {
                let vm = this;
                vm.departments = [];
                vm.deviated_components = [];
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "getDepartmentCompliance", data:vm.meta })
                .then(function (response) {
                    loader.hide();
                   vm.departments = response.data;
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addRemark(user_equipment_component) {
                let vm = this;
                vm.remark.user_equipment_component_id = user_equipment_component?.user_equipment_component_id;
                vm.remark.remark = user_equipment_component?.remark;
            },

            updateRemark() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateDeviatedRemarks", data: vm.remark };
                vm.$store.dispatch("post", uri)
                .then(function () {
                    loader.hide();
                    vm.remark.user_equipment_component_id = "";
                    vm.remark.remark = "";
                    vm.$store.dispatch("success", "Remark is successfully updated");
                    vm.getComplianceCount()
                    vm.getDeviatedComponents()
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            onPageChange(page) {
                this.meta.page = page;
                this.getDeviatedComponents();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.getDeviatedComponents();
            },
            sort(field) {
                this.meta = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.getDeviatedComponents();
            },
        },
    };
</script>

<style>
    .btn-xs {
        padding: 0.2rem 0.4rem;
    }
</style>