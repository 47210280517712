<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>{{component.component_name}}</h5>
                        </div>
                        <div class="card-body">
                            <div class="row gutters">
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Activity Date *</div>
                                        <input class="form-control" v-model="component_activity.activity_date" :class="{'is-invalid': errors.activity_date}" type="date" />
                                        <span v-if="errors.activity_date" class="invalid-feedback">{{ errors.activity_date[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Activity Time *</div>
                                        <input class="form-control" v-model="component_activity.activity_time" :class="{'is-invalid': errors.activity_time}" type="time" />
                                        <span v-if="errors.activity_time" class="invalid-feedback">{{ errors.activity_time[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Status *</div>
                                        <select class="form-control" v-model="component_activity.status" :class="{'is-invalid': errors.status}" @change="getLatestActivity()">
                                            <option value="">Select One</option>
                                            <option value="Added">Added</option>
                                            <option value="Removed">Removed</option>
                                        </select>
                                        <span v-if="errors.status" class="invalid-feedback">{{ errors.status[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Reason for Remove *</div>
                                        <select class="form-control" v-model="component_activity.reason" :class="{'is-invalid': errors.reason}" :disabled="component_activity.status!='Removed'">
                                            <option value="">Select One</option>
                                            <option value="Overhauling">Overhauling</option>
                                            <option value="Damaged">Damaged</option>
                                            <option value="Rewinding">Rewinding</option>
                                            <option value="Scrapped">Scrapped</option>
                                            <option value="Upgrade">Upgrade</option>
                                            <option value="Not Applicable">Not Applicable</option>
                                            <option value="Others">Others</option>
                                        </select>
                                        <span v-if="errors.reason" class="invalid-feedback">{{ errors.reason[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Equipment</div>
                                        <select class="form-control" :class="{'is-invalid': errors.equipment_id}" v-model="component_activity.equipment_id" :disabled="component_activity.status=='Removed'">
                                            <option value="">Select Equipment</option>
                                            <option v-for="equipment,key in equipments" :key="key" :value="equipment.equipment_id">
                                                {{ equipment.equipment_uid }} - {{ equipment.equipment_name }}
                                            </option>
                                        </select>
                                        <span v-if="errors.equipment_id" class="invalid-feedback">{{ errors.equipment_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Maintenance Cost</div>
                                        <input class="form-control" v-model="component_activity.maintenance_cost" placeholder="Maintenance Cost" :class="{'is-invalid': errors.maintenance_cost}" type="number" />
                                        <span v-if="errors.maintenance_cost" class="invalid-feedback">{{ errors.note[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-12">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Note</div>
                                        <textarea class="form-control" v-model="component_activity.note" :class="{'is-invalid': errors.note}"></textarea>
                                        <span v-if="errors.note" class="invalid-feedback">{{ errors.note[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <router-link to="/components" class="btn btn-danger">Discard</router-link>
                                    <button type="button" class="btn btn-primary ms-1" @click="addComponentActivity">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Activities</h5>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Status</th>
                                            <th>Equipment</th>
                                            <th>Reason</th>
                                            <th>Maintenance Cost</th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(component_activity,key) in component_activities" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{ component_activity.activity_date }}</td>
                                            <td>{{ component_activity.activity_time }}</td>
                                            <td>{{ component_activity.status }}</td>
                                            <td v-if="component_activity.equipment">
                                                {{ component_activity.equipment.equipment_uid }} -
                                                {{ component_activity.equipment.equipment_name }}
                                            </td>
                                            <td v-else>-</td>
                                            <td>{{ component_activity.reason }}</td>
                                            <td>{{ component_activity.maintenance_cost }}</td>
                                            <td class="td-actions">
                                                <a href="#" class="icon red" @click.prevent="deleteRow(component_activity)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                component_activity : {
                    activity_date:'',
                    activity_time:'',
                    component_id:'',
                    equipment_id:'',
                    equipment_component_id:'',
                    status:'',
                    reason:'',
                    maintenance_cost:'0',
                    note:'',
                },
                meta: {
                    search: "",
                    order_by: "desc",
                    keyword: "component_activity_id",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    component_id:'',
                },
                component_activities: [],
                component: {},
                equipments:[],
                errors: []
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.status = false;
                let uri = { uri: "showComponent/" + vm.$route.params.component_id };
                vm.$store.dispatch("get", uri)
                .then(function (response) {
                    vm.component = response.data.data;
                    vm.component_activity.component_id = vm.component.component_id;
                    vm.meta.component_id = vm.component.component_id;
                    vm.getEquipments()
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            });
        },

        methods: {
            getEquipments() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getAllEquipments",data:vm.$store.getters.user})
                .then(function (response) {
                    vm.equipments = response.data;
                    vm.index();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            index() {
				let vm = this;
                vm.$store.dispatch("post", { uri: "componentActivities",data:vm.meta})
				.then(function (response) {
					vm.component_activities = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},

            getLatestActivity() {
                let vm = this;
                if(vm.component_activity.status=='Removed') {
                    let loader = vm.$loading.show();
                    vm.$store.dispatch("post", { uri: "getLatestActivity", data: vm.component_activity })
                    .then(function (response) {
                        loader.hide();
                        vm.component_activity.equipment_id = response.data.equipment_id;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
                else {
                    vm.component_activity.reason = '';
                    vm.component_activity.equipment_id = '';
                }
            },

            addComponentActivity() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "addComponentActivity", data: vm.component_activity })
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch("success", "Activity is successfully created");
                    vm.component_activity.activity_date = "";
                    vm.component_activity.activity_time = "";
                    vm.component_activity.equipment_id = "";
                    vm.component_activity.equipment_component_id = "";
                    vm.component_activity.status = "";
                    vm.component_activity.reason = "";
                    vm.component_activity.maintenance_cost = "0";
                    vm.component_activity.note = "";
                    vm.errors = [];
                    vm.index();
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            deleteRow(component_activity) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    vm.$store.dispatch("delete", { uri: "deleteComponentActivity/" + component_activity.component_activity_id })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Activity is successfully deleted");
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
        }
    };
</script>
<style></style>
