<template>
    <div class="content-wrapper">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <i class="fa fa-home"></i>
                    Dashboard
                </li>
                <li class="breadcrumb-item active">Permissions</li>
                <li class="breadcrumb-right">
                    <router-link to="/permissions" class="btn btn-sm btn-success">
                        <i class="fa fa-list"></i>
                        Permissions
                    </router-link>
                </li>
            </ol>
        </nav>
        <div class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <form class="card" @submit.prevent="submitForm">
                            <!-- <form class="card" > -->
                            <div class="card-header">
                                <strong v-if="status">New Permission</strong>
                                <strong v-if="status1">Update Permission</strong>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label for="exampleInputUsername1">Role</label>
                                            <select class="form-control form-control-sm" :class="{ 'is-invalid': errors.role }" v-model="permission.role_id">
                                                <option value="">Select Role</option>
                                                <option v-for="(role,key) in roles" :key="key" :value="role.role_id">{{role.role }}</option>
                                            </select>
                                            <span v-if="errors.role" class="invalid-feedback">{{ errors.role[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="form-label">Module *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.module}" placeholder="Module" v-model="permission.module" ref="module" />
                                            <span v-if="errors.module" class="invalid-feedback">{{ errors.module[0] }}</span>
                                        </div>
                                    </div>

                                    <div class="col-sm-4">
                                        <div class="form-group">
                                            <label class="form-label">Permission *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.permission}" placeholder="Permission" v-model="permission.permission" ref="permission" />
                                            <span v-if="errors.permission" class="invalid-feedback">{{ errors.permission[0] }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-4" v-if="!status">
                                        <div class="form-group">
                                            <label class="form-label">Access *</label>
                                            <input type="text" class="form-control form-control-sm" :class="{'is-invalid': errors.access}" placeholder="Access" v-model="permission.access" ref="access" />
                                            <span v-if="errors.access" class="invalid-feedback">{{ errors.access[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="float-right">
                                    <router-link to="/permissions" class="btn btn-sm btn-outline-danger" type="button"> <i class="fas fa-ban"></i> Discard </router-link>
                                    <button class="btn btn-sm btn-outline-success" type="submit">
                                        <span v-if="status"> <i class="fas fa-save"></i> Submit </span>
                                        <span v-else> <i class="fas fa-save"></i> Update </span>
                                    </button>

                                    <!-- <button class="btn btn-sm btn-outline-success" v-else @click="update()">
										<span >
											<i class="fas fa-save"></i> Update
										</span>
									</button> -->
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Permission.Create",
        data() {
            return {
                status: true,
                status1: false,
                permission: {
                    permission_id: "",
                    role_id: "",
                    permission: "",
                    // description:'',
                },
                roles: [],
                permissions: [],
                errors: [],
            };
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getRoles();
                if (to.name == "Permissions.Create") {
                    vm.$refs.permission.focus();
                } else {
                    let uri = { uri: "showPermission", data: { permission_id: to.params.permission_id } };
                    vm.$store
                        .dispatch("post", uri)
                        .then(function (response) {
                            vm.permission = response.data.data;
                            vm.permission.role = vm.permission.role.role_id;
                            console.log(vm.permission.role.role_id);
                            vm.status = false;
                            vm.$refs.module.focus();
                        })
                        .catch(function (error) {
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            });
        },
        methods: {
            submitForm() {
                let vm = this;
                if (vm.status) {
                    vm.create();
                } else {
                    vm.update();
                }
            },

            getRoles() {
                let vm = this;
                let uri = { uri: "getRoles" };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        vm.roles = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            create() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "addPermission", data: vm.permission };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Permission is successfully created");
                        vm.$router.push("/permissions");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            update() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updatePermission", data: vm.permission };
                // console.log(vm.role);
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Permission is successfully updated");
                        vm.$router.push("/permissions");
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },
        },
    };
</script>
