<template>
    <!-- Content wrapper scroll start -->
    <div class="content-wrapper-scroll">
        <!-- Content wrapper start -->
        <div class="content-wrapper">   
            <!-- bar graph starts -->
            <div class="row gutters">
                <!---- uper portion start -->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <!-- Card start -->
                     <div class="card"> 
                        <div class="card-body">
                            <div class="row gutters mb-3">
                                <div class="col-sm-10">
                                    <h4><label> Daily Report</label></h4>
                                </div>
                            </div>

                          <div class="row gutters">
                                <div class="col-sm-6">
                                    <div class="input-group">
                                        <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search_summary" @keyup.enter="search_summary">                                    
                                        <span class="input-group-text">
                                            <i class="fas fa-search"></i>
                                        </span>
                                </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Date</div>
                                        <input class="form-control form-control-sm" type="date" placeholder="Date" v-model="meta.created_date" />
                                        <span v-if="errors.created_date" class="invalid-feedback">{{ errors.created_date[0] }}</span>
                                    </div>
                                </div>
                               <div class="col-sm-3">
                                    <button type="submit" v-if="status" @click="getChecklistSummary" class="btn btn-primary btn-block">Search Uploaded Template</button>
                                </div>
                          </div>    
                            
                            <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('job_no')">Template Name</th>
                                            <th>Uploaded (Y/N)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="user_templates_summary.length==0">
                                            <td colspan="8" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(template,key) in user_templates_summary" :key="key">
                                            <td>{{ meta.from_summary+key }}</td>                                          
                                            <td>{{ template.template_name}}</td>
                                            <td>{{ checkDate(template.user_template) }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page_summary" @change="onPerPageChange_summary">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage_summary" :totalPages="meta.lastPage_summary" :currentPage="meta.page_summary" @pagechanged="onPageChange_summary" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- table end -->
                </div>
                <!-- Row end -->
            </div>
            <!-- bar graph ends -->
        </div>
    </div>
    <!-- Content wrapper scroll end -->
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    import moment from 'moment'
    export default {
        components: { Pagination },
        data() {
            return {
                // user_equipment_component: {
                //     plant_id: "",
                //     user_equipment_component_id: "",
                //     user_template_id: "",
                //     user_equipment_id: "",
                //     equipment_id: "",
                //     equipment_component_id: "",
                //     equipment_group_id: "",
                //     equipment_type_id: "",
                //     component_type_id: "",
                //     check_type_id: "",
                //     default_value: "",
                //     value: "",
                //     auto_fill: "",
                //     component_id: "",
                //     ucl: "",
                //     lcl: "",
                // },
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "user_equipment_component_id",                   
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    plant_id:"",

                    search_summary: "",
                    order_by_summary: "asc",                   
                    field_summary: "template_id",
                    per_page_summary: 10,
                    totalRows_summary: 0,
                    page_summary: 1,
                    lastPage_summary: 1,
                    from_summary: 1,
                    maxPage_summary: 1,
                    plant_id_summary:"",
                    frequency_id_summary:1,
                    currentPage_summary:1,
                    created_date:moment().format('YYYY-MM-DD'),
                 
                    equipment_id: "",
                    equipment_component_id: "",
                    equipment_group_id: "",
                    equipment_type_id: "",
                    component_type_id: "",
                    check_type_id: "",
                    from_date: "",
                    to_date: "",
                },
                equipment_group: [],
               // user_equipment_components: [],                
                user_templates_summary: [],
                equipment_type: [],
                component_type: [],
                equipmentGroup: [],
                equipmentType: [],
                componentTypes: [],
                check_Type: [],
                errors: [],
                status: true,
            };
        },
        mounted() {
            let vm = this;
            vm.getequipmentGroup();
            vm.getequipmentType();
            vm.getcomponentTypes();
            vm.getCheck_Type();
            vm.getChecklistSummary();
        },
        methods: {
            
            getChecklistSummary()  {
                let vm = this;
                 vm.meta.plant_id_summary=vm.$store.getters.user.plant_id
                let uri = "getChecklistSummary?page=" + vm.meta.page_summary + "&search=" + vm.meta.search_summary + "&frequency_id=" + vm.meta.frequency_id_summary + "&order_by=" + vm.meta.order_by_summary + "&field=" + vm.meta.field_summary + "&per_page=" + vm.meta.per_page_summary + "&plant_id=" + vm.meta.plant_id_summary+"&created_date= "+vm.meta.created_date;
                vm.$store
                    .dispatch("post", { uri: uri })
                    .then(function (response) {                         
                        response.data.data;                       
                        vm.user_templates_summary = response.data.data;
                        vm.meta.totalRows_summary = response.data.total;
                        vm.meta.lastPage_summary = response.data.last_page;                       
                        vm.meta.from_summary = response.data.from;
                        vm.meta.maxPage_summary = vm.meta.lastPage_summary >= 3 ? 3 : vm.meta.lastPage_summary;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            checkDate(user_template) 
            {         
                let vm = this;      
                if(user_template.length>0)
                {   
                    let data = user_template.filter(function (user_temp) {
                        var d = moment(user_temp.date_time).format('yyyy-MM-DD');
                        var today = moment(vm.meta.created_date).format('yyyy-MM-DD');
                        return d==today
                    });                    
                    if(data.length==0) {
                        return "No";
                    }
                    else {
                        return "Yes";
                    }
                }                 
                else
                {
                     return "No";
                }
                 
            },



            getequipmentGroup() {
                let vm = this;
                // let uri = "equipmentGroups?page=" + vm.meta.page + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=equipment_group_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getEquipmentGroups" })
                    .then(function (response) {
                        response.data.data;
                        vm.equipmentGroup = response.data.data;
                        vm.meta.totalRows = response.data.total;
                        vm.meta.lastPage = response.data.last_page;
                        vm.meta.from = response.data.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                       
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getequipmentType() {
                let vm = this;
                // let uri = "equipmentTypes?page=" + vm.meta.page + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=equipment_type_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getEquipmentTypes" })
                    .then(function (response) {
                        response.data.data;
                        vm.equipmentType = response.data.data;

                        console.log(vm.equipmentType);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getcomponentTypes() {
                let vm = this;
                // let uri = "componentTypes?page=" + vm.meta.page + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=component_type_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getComponentTypes" })
                    .then(function (response) {
                        response.data.data;
                        vm.componentTypes = response.data.data;

                        console.log(vm.componentTypes);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getCheck_Type() {
                let vm = this;
                // let uri = "checkTypes?page=" + vm.meta.page + "&search=" + vm.meta.search +
                //      "&order_by=" + vm.meta.order_by + "&field=check_type_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getCheckTypes" })
                    .then(function (response) {
                        response.data.data;
                        vm.check_Type = response.data.data;
                    
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            reset() {
                let vm = this;
                vm.master.master_id = "";
                vm.master.plant_id = "";
                vm.master.functional_id = "";
                vm.master.department_id = "";
                vm.master.section_id = "";
                vm.master.equipment_group_id = "";
                vm.master.equipment_type_id = "";
                vm.master.component_type_id = "";
                vm.master.check_type_id = "";
                vm.status = true;
            },          

            sort_summary(field_summary) {
                this.meta = field_summary;
                this.meta.order_by_summary = this.meta.order_by_summary=='asc' ? 'desc' : 'asc';
                this.getChecklistSummary();
            },

        search_summary() {
				let vm = this;
                vm.meta.page_summary=1;
                vm.getChecklistSummary();
			},  
             onPageChange_summary(page_summary) {
                this.meta.page_summary = page_summary;
                this.getChecklistSummary();
            },
            onPerPageChange_summary() {
                this.meta.page_summary = 1;
                this.getChecklistSummary();
            },

        },
    };
</script>
<style>
    .apexcharts-legend {
        display: flex;
        overflow: auto;
        padding: 0 10px;
    }

    .apexcharts-legend.position-bottom,
    .apexcharts-legend.position-top {
        flex-wrap: wrap;
    }

    .apexcharts-legend.position-right,
    .apexcharts-legend.position-left {
        flex-direction: column;
        bottom: 0;
    }

    .apexcharts-legend.position-bottom.apexcharts-align-left,
    .apexcharts-legend.position-top.apexcharts-align-left,
    .apexcharts-legend.position-right,
    .apexcharts-legend.position-left {
        justify-content: flex-start;
    }

    .apexcharts-legend.position-bottom.apexcharts-align-center,
    .apexcharts-legend.position-top.apexcharts-align-center {
        justify-content: center;
    }

    .apexcharts-legend.position-bottom.apexcharts-align-right,
    .apexcharts-legend.position-top.apexcharts-align-right {
        justify-content: flex-end;
    }

    .apexcharts-legend-series {
        cursor: pointer;
        line-height: normal;
    }

    .apexcharts-legend.position-bottom .apexcharts-legend-series,
    .apexcharts-legend.position-top .apexcharts-legend-series {
        display: flex;
        align-items: center;
    }

    .apexcharts-legend-text {
        position: relative;
        font-size: 14px;
    }

    .apexcharts-legend-text *,
    .apexcharts-legend-marker * {
        pointer-events: none;
    }

    .apexcharts-legend-marker {
        position: relative;
        display: inline-block;
        cursor: pointer;
        margin-right: 5px;
    }

    .apexcharts-legend.apexcharts-align-right .apexcharts-legend-series,
    .apexcharts-legend.apexcharts-align-left .apexcharts-legend-series {
        display: inline-block;
    }

    .apexcharts-legend-series.apexcharts-no-click {
        cursor: auto;
    }

    .apexcharts-legend .apexcharts-hidden-zero-series,
    .apexcharts-legend .apexcharts-hidden-null-series {
        display: none !important;
    }

    .apexcharts-inactive-legend {
        opacity: 0.45;
    }
</style>
