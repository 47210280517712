<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Permisssion</h5>
                        </div>
                        <div class="card-body">
                            <div class="row gutters">
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Role *</div>
                                        <search
                                            :class="{ 'is-invalid': errors.role_id }"
                                            :customClass="{ 'is-invalid': errors.role_id }"
                                            :initialize="role_ability.role_id"
                                            id="role_id"
                                            label="role_name"
                                            placeholder="Select Role"
                                            :data="roles"
                                            @input="selectRole($event)"
                                        >
                                        </search>
                                        <span v-if="errors.role_id" class="invalid-feedback">{{ errors.role_id[0] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-if="role_ability.role_id ">
                                <div class="accordion-item" v-for="module,key in modules" :key="key">
                                    <h2 class="accordion-header" :id="'panelsStayOpen-heading'+key">
                                        <button
                                            class="accordion-button collapsed"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            :data-bs-target="'#panelsStayOpen-collapse'+key"
                                            aria-expanded="false"
                                            :aria-controls="'panelsStayOpen-collapse'+key"
                                        >
                                            {{module.module_name}}
                                        </button>
                                    </h2>
                                    <div :id="'panelsStayOpen-collapse'+key" class="accordion-collapse collapse" :aria-labelledby="'panelsStayOpen-heading'+key">
                                        <div class="accordion-body row">
											<div class="col-sm-3 mb-4" v-for="ability,key2 in module.abilites" :key="key2">
												<input v-if="testCheckMark(ability.role_abilites)==0" type="checkbox" @change="unCheckedValues(ability.module_id,ability.ability_id)" />
												<input v-else type="checkbox" checked="checked" @change="checkedValues(ability.role_abilites)" />
												{{ ability.description ? ability.description : ability.ability }}
											</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    td {
        display: table-cell;
        vertical-align: inherit;
        width: 32%;
    }
</style>

<script>
    // import Pagination from '@/components/Pagination.vue';
    let Search = require("@/components/Search.vue").default;
    export default {
        components: { Search },
        name: "Permissions.Index",
        data() {
            return {
                meta: {
                    search: "",
                    order_by: "asc",
                    keyword: "module_id",
                    per_page: 100,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                status: true,
                module: {
                    module_id: "",
                    description: "",
                    abilities: [],
                    module_name: "",
                },
                role_ability: {
                    role_ability_id: "",
                    ability_id: "",
                    role_id: "",
                },

                count: 0,
                access: [],
                roles: [],
                errors: [],
                modules: [],
                abilities: [],
            };
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.getRoles();
                vm.getModules();
            });
        },

        methods: {
            testCheckMark(role_ability) {
                let vm = this;
                let count = 0;
                role_ability.forEach((value) => {
                    console.log(value.role_id);
                    if (value.role_id == vm.role_ability.role_id) {
                        count = count + 1;
                    }
                });

                if (count > 0) return 1;
                else return 0;
            },

            checkedValues(role_ability_obj) {
                let vm = this;
                role_ability_obj.forEach((value) => {
                    if (value.role_id == vm.role_ability.role_id) {
                        // alert("RoleAbility role_ability_id : "+value.role_ability_id);
                        // alert("RoleAbility ability_id : "+value.ability_id);
                        //alert(value.role_ability_id);
                        //call function to delete or Create in roleAbility
                        vm.deleteRoleAbility(value.role_ability_id);
                    }
                });
            },

            unCheckedValues(module_id, ability_id) {
                let vm = this;
                //alert(module_id+"--"+ability_id);
                vm.addRoleAbility(ability_id);
            },

            getModules() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "getModules?page=" + vm.meta.currentPage + "&search=" + vm.meta.search + "&order_by=" + vm.meta.order_by + "&keyword=" + vm.meta.keyword + "&per_page=" + vm.meta.per_page };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.modules = response.data.data;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            addRoleAbility(ability_id) {
                let vm = this;
                let loader = vm.$loading.show();
                vm.role_ability.ability_id = ability_id;
                vm.$store
                    .dispatch("post", { uri: "addRoleAbility", data: vm.role_ability })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "RoleAbility Added successfully");
                        vm.getModules();
                        vm.reset();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deleteRoleAbility(role_ability_id) {
                let vm = this;
                let loader = vm.$loading.show();
                vm.role_ability.role_ability_id = role_ability_id;
                vm.$store
                    .dispatch("post", { uri: "deleteRoleAbility", data: vm.role_ability })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "RoleAbility Deleted successfully");
                        vm.getModules();
                        vm.reset();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getRoles() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "getRoles", data: vm.permission };
                vm.$store
                    .dispatch("post", uri)
                    .then(function (response) {
                        loader.hide();
                        vm.roles = response.data.data;
                        if (vm.roles.length != 0) {
                            vm.permission.role_id = vm.roles[0].role_id;
                            vm.getPermissions();
                        }
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            updateCheck(permission) {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "makeTaxDefault", data: permission };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            check() {
                if (this.permission.access == 1) {
                    this.ischeckAll = true;
                } else {
                    this.ischeckAll = false;
                }
            },

            addRoles() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "add_permission_roles?role_id=" + vm.permission.role_id };
                vm.$store
                    .dispatch("post", uri)
                    .then(function () {
                        loader.hide();
                        vm.getPermissions();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            selectRole(e) {
                let vm = this;
                vm.role_ability.role_id = e;
                vm.getModules();
            },
        },
    };
</script>
