<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <div class="col-sm-12">
                    <div class="card">
                    
                        <div class="card-header">
                        <div class="col-sm-4">
                          
                            <h5 >Template | Checklist</h5>
                        </div>
                         <div class="col-sm-4">
                          
                              <span class="float-end"><router-link to="/templates" class="btn btn-primary">
                                <i class="fas fa-arrow-left"></i><i class="fas fa-th-list"></i>
                                Templates
                            </router-link></span>
                        </div>
                        </div>
                        
                        <div class="card-body">
                        
                            <div class="row gutters">
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Template Name</div>
                                        <input class="form-control" v-model="template.template_name"  ref="template_name" type="text" readonly />
                                       
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder" >Plant </div>
                                        <input class="form-control" v-if="template.plant"  v-model="template.plant.plant_name"  type="text" readonly/>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Department *</div>
                                         <input class="form-control" v-if="template.department"  v-model="template.department.department_name"  type="text" readonly/>
                                        
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Functional </div>
                                        <input class="form-control" v-if="template.functional"  v-model="template.functional.functional_name"  type="text" readonly/>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Category </div>
                                        <input class="form-control" v-if="template.category"  v-model="template.category.category_name"  type="text" readonly/>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Frequency</div>
                                      <input class="form-control" v-if="template.frequency"  v-model="template.frequency.frequency_name"  type="text" readonly/> 
                                    </div>
                                </div>
                               
                            </div>
                            <hr>
                            <div class="row gutters">
                                <div class="col-sm-12">
                                    <div class="accordion" id="myAccordion">
                                        <div class="accordion-item" v-for="(equipment,i) in selected_equipments" :key="i">
                                            
                                            <h2 class="accordion-header row" id="headingOne">
                                                <div class="col-sm-1" style="margin-top:8px">
                                                   
                                                </div>
                                                <div class="col-sm-12">
                                                    <button type="button" class="accordion-button collapsed" data-bs-toggle="collapse" :data-bs-target="'#collapse'+i">{{i+1}}. {{equipment.equipment_uid}} - {{ equipment.equipment_name }}</button>
                                                </div>
                                            </h2>
                                            <div :id="'collapse'+i" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table class="table m-0">
                                                            <thead>
                                                                <tr>
                                                                    <th>Equipment Group</th>
                                                                    <th>Equipment Type</th>
                                                                    <th>Component Type</th>
                                                                    <th>Check Type</th>
                                                                    <th>Default Value</th>
                                                                    <th class="text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr v-for="equipment_component,j in equipment.equipment_components" :key="j">
                                                                    <td>{{equipment_component.equipment_group.equipment_group_name}}</td>
                                                                    <td>{{equipment_component.equipment_type.equipment_type_name}}</td>
                                                                    <td>{{equipment_component.component_type.component_type_name}}</td>
                                                                    <td>{{equipment_component.check_type.check_type_name}}</td>
                                                                    <td>{{equipment_component.default_value}}</td>
                                                                        
                                                                    <td class="text-center">
                                                                        <input class="form-check-input" type="checkbox" disabled="disabled" :id="'check'+equipment_component.equipment_component_id" :value="equipment_component.equipment_component_id" v-model="equipment_component.status">
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           <!-- <div class="form-actions-footer mt-2">
                                <div class="text-end">
                                    <router-link to="/templates" class="btn btn-danger">Discard</router-link>
                                    <button class="btn btn-primary ms-1" v-if="status" @click="addTemplate">Submit</button>
                                    <button class="btn btn-primary ms-1" v-else @click="updateTemplate">Update</button>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                template: {
                    template_name: "",
                    category_id:"",
                    plant_id: "",
                    department_id: "",
                    functional_id: "",
                    section_id: "",
                    frequency_id: "",
                    equipment_id:"",
                    template_equipments: [],
                    deleted_template_equipments: [],
                },
                categories: [],
                plants: [],
                departments: [],
                functionals: [],
                sections: [],
                frequencies: [],
                equipments: [],
                selected_equipments: [],
                errors: [],
                status: true,
            };
        },

        mounted() {
            let vm = this;
            vm.getCategories(); 
        },

        beforeRouteEnter(to, from, next) {
            next(vm => {
                if(to.name=='Templates.show') {
                    vm.getPlats(); 
                }
                else {
                    let uri ='showTemplate/'+ vm.$route.params.template_id;
                    vm.status = false;
                    vm.$store.dispatch("get", { uri:uri})
                    .then(function (response) {
                        vm.getPlats();
                        Object.assign(vm.template, response.data.data);
                        Object.assign(vm.selected_equipments, vm.template.template_equipments);
                        vm.$set(vm.template, 'deleted_template_equipments', []);
                        vm.$set(vm.template, 'template_equipments', []);
                        vm.$set(vm.template, 'section_id', '');
                        vm.$set(vm.template, 'equipment_id', '');
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },

        methods: {

            getPlats() {
                let vm = this;
                vm.$store .dispatch("post", { uri: "getPlants" })
                .then(function (response) {
                    vm.plants = response.data.data;
                    vm.getCategories();
                    if(vm.status) {
                        vm.template.plant_id = vm.plants[0].plant_id;
                    }
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getCategories() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getCategories" })
                .then(function (response) {
                    vm.categories = response.data.data;
                    vm.getFunctions();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getFunctions() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getFunctionals" })
                .then(function (response) {
                    vm.functionals = response.data.data;
                    vm.getDepartment();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getDepartment() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getDepartments" })
                .then(function (response) {
                    vm.departments = response.data.data;
                    vm.getSection();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getSection() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getSections" })
                .then(function (response) {
                    vm.sections = response.data.data;
                    vm.getFrequencies();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getFrequencies() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getFrequencies" })
                .then(function (response) {
                    vm.frequencies = response.data.data;
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            getEquipments() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getEquipments",data:vm.template })
                .then(function (response) {
                    vm.equipments = response.data.data;
                })
                .catch(function (error) {
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },

            addEquipment() {
                let vm = this;
                if(vm.template.equipment_id!=''){
                    let equipment = vm.equipments.filter(function (el) {
                        return el.equipment_id == vm.template.equipment_id;
                    });
                    let selected_equipment = vm.selected_equipments.filter(function (el) {
                        return el.equipment_id == vm.template.equipment_id;
                    });
                    if(selected_equipment.length==0) {
                        vm.selected_equipments.push(equipment[0]);
                    }
                }
            },

            deleteRow(equipment,key) {
                let vm = this;
                vm.template.deleted_template_equipments.push(equipment);
                vm.selected_equipments.splice(key, 1);
            },

            async addTemplate() {
                let vm = this;
                let loader = vm.$loading.show();
                await vm.selected_equipments.forEach((equipment) => {
                    let template_equipment_components = [];
                    equipment.equipment_components.forEach((equipment_component) => {
                        if(equipment_component.status==true) {
                            template_equipment_components.push(equipment_component);
                        }
                    });    
                    if(template_equipment_components.length!=0) {
                        let template_equipment = {
                            template_id:'',
                            equipment_id:equipment.equipment_id,
                            template_equipment_components:template_equipment_components,
                        }
                        vm.template.template_equipments.push(template_equipment);
                    }
                });

                await vm.$store.dispatch("post", { uri: "addTemplate",data:vm.template })
                .then(function (response) {
                    loader.hide();
                    vm.$store.dispatch('success','Template is successfully created');
                    vm.$router.push('/templates');
                    console.log(response);
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
        },
    };
</script>
<style></style>
