<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-4">
                    <div class="card">
                        <div class="card-header">
                            <h5>New Check Type</h5>
                        </div>
                        <div class="card-body">
                            <div class="field-wrapper">
                                <input class="form-control" type="text" :class="{'is-invalid': errors.check_type_name}" placeholder="Name" v-model="checktype.check_type_name" ref="check_type_name" />
                                <span v-if="errors.check_type_name" class="invalid-feedback">{{ errors.check_type_name[0] }}</span>
                                <div class="field-placeholder">Check Type <span class="text-danger">*</span></div>
                               
                            </div>

                            <div class="field-wrapper">
                                <input class="form-control" type="text" :class="{'is-invalid': errors.default_value}" placeholder="Default Value " v-model="checktype.default_value" ref="default_value" />
                                <span v-if="errors.default_value" class="invalid-feedback">{{ errors.default_value[0] }}</span>
                                <div class="field-placeholder">Default Value <span class="text-danger"></span></div>
                              
                            </div>

                            <div class="field-wrapper">
                                <select class="form-control" :class="{'is-invalid': errors.field_type}" placeholder="Field Type" v-model="checktype.field_type" ref="field_type">
                                    <option value="">Select Field Type</option>
                                    <option>Text</option>
                                    <option>Number</option>
                                    <option>Select</option>
                                </select>
                                <span v-if="errors.field_type" class="invalid-feedback">{{ errors.field_type[0] }}</span>
                                <div class="field-placeholder">Field Type <span class="text-danger">*</span></div>
                               
                            </div>

							<div class="field-wrapper">
                                <input class="form-control" type="number" :class="{'is-invalid': errors.lcl}" placeholder="LCL " v-model.number="checktype.lcl" ref="lcl" />
                                <span v-if="errors.lcl" class="invalid-feedback">{{ errors.lcl[0] }}</span>
                                <div class="field-placeholder">LCL</div>
                            </div>

							<div class="field-wrapper">
                                <input class="form-control" type="number" :class="{'is-invalid': errors.ucl}" placeholder="UCL" v-model.number="checktype.ucl" ref="ucl" />
                                <span v-if="errors.ucl" class="invalid-feedback">{{ errors.ucl[0] }}</span>
                                <div class="field-placeholder">UCL</div>
                            </div>

                            <div class="field-wrapper">
                                <textarea class="form-control" rows="2" :class="{'is-invalid': errors.description}" placeholder="Field Values" v-model="checktype.description" ref="description"></textarea>
                                <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                                <div class="field-placeholder">Field Values <span class="text-danger">*</span></div>
                            </div>

                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <button class="btn btn-danger" @click="reset">Discard</button>
                                    <button  v-can="'check_types.create'"  class="btn btn-primary ms-1" v-if="status" @click="addCheckType">Submit</button>
                                    <button  v-can="'check_types.update'"  class="btn btn-primary ms-1" v-else @click="updateCheckType">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="card">
                        <div class="card-header">
                            <h5>Check Types</h5>
                        </div>
                        <div class="card-body">
                            <div class="col-sm-12">
                                <div class="input-group">
                                    <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                    <span class="input-group-text">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>

                            <div class="table-responsive tabletop" >
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('check_type_name')">
                                                Name
                                            <span>
                                                    <i v-if="meta.field=='check_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='check_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('default_value')">
                                                Default Value
                                            <span>
                                                    <i v-if="meta.field=='default_value' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='default_value' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>     
                                            </th>
                                            <th @click="sort('field_type')">
                                                Field Type
                                            <span>
                                                    <i v-if="meta.field=='field_type' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='field_type' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
											<th @click="sort('lcl')">
                                                LCL
                                            <span>
                                                    <i v-if="meta.field=='lcl' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='lcl' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
											<th @click="sort('ucl')">
                                                UCL
                                            <span>
                                                    <i v-if="meta.field=='ucl' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='ucl' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('description')">
                                                Field Values
                                             <span>
                                                    <i v-if="meta.field=='description' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='description' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="check_types.length==0">
                                            <td  colspan="8" class="text-center">No records found</td>
                                        </tr>
                                        <tr role="row" v-for="(check_type,key) in check_types" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{ check_type.check_type_name }}</td>
                                            <td>{{ check_type.default_value }}</td>
                                            <td>{{ check_type.field_type }}</td>
											<td>{{ check_type.lcl }}</td>
											<td>{{ check_type.ucl }}</td>
                                            <td>{{ check_type.description }}</td>

                                            <td class="td-actions">
                                                <a href="#"  v-can="'check_types.update'" class="icon green" @click.prevent="edit(check_type)">
                                                    <i class="icon-pencil"></i>
                                                </a>
                                                <a href="#"  v-can="'check_types.delete'"  class="icon red" @click.prevent="deleteCheckType(check_type.check_type_id)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-actions-footer">
                                    <div class="text-end row">
                                        <div class="col-sm-2">
                                            <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                                <option>10</option>
                                                <option>15</option>
                                                <option>20</option>
                                                <option>25</option>
                                                <option>30</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-10">
                                            <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                status: true,
                checktype: {
                    check_type_id: "",
                    check_type_name: "",
                    description: "",
                    default_value: "",
                    field_type: "",
					lcl:"",
					ucl:""
                },
                errors: [],
                check_types: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "check_type_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
            };
        },
        mounted() {
            this.index();
        },
        methods: {
            index() {
                let vm = this;
                let uri = "checkTypes?page=" + vm.meta.currentPage + "&search=" + vm.meta.search + "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + "&per_page=" + vm.meta.per_page;
                vm.$store.dispatch("post", { uri: uri })
				.then(function (response) {
					response.data.data;
					vm.check_types = response.data.data;
					vm.meta.totalRows = response.data.total;
					vm.meta.lastPage = response.data.last_page;
					vm.meta.from = response.data.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },
            addCheckType() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch("post", { uri: "addCheckType", data: vm.checktype })
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Check Type Added successfully");
					vm.reset();
					vm.errors = [];
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },

            updateCheckType() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateCheckType/" + vm.checktype.check_type_id, data: vm.checktype };
                vm.$store.dispatch("patch", uri)
				.then(function () {
					loader.hide();
					vm.$store.dispatch("success", "Check Type is successfully updated");
					vm.reset();
					vm.errors = [];
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
            },

            deleteCheckType(check_types) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteCheckType/" + check_types };
                    vm.$store.dispatch("delete", uri)
					.then(function () {
						loader.hide();
						vm.$store.dispatch("success", "Check Type is successfully deleted");
						vm.reset();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
                }
            },

            edit(check_types) {
                let vm = this;
                (vm.checktype = check_types), (vm.status = false), vm.$refs.check_type_name.focus();
            },

            reset() {
                let vm = this;
                vm.checktype.check_type_name = "";
                vm.checktype.description = "";
                vm.checktype.default_value = "";
                vm.checktype.field_type = "";
				vm.checktype.lcl = "";
				vm.checktype.ucl = "";
                vm.$refs.check_type_name.focus();
                vm.index();
                vm.errors = [];
                vm.status = true;
            },

            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
            search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			}
        },
    };
</script>
<style >
.tabletop{
    margin-top: 10px;
}
</style>
