<template>
<!-- Content wrapper scroll start -->
 <div class="content-wrapper-scroll">

    <!-- Content wrapper start -->
    <div class="content-wrapper">

      <!-- form row -->
        <div class="row">
            <div class="col-sm-4">
                <div class="card">
                <div class="card-header">
                            <h5>New Function</h5>
                        </div>
                    <div class="card-body">
                     <!-- Field wrapper start -->
                        <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.functional_name}" placeholder="Name" v-model="functionss.functional_name" ref="functional_name">
                            <span v-if="errors.functional_name" class="invalid-feedback">{{ errors.functional_name[0] }}</span>
                            <div class="field-placeholder">Functions Name <span class="text-danger">*</span>
                            </div>
                       
                        </div>
                       <!-- Field wrapper end -->
                         <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.functional_head}" placeholder="Function Head" v-model="functionss.functional_head" ref="functional_head">
                            <span v-if="errors.functional_head" class="invalid-feedback">{{ errors.functional_head[0] }}</span>
                            <div class="field-placeholder">Function Head
                            </div>
                        
                        </div>

                        <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.functional_head_phone}" placeholder="Section Head Phone" v-model="functionss.functional_head_phone" ref="functional_head_phone">
                            <span v-if="errors.functional_head_phone" class="invalid-feedback">{{ errors.functional_head_phone[0] }}</span>
                            <div class="field-placeholder">Phone no
                            </div>
                         
                        </div>
                         <div class="field-wrapper">
                            <input class="form-control" type="email" :class="{'is-invalid': errors.functional_head_email}" placeholder="Section Head Email" v-model="functionss.functional_head_email" ref="functional_head_email">
                            <span v-if="errors.functional_head_email" class="invalid-feedback">{{ errors.functional_head_email[0] }}</span>
                            <div class="field-placeholder">Email Id
                            </div>
                         
                        </div>

                        <!-- Field wrapper start -->
                         <!-- Field wrapper end -->
                        <div class="field-wrapper">
                            <textarea class="form-control" rows="2" :class="{'is-invalid': errors.description}" placeholder="description" v-model="functionss.description" ref="description"></textarea>
                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                            <div class="field-placeholder">Description <span class="text-danger">*</span>
                            </div>
                          
                        </div>
                        <!-- Field wrapper end -->
                         <div class="form-actions-footer">
                                <div class="text-end">
                                    <button class="btn btn-danger" @click="reset">Discard</button>
                                    <button v-can="'functionals.create'" class="btn btn-primary ms-1" v-if="status" @click="addfunctions">Submit</button>
                                    <button v-can="'functionals.update'" class="btn btn-primary ms-1" v-else @click="updatefunction">Update</button>
                                </div>
                            </div>
                     </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="card">
                 <div class="card-header">
                            <h5>Functions</h5>
                        </div>
                    <div class="card-body">
                       
                        <div class="col-sm-12">
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                            </div>
                        </div>

                        <div class="table-responsive" style="margin-top: 10px;">
                                 
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr >
                                            <th>#</th>
                                           
                                            <th @click="sort('functional_name')" >
                                                Function
                                            <span>
                                                    <i v-if="meta.field=='functional_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='functional_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('functional_head')">
                                                Head
                                            <span>
                                                    <i v-if="meta.field=='functional_head' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='functional_head' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>      
                                            </th>
                                            <th @click="sort('functional_head_phone')">
                                                Phone
                                            <span>
                                                    <i v-if="meta.field=='functional_head_phone' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='functional_head_phone' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>  
                                            </th>
                                            <th @click="sort('functional_head_email')">
                                                Email
                                            <span>
                                                    <i v-if="meta.field=='functional_head_email' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='functional_head_email' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>  
                                            </th>
                                            <th @click="sort('description')">
                                                Description
                                             <span>
                                                    <i v-if="meta.field=='description' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='description' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="functionsal.length==0">
                                            <td  colspan="7" class="text-center">No records found</td>
                                        </tr>
                                     
                                        <tr v-for="(functional,key) in functionsal" :key="functional.functional_id">
                                           
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{functional.functional_name}}</td>                                        
                                            <td>{{functional.functional_head}}</td>
                                            <td>{{functional.functional_head_phone}}</td>
                                            <td>{{functional.functional_head_email}}</td>
                                            <td>{{functional.description}}</td>
                                              <td class="td-actions">
                                                <a href="#" v-can="'functionals.update'"  class="icon green" @click.prevent="edit(functional)">
                                                    <i class="icon-pencil"></i>
                                                </a>
                                                <a href="#" v-can="'functionals.delete'" class="icon red" @click.prevent="deletefunction(functional.functional_id)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                            </tr>
                                       
                                    </tbody>
                                </table>
                        </div>
                             <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import Pagination from '@/components/Pagination.vue';
export default{
    components: { Pagination },
data(){
    return{
         status: true,
        functionss:{
            functional_name:'',
            description:'',
            functional_head: '',
            functional_head_phone: '',
            functional_head_email: '',
            functional_id:''
        },
        errors:[],
        functionsal:[],
         meta: {
                    search: "",
                    order_by: "asc",
                    field: "functional_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
    }
},
    mounted(){
            this.index()
        },
        methods: {

            index() {
                let vm = this;
                let uri = "functionals?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                 "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field +
                  "&per_page=" + vm.meta.per_page;
                vm.$store.dispatch("post", { uri:uri})
                    .then(function (response) {
                        response.data.data;
                        console.log(response.data.data);
                        vm.functionsal = response.data.data;
                        vm.meta.totalRows = response.data.total;
                        vm.meta.lastPage = response.data.last_page;
                        vm.meta.from = response.data.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                       
                    })
                    .catch(function (error) {
                        console.log(error)
                    //    vm.errors = error.response.data.errors;
                    // ,    vm.$store.dispatch("error", error.response.data.message);
                    });
            },
             addfunctions() {
                 
                let vm = this;
                let loader = vm.$loading.show();
                //    console.log(vm.unit)
                vm.$store.dispatch("post", { uri: "addFunctional", data: vm.functionss })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "functions Added successfully");
                       vm.index();
                        vm.reset();
                        vm.errors=[];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },


            updatefunction() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateFunctional/" + vm.functionss.functional_id, data: vm.functionss };
                vm.$store
                    .dispatch("patch", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "function is successfully updated");
                        vm.index();
                        vm.reset();
                        vm.errors=[];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deletefunction(functionss) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteFunctional/" + functionss };
                    vm.$store
                        .dispatch("delete", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "function is successfully deleted");
                            vm.index()
                            vm.reset();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            edit(functional) {
                let vm = this;
                (vm.functionss = functional), (vm.status = false), vm.$refs.functional_name.focus();
            },
             reset(){
                let vm = this;
                vm.functionss.functional_name = "";
                vm.functionss.description = "";
                vm.functionss.functional_head="",
                vm.functionss.functional_head_phone="",
                vm.functionss.functional_head_email="",
                vm.$refs.functional_name.focus();
                vm.errors=[];
                vm.status = true;
            },

             onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },
            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
            search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			} 
        },

}
</script>
<style>
.btn {
            
            margin-right: 10px;
        }
</style>