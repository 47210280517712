<template>
    <div class="content-wrapper-scroll">
        <!-- Content wrapper start -->
        <div class="content-wrapper">
            <!-- Row start -->
            <div class="row gutters">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <!-- Card start -->
                    <div class="card">
                        <div class="card-header">
                            <h5>Master</h5>
                        </div>
                        <div class="card-body">
                            <!-- select box1 start -->
                            <div class="row gutters">
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Plant *</div>
                                        <search 
                                            :class="{ 'is-invalid': errors.plant_id }" 
                                            :customClass="{ 'is-invalid': errors.plant_id }" 
                                            :initialize="master.plant_id" 
                                            id="plant_id"
                                            label="plant_name"
                                            placeholder="Select Plant"
                                            :data="plants"
                                            @input="plant => master.plant_id = plant"
                                            disabled="disabled">
                                        </search>
                                        <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <search 
                                            :class="{ 'is-invalid': errors.section_id }" 
                                            :customClass="{ 'is-invalid': errors.section_id }" 
                                            :initialize="master.section_id" 
                                            id="section_id"
                                            label="section_name"
                                            placeholder="Select Sections"
                                            :data="sections"
                                            @input="section => master.section_id = section">
                                        </search>
                                    <span v-if="errors.section_id" class="invalid-feedback">{{ errors.section_id[0] }}</span>
                                        <div class="field-placeholder">Sections *</div>
                                    </div>
                                </div>

                                <div class="col-sm-4">
                                    <div class="field-wrapper">

                                         <search 
                                            :class="{ 'is-invalid': errors.equipment_group_id }" 
                                            :customClass="{ 'is-invalid': errors.equipment_group_id }" 
                                            :initialize="master.equipment_group_id" 
                                            id="equipment_group_id"
                                            label="equipment_group_name"
                                            placeholder="Select Equipment Group"
                                            :data="equipmentGroup"
                                            @input="equipment_group => master.equipment_group_id = equipment_group">
                                        </search>
                                    <span v-if="errors.equipment_group_id" class="invalid-feedback">{{ errors.equipment_group_id[0] }}</span>
                                        <div class="field-placeholder">Equipment Group *</div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <search 
                                            :class="{ 'is-invalid': errors.equipment_type_id }" 
                                            :customClass="{ 'is-invalid': errors.equipment_type_id }" 
                                            :initialize="master.equipment_type_id" 
                                            id="equipment_type_id"
                                            label="equipment_type_name"
                                            placeholder="Select Equipment Type"
                                            :data="equipmentType"
                                            @input="equipment_type => master.equipment_type_id = equipment_type">
                                        </search>
                                        <span v-if="errors.equipment_type_id" class="invalid-feedback">{{ errors.equipment_type_id[0] }}</span>
                                        <div class="field-placeholder">Equipment Type *</div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <search 
                                            :class="{ 'is-invalid': errors.component_type_id }" 
                                            :customClass="{ 'is-invalid': errors.component_type_id }" 
                                            :initialize="master.component_type_id" 
                                            id="component_type_id"
                                            label="component_type_name"
                                            placeholder="Select Component Type"
                                            :data="componentTypes"
                                            @input="component_types => master.component_type_id = component_types">
                                        </search>
                                    <span v-if="errors.component_type_id" class="invalid-feedback">{{ errors.component_type_id[0] }}</span>
                                        <div class="field-placeholder">Component_Type *</div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <search 
                                            :class="{ 'is-invalid': errors.check_type_id }" 
                                            :customClass="{ 'is-invalid': errors.check_type_id }" 
                                            :initialize="master.check_type_id" 
                                            id="check_type_id"
                                            label="check_type_name"
                                            placeholder="Select Check Type"
                                            :data="check_Type"
                                            @input="check_type => master.check_type_id = check_type">
                                        </search>
                                    <span v-if="errors.check_type_id" class="invalid-feedback">{{ errors.check_type_id[0] }}</span>
                                        <div class="field-placeholder">Check_Type *</div>
                                    </div>
                                </div>
                            </div>

                            <!--select box2 end  -->
                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <!-- <router-link to="/components" class="btn btn-danger">Discard</router-link> -->
                                    <button type="button"  class="btn btn-danger" @click="reset" >Discard</button>
                                    <button type="button"  v-can="'masters.create'" class="btn btn-primary ms-1" v-if="status" @click="addMaster">Submit</button>
                                    <button type="button" class="btn btn-primary ms-1" v-else @click="updateMaster">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Card end -->
                </div>
                <!-- table start -->

                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="card">
                        <div class="card-body">

                        <div class="col-sm-12">
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                            </div>
                        </div>
                            <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('plant_name')">
                                                Plant
                                            <span>
                                                    <i v-if="meta.field=='plant_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>

                                            <th @click="sort('section_name')">
                                                Sections
                                            <span>
                                                    <i v-if="meta.field=='section_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='section_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th @click="sort('equipment_group_name')">
                                                Equipment_Group
                                             <span>
                                                    <i v-if="meta.field=='equipment_group_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='equipment_group_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('equipment_type_name')">
                                                Equipment_Type
                                            <span>
                                                    <i v-if="meta.field=='equipment_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='equipment_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>        
                                            </th>
                                            <th @click="sort('component_type_name')">
                                                Component_Type
                                            <span>
                                                    <i v-if="meta.field=='component_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='component_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('check_type_name')">
                                                Check_Type
                                             <span>
                                                    <i v-if="meta.field=='check_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='check_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>     
                                            </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="masters.length==0">
                                            <td  colspan="8" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(masters,key) in masters" :key="masters.master_id">
                                            <td>{{meta.from+key}}</td>
                                            <td>{{masters.plant.plant_name}}</td>

                                            <td>{{masters.section.section_name}}</td>
                                            <td>{{masters.equipment_group.equipment_group_name}}</td>
                                            <td>{{masters.equipment_type.equipment_type_name}}</td>
                                            <td>{{masters.component_type.component_type_name}}</td>
                                            <td v-if="masters.check_type">{{masters.check_type.check_type_name}}</td>
                                            <td v-else></td>
                                            <td class="td-actions">
                                                <a href="#" class="icon blue"  v-can="'masters.update'" @click.prevent="edit(masters)">
                                                    <i class="icon-pencil"></i>
                                                </a>
                                                <a href="#" class="icon red"  v-can="'masters.delete'" @click.prevent="deletemaster(masters.master_id)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="form-actions-footer">
                                    <div class="text-end row">
                                        <div class="col-sm-2">
                                            <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                                <option>10</option>
                                                <option>15</option>
                                                <option>20</option>
                                                <option>25</option>
                                                <option>30</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-10">
                                            <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- table end -->
                </div>
                <!-- Row end -->
            </div>
        </div>
    </div>
    <!-- Content wrapper scroll end -->
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    let Search = require('@/components/Search.vue').default;
    export default {
        components: { Pagination,Search},
        data() {
            return {
                master: {
                    master_id: "",
                    plant_id: "",
                    plnat_name:"",
                    functional_id: "",
                    department_id: "",
                    section_id: "",
                    equipment_group_id: "",
                    equipment_type_id: "",
                    component_type_id: "",
                    check_type_id: "",
                },
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "master_id",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
                plants: [],
                masters: [],
                functionss: [],
                departments: [],
                sections: [],
                equipmentGroup: [],
                equipmentType: [],
                componentTypes: [],
                check_Type: [],
                errors: [],
                status: true,
            };
        },
        mounted() {
            let vm = this;
            vm.getPlats();          
        },
        methods: {
            index() {
                let vm = this;
                //  let uri = "functionals?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=master_id &per_page=" + vm.meta.per_page;
                let uri = "masters?page=" + vm.meta.currentPage + "&search=" + vm.meta.search + "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + "&per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: uri })
                    .then(function (response) {
                        response.data.data;
                        vm.masters = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            addMaster() {
                let vm = this;
                let loader = vm.$loading.show();
                //    console.log(vm.unit)
                vm.$store
                    .dispatch("post", { uri: "addMaster", data: vm.master })
                    .then(function (response) {
                       // alert(response.data);
                        loader.hide();
                        if(response.data.created==false)
                            vm.$store.dispatch("error", "Master entry alredy exist.");
                        else
                             vm.$store.dispatch("success", "Master Added successfully");
                            
                        vm.index();
                        //vm.reset();
                        vm.errors = [];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            updateMaster() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateMaster/" + vm.master.master_id, data: vm.master };
                vm.$store
                    .dispatch("patch", uri)
                    .then(function (response) {
                        loader.hide();
                         if(response.data=="updated")
                         {
                            vm.$store.dispatch("success", "Masters  is successfully updated");
                         }
                         else
                         {
                            vm.$store.dispatch("error", "Cannot update, as same erntry is alredy exist.");      
                         }
                        
                        vm.index();
                        vm.reset();
                        vm.errors = [];
                        vm.status = true;
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            edit(masters) {
                let vm = this;
                (vm.master = masters), (vm.status = false);
            },

            deletemaster(masters) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteMaster/" + masters };
                    vm.$store
                        .dispatch("delete", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Master  is successfully deleted");
                            vm.index();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            getPlats() {
                let vm = this;
                // let uri = "getPlants?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=plant_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getPlants" })
                    .then(function (response) {
                        response.data.data;
                        vm.plants = response.data.data;
                         vm.master.plant_id = vm.$store.getters.user.plant_id
                        vm.getFunctions();
                        // vm.meta.totalRows = response.data.total;
                        // vm.meta.lastPage = response.data.last_page;
                        // vm.meta.from = response.data.from;
                        // vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                         
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getFunctions() {
                let vm = this;
                // let uri = "functionals?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=functional_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getFunctionals" })
                    .then(function (response) {
                        response.data.data;
                        vm.functionss = response.data.data;
                        vm.getDepartment();
                        // vm.meta.totalRows = response.data.total;
                        // vm.meta.lastPage = response.data.last_page;
                        // vm.meta.from = response.data.from;
                        // vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getDepartment() {
                let vm = this;
                // let uri = "departments?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=department_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getDepartments" })
                    .then(function (response) {
                        response.data.data;
                        vm.departments = response.data.data;
                        vm.getSection();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getSection() {
                let vm = this;
                // let uri = "sections?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=section_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getSections" })
                    .then(function (response) {
                        response.data.data;
                        vm.sections = response.data.data;
                        vm.getequipmentGroup();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getequipmentGroup() {
                let vm = this;
                // let uri = "equipmentGroups?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=equipment_group_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getEquipmentGroups" })
                    .then(function (response) {
                        response.data.data;
                        vm.equipmentGroup = response.data.data;
                        vm.meta.totalRows = response.data.total;
                        vm.meta.lastPage = response.data.last_page;
                        vm.meta.from = response.data.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                        vm.getequipmentType();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getequipmentType() {
                let vm = this;
                // let uri = "equipmentTypes?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=equipment_type_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getEquipmentTypes" })
                    .then(function (response) {
                        response.data.data;
                        vm.equipmentType = response.data.data;
                        vm.getcomponentTypes();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getcomponentTypes() {
                let vm = this;
                // let uri = "componentTypes?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //  "&order_by=" + vm.meta.order_by + "&field=component_type_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getComponentTypes" })
                    .then(function (response) {
                        response.data.data;
                        vm.componentTypes = response.data.data;
                        vm.getCheck_Type();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getCheck_Type() {
                let vm = this;
                // let uri = "checkTypes?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                //      "&order_by=" + vm.meta.order_by + "&field=check_type_name &per_page=" + vm.meta.per_page;
                vm.$store
                    .dispatch("post", { uri: "getCheckTypes" })
                    .then(function (response) {
                        response.data.data;
                        vm.check_Type = response.data.data;
                        vm.index();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            reset() {
                let vm = this;
                vm.master.master_id = "";
                vm.master.functional_id = "";
                vm.master.department_id = "";
                vm.master.section_id = "";
                vm.master.equipment_group_id = "";
                vm.master.equipment_type_id = "";
                vm.master.component_type_id = "";
                vm.master.check_type_id = "";
                vm.status = true;
                vm.errors =[];
            },
            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },
            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
            search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			}  
        },
    };
</script>
<style></style>
