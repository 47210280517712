<template>
    <!-- Content wrapper scroll start -->
    <div class="content-wrapper-scroll">
        <!-- Content wrapper start -->
        <div class="content-wrapper">
            <!-- bar graph starts -->
            <div class="row gutters">
                <!---- uper portion start -->
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <!-- Card start -->
                    <div class="card">
                        <div class="card-body">
                            <div class="row gutters mb-3">
                                <div class="col-sm-10">
                                    <h4><label> Deviated Components </label></h4>
                                </div>                                
                            </div>
                            <div class="row gutters">
                                <div class="col-sm-2 mr-2">
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.department_id }"
                                            :customClass="{ 'is-invalid': errors.department_id }"
                                            :initialize="meta.department_id"
                                            id="department_id"
                                            label="department_name"
                                            placeholder="Select Department"
                                            :data="departments"
                                            @input="departments => meta.department_id = departments"
                                        >
                                        </search>
                                        <span v-if="errors.department_id" class="invalid-feedback">{{ errors.department_id[0] }}</span>
                                        <div class="field-placeholder">Department</div>
                                    </div>
                                </div>
                                <div class="col-sm-2 mr-2">
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.section_id }"
                                            :customClass="{ 'is-invalid': errors.section_id }"
                                            :initialize="meta.section_id"
                                            id="section_id"
                                            label="section_name"
                                            placeholder="Select Equipment Type"
                                            :data="sections"
                                            @input="sections => meta.section_id = sections"
                                        >
                                        </search>
                                        <span v-if="errors.section_id" class="invalid-feedback">{{ errors.section_id[0] }}</span>
                                        <div class="field-placeholder">Section</div>
                                    </div>
                                </div>

                                <div class="col-sm-2">
                                    <button type="submit" v-if="status" @click="index" class="btn btn-primary btn-block">Search</button>
                                </div>
                                 <div class="col-sm-2">
                                    <!-- <button type="submit" v-if="status" @click="index" class="btn btn-primary btn-block">PDF</button> -->
                                    <a  :href="$store.getters.pdfUrl+'getDeviatedComponentPDF?plant_id='+meta.plant_id+'&section_id='+meta.section_id+'&department_id='+meta.department_id" target="_blank" class="btn btn-primary btn-block" style="margin-left: 5px;">PDF</a> 
                                </div>
                                 <div class="col-sm-2">
                                    <!-- <button type="submit" v-if="status" @click="index" class="btn btn-primary btn-block">Excel</button>-->
                                       <a  :href="$store.getters.pdfUrl+'getDeviatedComponentExcel?plant_id='+meta.plant_id+'&section_id='+meta.section_id+'&department_id='+meta.department_id" target="_blank" class="btn btn-primary btn-block" style="margin-left: 5px;">Excel</a> 
                                </div>
                               
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('equipment_group_name')">
                                                Equipment Group
                                                <span>
                                                    <i v-if="meta.field=='equipment_group_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='equipment_group_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('equipment_type_name')">
                                                Equipment Type
                                            <span>
                                                    <i v-if="meta.field=='equipment_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='equipment_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('component_type_name')">
                                                Component Type
                                            <span>
                                                    <i v-if="meta.field=='component_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='component_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('check_type_name')" >
                                                Check Type
                                            <span>
                                                    <i v-if="meta.field=='check_type_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='check_type_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('default_value')">
                                                Default
                                            <span>
                                                    <i v-if="meta.field=='default_value' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='default_value' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('auto_fill')">
                                                Auto Fill
                                            <span>
                                                    <i v-if="meta.field=='auto_fill' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='auto_fill' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>     
                                            </th>
                                            <th @click="sort('ucl')">
                                                Field Type
                                                   
                                            </th>
                                            <th @click="sort('ucl')">
                                                UCL
                                             <span>
                                                    <i v-if="meta.field=='ucl' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='ucl' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>       
                                            </th>
                                            <th @click="sort('ucl')">
                                                LCL
                                            <span>
                                                    <i v-if="meta.field=='lcl' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='lcl' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>  
                                            </th>
                                            <th @click="sort('value')">
                                                Value
                                            <span>
                                                    <i v-if="meta.field=='value' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='value' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="user_equipment_components.length==0">
                                            <td  colspan="11" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(user_equipment_components,key) in user_equipment_components" :key="user_equipment_components.user_equipment_component_id">
                                            <td>{{meta.from+key}}</td>
                                            <td>{{user_equipment_components.equipment_group.equipment_group_name}}</td>
                                            <td>{{user_equipment_components.equipment_type.equipment_type_name}}</td>
                                            <td>{{user_equipment_components.component_type.component_type_name}}</td>
                                            <td>{{user_equipment_components.check_type.check_type_name}}</td>
                                            <td>{{user_equipment_components.default_value}}</td>
                                            <td v-if="user_equipment_components.auto_fill === 1">Yes</td>
                                            <td v-else>No</td>
                                            <td>{{user_equipment_components.check_type.field_type}}</td>
                                            <td>{{user_equipment_components.ucl}}</td>
                                            <td>{{user_equipment_components.lcl}}</td>
                                            <td>{{user_equipment_components.value}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="form-actions-footer">
                                    <div class="text-end row">
                                        <div class="col-sm-2">
                                            <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                                <option>10</option>
                                                <option>15</option>
                                                <option>20</option>
                                                <option>25</option>
                                                <option>30</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-10">
                                            <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.page" @pagechanged="onPageChange" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                                    
                    
                    <!-- table end -->
                </div>
                <!-- Row end -->
               
            </div>
            <!-- bar graph ends -->

        </div>
    </div>
    <!-- Content wrapper scroll end -->
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
   
    let Search = require("@/components/Search.vue").default;
    export default {
        components: { Pagination, Search },
        data() {
            return {               
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "user_equipment_component_id",                   
                    per_page: 10,
                    totalRows: 0,
                    page: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    plant_id:"",
 
                    equipment_id: "10",
                    equipment_component_id: "",
                    department_id: "",
                    section_id: "",
                    component_type_id: "",
                    check_type_id: "",
                    from_date: "",
                    to_date: "",
                },
                departments: [],
                user_equipment_components: [],                
                //user_templates_summary: [],
                sections: [],
               // component_type: [],
               // equipmentGroup: [],
               // equipmentType: [],
                //componentTypes: [],
               // check_Type: [],
                errors: [],
                status: true,
            };
        },
        mounted() {
            let vm = this;
            vm.getDepartment();
            vm.getSections();
           // vm.getcomponentTypes();
           // vm.getCheck_Type();
            vm.index();
        },
        methods: {
            index() {
                //alert('index from parent')
                let vm = this;
                vm.meta.plant_id=vm.$store.getters.user.plant_id
                vm.$store
                    .dispatch("post", { uri: "getDeviatedComponent", data: vm.meta })
                    .then(function (response) {
                        response.data.data;
                        vm.user_equipment_components = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                        vm.$refs.child.index()
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            
            getDepartment() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getDepartments" })
                    .then(function (response) {
                        response.data.data;
                        vm.departments = response.data.data;
                        // vm.meta.totalRows = response.data.total;
                        // vm.meta.lastPage = response.data.last_page;
                        // vm.meta.from = response.data.from;
                        // vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getSections() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getSections" })
                    .then(function (response) {
                        response.data.data;
                        vm.sections = response.data.data;
                        //console.log(vm.equipmentType);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

           /* getcomponentTypes() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getComponentTypes" })
                    .then(function (response) {
                        response.data.data;
                        vm.componentTypes = response.data.data;

                        console.log(vm.componentTypes);
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getCheck_Type() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getCheckTypes" })
                    .then(function (response) {
                        response.data.data;
                        vm.check_Type = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },*/
            reset() {
                let vm = this;
                vm.master.master_id = "";
                vm.master.plant_id = "";
                vm.master.functional_id = "";
                vm.master.department_id = "";
                vm.master.section_id = "";
               // vm.master.department_id = "";
               // vm.master.section_id = "";
                vm.master.component_type_id = "";
                vm.master.check_type_id = "";
                vm.status = true;
            },
            onPageChange(page) {
                this.meta.page = page;
                this.index();
            },
            onPerPageChange() {
                this.meta.page = 1;
                this.index();
            },
            sort(field) {
                this.meta = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
        },
    };
</script>

