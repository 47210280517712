<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Motors</h5>
                        </div>
                        <div class="card-body">

                            <div class="row gutters">
                                <div class="col-sm-4 mr-2">
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.section_id }"
                                            :customClass="{ 'is-invalid': errors.section_id }"
                                            :initialize="meta.section_id"
                                            id="section_id"
                                            label="section_name"
                                            placeholder="Select Section"
                                            :data="sections"
                                            @input="sections => meta.section_id = sections"
                                        >
                                        </search>
                                        <span v-if="errors.section_id" class="invalid-feedback">{{ errors.section_id[0] }}</span>
                                        <div class="field-placeholder">Section</div>
                                    </div>
                                </div>
                                <div class="col-sm-4">
									<div class="input-group field-wrapper">
                                        <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                        <div class="field-placeholder">Search</div>
                                    </div>
                                </div>
                                <div class="col-sm-4"  style="justify-content: space-between;">
                                    <div class="input-group field-wrapper">
                                        <button type="submit" v-if="status" @click="search" class="btn  btn-primary btn-block">Search</button>
                                        
                                        <button type="submit" v-if="status" @click="reset" class="btn btn-danger btn-block">Reset</button>
                                        
                                        <a class="btn btn-primary" :href="$store.state.pdfUrl+'plantWiseBarcodeUID?plant_id='+$store.getters.user.plant_id" target="_blank" role="button">Download</a>
                                    </div>   
                                </div>
                            </div>
                            <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Plant</th>
                                            <th @click="sort('section_name')">
                                                Section
                                                <span>
                                                    <i v-if="meta.field=='section_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='section_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('component_type')">
                                                Component Type
                                                <span>
                                                    <i v-if="meta.field=='component_type' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='component_type' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('serial_number')">
                                                Serial number
                                                <span>
                                                    <i v-if="meta.field=='' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('component_name')">
                                                Component Name
                                                <span>
                                                    <i v-if="meta.field=='component_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='component_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('make')">
                                                Make
                                                <span>
                                                    <i v-if="meta.field=='make' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='make' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('condition')">
                                                Condition
                                                <span>
                                                    <i v-if="meta.field=='condition' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='condition' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('power_rating')">
                                                Power Rating
                                                <span>
                                                    <i v-if="meta.field=='power_rating' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='power_rating' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="components.length==0">
                                            <td colspan="10" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(component,key) in components" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td v-if="component.plant">
                                                {{ component.plant.plant_name }}
                                            </td>
                                            <td v-else>-</td>
                                            <td v-if="component.section">
                                                {{ component.section.section_name }}
                                            </td>
                                            <td v-else>-</td>
                                            <td>{{ component.component_type }}</td>
                                            <td>{{ component.serial_number }}</td>
                                            <td>{{ component.component_name }}</td>
                                            <td>{{ component.make }}</td>
                                            <td>{{ component.condition }}</td>
                                            <td>{{ component.power_rating }}</td>

                                            <td class="td-actions">
                                                <a v-can="'components.barcode'" :href="$store.getters.pdfUrl+'barcode?component_id='+component.component_id" target="_blank" class="icon blue">
                                                    <i class="icon-file"></i>
                                                </a>
                                                <router-link v-can="'components.view'" :to="'/components/'+component.component_id+'/view'" class="icon green">
                                                    <i class="icon-eye"></i>
                                                </router-link>
                                                <router-link v-can="'components.copy'" :to="'/components/'+component.component_id+'/copy'" class="icon red">
                                                    <i class="icon-copy"></i>
                                                </router-link>
                                                <router-link v-can="'components.update'" :to="'/components/'+component.component_id+'/edit'" class="icon blue">
                                                    <i class="icon-pencil"></i>
                                                </router-link>
                                                <a href="#" v-can="'components.delete'" class="icon red" @click.prevent="deleteRow(component)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    let Search = require("@/components/Search.vue").default;
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination, Search },
        data() {
            return {
                status: true,
                errors: [],
                components: [],
                component_types: [],
                sections: [],
                meta: {
                    component_type_id: "",
                    section_id: "",
                    search: "",
                    order_by: "asc",
                    field: "component_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    plant_id: this.$store.getters.user.plant_id,
                },
            };
        },
        mounted() {
            this.getSections();
            this.index();
        },
        methods: {
            index() {
                let vm = this;
                let uri = "paginateComponents?page=" + vm.meta.currentPage + "&search=" + vm.meta.search + "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + "&per_page=" + vm.meta.per_page + "&plant_id=" + vm.meta.plant_id + "&section_id="+ vm.meta.section_id + "&component_type_id="+ vm.meta.component_type_id ;
                vm.$store
                    .dispatch("post", { uri: uri })
                    .then(function (response) {
                        vm.components = response.data.data;
                        console.log(vm.components);
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
               reset()
            {
                let vm = this;
                vm.meta.section_id="";
                vm.meta.component_type_id="";              
                vm.meta.search="";
                vm.index();
            },
           
            getSections() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getSections" })
                    .then(function (response) {
                        vm.sections = response.data.data;
                        vm.getComponentTypes();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
           
            getComponentTypes() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getComponentTypes", data: {} })
                    .then(function (response) {
                        vm.component_types = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deleteRow(component) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    vm.$store
                        .dispatch("delete", { uri: "deleteComponent/" + component.component_id })
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Motor is successfully deleted");
                            vm.index();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            search() {
                let vm = this;
                vm.meta.currentPage = 1;
                vm.index();
            },
        },
    };
</script>
