<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row gutters">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 v-if="status">New Equipment</h5>
                            <h5 v-else>Update Equipment</h5>
                        </div>
                        <div class="card-body">
                            <div class="row gutters">
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Equipment Name *</div>
                                        <input class="form-control" v-model="equipment.equipment_name" :class="{'is-invalid': errors.equipment_name}" ref="equipment_name" type="text" placeholder="Enter Equipment Name" />
                                        <span v-if="errors.equipment_name" class="invalid-feedback">{{ errors.equipment_name[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Plant *</div>
                                          <search 
                                            :class="{ 'is-invalid': errors.plant_id }" 
                                            :customClass="{ 'is-invalid': errors.plant_id }" 
                                            :initialize="equipment.plant_id" 
                                            id="plant_id"
                                            label="plant_name"
                                            placeholder="Select Plant"
                                            :data="plants"
                                            @input="selectPlant($event)"
                                             disabled="disabled">
                                        </search>  
                                    <span v-if="errors.plant_id" class="invalid-feedback">{{ errors.plant_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Section *</div>
                                        <search
                                            :class="{ 'is-invalid': errors.section_id }" 
                                            :customClass="{ 'is-invalid': errors.section_id }" 
                                            :initialize="equipment.section_id" 
                                            id="section_id"
                                            label="section_name"
                                            placeholder="Select Sections"
                                            :data="sections"
                                            @input="selectSection($event)">
                                        </search>  
                                      <span v-if="errors.section_id" class="invalid-feedback">{{ errors.section_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-6">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">UID *</div>
                                        <input class="form-control" v-model="equipment.uid" :class="{'is-invalid': errors.uid}" ref="uid" type="text" placeholder="Enter UID" />
                                        <span v-if="errors.uid" class="invalid-feedback">{{ errors.uid[0] }}</span>
                                    </div>
                                </div>

                                <div class="col-sm-6">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Equipment UID *</div>
                                        <input class="form-control" v-model="equipment.equipment_uid" :class="{'is-invalid': errors.equipment_uid}" ref="equipment_uid" type="text" placeholder="Enter Equipment UID" readonly />
                                        <span v-if="errors.equipment_uid" class="invalid-feedback">{{ errors.equipment_uid[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Equipment Group *</div>
                                            <search
                                                      
                                                        :class="{ 'is-invalid': errors.equipment_group_id }" 
                                                        :customClass="{ 'is-invalid': errors.equipment_group_id }" 
                                                        :initialize="equipment_component.equipment_group_id" 
                                                        id="equipment_group_id"
                                                        label="equipment_group_name"
                                                        placeholder="Select Equipment Group"
                                                        :data="equipment_groups"
                                                        @input="selectEquipment_group($event)">
                                                    </search>    
                                        <span v-if="errors.equipment_group_id" class="invalid-feedback">{{ errors.equipment_group_id[0] }}</span>
                                    </div>
                                </div>

                                <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Equipment Type *</div>
                                           <search
                                                      
                                                        :class="{ 'is-invalid': errors.equipment_type_id }" 
                                                        :customClass="{ 'is-invalid': errors.equipment_type_id }" 
                                                        :initialize="equipment_component.equipment_type_id" 
                                                        id="equipment_type_id"
                                                        label="equipment_type_name"
                                                        placeholder="Select Equipment Group"
                                                        :data="equipment_types"
                                                        @input="selectEquipment_type($event)">
                                                    </search> 

                                                   <span v-if="errors.equipment_type_id" class="invalid-feedback">{{ errors.equipment_type_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Component Type *</div>
                                           <search
                                                        :class="{ 'is-invalid': errors.component_type_id }" 
                                                        :customClass="{ 'is-invalid': errors.component_type_id }" 
                                                        :initialize="equipment_component.component_type_id" 
                                                        id="component_type_id"
                                                        label="component_type_name"
                                                        placeholder="Select Component Type"
                                                        :data="component_types"
                                                        @input="selectComponent_type($event)">
                                                    </search>
                                                      
                                                <span v-if="errors.component_type_id" class="invalid-feedback">{{ errors.component_type_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="field-wrapper">
                                        <div class="field-placeholder">Check Type *</div>
                                           <search
                                                        @change="changeCheckType" 
                                                        :class="{ 'is-invalid': errors.check_type_id }" 
                                                        :customClass="{ 'is-invalid': errors.check_type_id }" 
                                                        :initialize="equipment_component.check_type_id" 
                                                        id="check_type_id"
                                                        label="check_type_name"
                                                        placeholder="Select Check Type"
                                                        :data="check_types"
                                                        @input="selectCheck_type($event)">
                                                    </search>  
                                                      
                                               <span v-if="errors.check_type_id" class="invalid-feedback">{{ errors.check_type_id[0] }}</span>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <button v-if="equipment_component.status" class="btn btn-primary" style="margin:7px" @click="addRow">
                                                            <i class="fa fa-plus"></i>
                                                        </button>
                                                        <button v-else class="btn btn-success" @click="updateRow">
                                                            <i class="icon-pencil"></i>
                                                        </button>
                                </div>
                                <div class="col-sm-12">
                                    <div class="table-responsive1">
                                        <table class="table table-sm table-bordered">
                                            <thead>
                                                <tr style="margin-top:5px">
                                                    <th>Equipment Group</th>
                                                    <th>Equipment Type </th>
                                                    <th>Component Type </th>
                                                    <th>Check Type</th>
                                                    <th class="text-center">Action</th>
                                                    <!-- <th class="field-wrapper">
                                                        <div class="field-placeholder">Component *</div>
                                                        <select class="form-control" :class="{'is-invalid': errors.component_id}" v-model="equipment_component.component_id" @change="getMasterCheckTypes">
                                                            <option value="">Select Component</option>
                                                            <option v-for="component,key in components" :key="key" :value="component.component_id">{{  component.component_uid }} - {{ component.component_name }}</option>
                                                        </select>
                                                        <span v-if="errors.component_id" class="invalid-feedback">{{ errors.component_id[0] }}</span>
                                                    </th> -->
                                                   
                                                 </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(equipment_component,key) in equipment.equipment_components" :key="key">
                                                    <td>{{ equipment_component.equipment_group.equipment_group_name }}</td>
                                                    <td>{{ equipment_component.equipment_type.equipment_type_name }}</td>
                                                    <td>{{ equipment_component.component_type.component_type_name }}</td>
                                                    <!-- <td v-if="equipment_component.component">{{ equipment_component.component.component_uid }} - {{ equipment_component.component.component_name }}</td>
                                                    <td v-else></td> -->
                                                    <td>{{ equipment_component.check_type.check_type_name }}</td>
                                                    <td class="td-actions">
                                                        <a href="#" class="icon green" @click.prevent="editRow(equipment_component,key)">
                                                            <i class="icon-pencil"></i>
                                                        </a>
                                                        <a href="#" class="icon red" @click.prevent="deleteRow(equipment_component,key)">
                                                            <i class="icon-cancel"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end">
                                    <router-link to="/equipments" class="btn btn-danger">Discard</router-link>
                                    <button type="button" class="btn btn-primary ms-1" v-if="status" @click="addEquipment">Submit</button>
                                    <button type="button" class="btn btn-primary ms-1" v-else @click="updateEquipment">Update</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
let Search = require('@/components/Search.vue').default;
    export default {
        components: {Search},
        data() {
            return {
                equipment: {
                    equipment_id: "",
                    equipment_name: "",
                    equipment_uid: "",
                    uid: "",
                    plant_id: "",
                    section_id: "",
                    equipment_components: [],
                    deleted_equipment_components: [],
                },
                equipment_component: {
                    equipment_id: "",
                    equipment_component_id: "",
                    equipment_group_id: "",
                    equipment_group: {},
                    equipment_type_id: "",
                    equipment_type: {},
                    component_type_id: "",
                    component_type: {},
                    component_id: "",
                    component: {},
                    check_type_id: "",
                    check_type: {},
                    status: true,
                    key: "",
                },
                plants: [],
                sections: [],
                equipment_groups: [],
                equipment_types: [],
                component_types: [],
                check_types: [],
                errors: [],
                status: true,
            };
        },

        mounted() {
            let vm = this;
            vm.getPlats();
            
        },

        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (to.name == "Equipment.Create") {
                    vm.status = true;
                    vm.equipment.equipment_id = "";
                    vm.equipment.equipment_name = "";
                    vm.equipment.section_id = "";
                    vm.equipment.uid = "";
                    vm.equipment.equipment_uid = "";
                    vm.equipment.equipment_components = [];
                    vm.equipment.deleted_equipment_components = [];
                }
                else if (to.name == "Equipment.Copy") {
                    vm.status = true;
                    let uri = { uri: "showEquipment/" + vm.$route.params.equipment_id };
                    vm.$store.dispatch("get", uri)
                    .then(function (response) {
                        let equipment = response.data.data;
                        vm.equipment.equipment_components = equipment.equipment_components;
                        vm.equipment.deleted_equipment_components = [];
                       /* vm.getSections();
                        vm.getequipmentGroup();
                        vm.getequipmentType();
                        vm.getcomponentTypes();
                        // vm.getComponent();
                        vm.getCheck_Type();
                        vm.equipment_groups();
                        vm.getcomponentdata();*/
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                } else {
                    vm.status = false;
                    let uri = { uri: "showEquipment/" + vm.$route.params.equipment_id };
                    vm.$store.dispatch("get", uri)
                    .then(function (response) {
                        vm.equipment = response.data.data;
                        vm.equipment.deleted_equipment_components = [];
                       /* vm.getSections();
                        vm.getequipmentGroup();
                        vm.getequipmentType();
                        vm.getcomponentTypes();
                        // vm.getComponent();
                        vm.getCheck_Type();
                        vm.equipment_groups();
                        vm.getcomponentdata();*/
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            });
        },

        methods: {
            getPlats() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getPlants" })
                    .then(function (response) {
                        vm.plants = response.data.data;
                        vm.equipment.plant_id = vm.$store.getters.user.plant_id
                        vm.getSections();
                       
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            getSections() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getSections" })
                    .then(function (response) {
                        vm.sections = response.data.data;
                        vm.getMasterEquipmentGroups();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getcomponentdata() {
                let vm = this;
                if (vm.status == true) {
                    vm.getEquipmentUID();
                } else {
                    vm.getMasterEquipmentGroups();

                    // vm.getequipmentGroup();
                    vm.getSections();
                    vm.getPlats();
                }
            },
            getEquipmentUID() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getEquipmentUID", data: vm.equipment })
                    .then(function (response) {
                        vm.equipment.equipment_uid = response.data;
                        vm.getMasterEquipmentGroups();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getMasterEquipmentGroups() {
                let vm = this;

                vm.$store
                    .dispatch("post", { uri: "getMasterEquipmentGroups", data: vm.equipment })
                    .then(function (response) {
                        vm.equipment_groups = response.data.data;
                        vm.getMasterEquipmentTypes();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getMasterEquipmentTypes() {
                let vm = this;
                let equipment_group = vm.equipment_groups.filter(function (el) {
                    return el.equipment_group_id == vm.equipment_component.equipment_group_id;
                });
                vm.equipment_component.equipment_group = equipment_group[0];
                let data = {
                    plant_id: vm.equipment.plant_id,
                    section_id: vm.equipment.section_id,
                    equipment_group_id: vm.equipment_component.equipment_group_id,
                };
                vm.$store
                    .dispatch("post", { uri: "getMasterEquipmentTypes", data: data })
                    .then(function (response) {
                        vm.equipment_types = response.data.data;
                        vm.getMasterComponentTypes();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getMasterComponentTypes() {
                let vm = this;
                let equipment_type = vm.equipment_types.filter(function (el) {
                    return el.equipment_type_id == vm.equipment_component.equipment_type_id;
                });
                vm.equipment_component.equipment_type = equipment_type[0];
                let data = {
                    plant_id: vm.equipment.plant_id,
                    section_id: vm.equipment.section_id,
                    equipment_group_id: vm.equipment_component.equipment_group_id,
                    equipment_type_id: vm.equipment_component.equipment_type_id,
                };
                vm.$store
                    .dispatch("post", { uri: "getMasterComponentTypes", data: data })
                    .then(function (response) {
                        vm.component_types = response.data.data;
                         vm.getMasterCheckTypes();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            getMasterCheckTypes() {
                let vm = this;
                let data = {
                    plant_id: vm.equipment.plant_id,
                    section_id: vm.equipment.section_id,
                    equipment_group_id: vm.equipment_component.equipment_group_id,
                    equipment_type_id: vm.equipment_component.equipment_type_id,
                    component_type_id: vm.equipment_component.component_type_id,
                };
                vm.$store.dispatch("post", { uri: "getMasterCheckTypes", data: data })
                    .then(function (response) {
                        vm.check_types = response.data.data;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            changeCheckType() {
                let vm = this;
                let check_type = vm.check_types.filter(function (el) {
                    return el.check_type_id == vm.equipment_component.check_type_id;
                });
                vm.equipment_component.check_type = check_type[0];
            },

            addRow() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "validationOfEquipmentComponent", data: vm.equipment_component })
                    .then(function (response) {
                        vm.equipment.equipment_components.push(response.data);
                       vm.discard();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            editRow(equipment_component, key) {
                let vm = this;
                vm.equipment_component.equipment_id = equipment_component.equipment_id;
                vm.equipment_component.equipment_component_id = equipment_component.equipment_component_id;
                vm.equipment_component.equipment_group_id = equipment_component.equipment_group_id;
                vm.equipment_component.equipment_type_id = equipment_component.equipment_type_id;
                vm.equipment_component.component_type_id = equipment_component.component_type_id;
                vm.equipment_component.component_id = equipment_component.component_id;
                vm.equipment_component.check_type_id = equipment_component.check_type_id;
                vm.equipment_component.key = key;
                vm.equipment_component.status = false;
                //console.log(vm.equipment_component);
               vm.getcomponentdata();
            },

            updateRow() {
                let vm = this;
                vm.changeCheckType()
                vm.$store
                    .dispatch("post", { uri: "validationOfEquipmentComponent", data: vm.equipment_component })
                    .then(function (response) {
                        vm.equipment.equipment_components.splice(response.data.key, 1, response.data), vm.discard();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deleteRow(equipment_component, key) {
                let vm = this;
                vm.equipment.deleted_equipment_components.push(equipment_component);
                vm.equipment.equipment_components.splice(key, 1);
                vm.discard();
            },

            addEquipment() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store
                    .dispatch("post", { uri: "addEquipment", data: vm.equipment })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Equipment is successfully created");
                        vm.$router.push("/equipments");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            updateEquipment() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateEquipment/" + vm.equipment.equipment_id, data: vm.equipment };
                vm.$store
                    .dispatch("patch", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Equipment is successfully updated");
                        vm.$router.push("/equipments");
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            //  submitForm() {
            //     let vm = this;
            //     if (vm.status) {
            //         vm.addTaxes();
            //     } else {
            //         vm.updatetax();
            //     }
            // },
            discard() {
                let vm = this;
                vm.equipment_component.equipment_id = "";
                // vm.equipment_component.equipment_group_id = "";
                // vm.equipment_component.equipment_type_id = "";
                // vm.equipment_component.component_type_id = "";
                vm.equipment_component.check_type_id = "";
                vm.equipment_component.status = true;
                vm.equipment_component.key = "";
                vm.errors = [];
            },

            selectPlant(e) {
                let vm = this;
                vm.equipment.plant_id = e;
                vm.getcomponentdata()
            },

            selectSection(e) {
                let vm = this;
                vm.equipment.section_id = e;
                vm.getcomponentdata()
            },
            selectEquipment_group(e){
                let vm = this;
                vm.equipment_component.equipment_group_id = e;
                vm.getMasterEquipmentTypes()
            },
             selectEquipment_type(e){
                let vm = this;
                vm.equipment_component.equipment_type_id = e;
                vm.getMasterComponentTypes()
            },
            selectComponent_type(e){
                let vm = this;
                vm.equipment_component.component_type_id = e;
                vm.getMasterCheckTypes()
            },
            selectCheck_type(e){
                 let vm = this;
                vm.equipment_component.check_type_id = e;
                vm.changeCheckType()
            }

        },
    };
</script>
<style></style>
