<template>
	<div class="content-wrapper-scroll">
		<div class="content-wrapper">
			<div class="row">
				<div class="col-sm-12">
					<div class="card">
						<div class="card-header">
							<h5>Equipments</h5>
						</div>
						<div class="card-body">
						
							<div class="row gutters">
                                <div class="col-sm-2 mr-2">
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.section_id }"
                                            :customClass="{ 'is-invalid': errors.section_id }"
                                            :initialize="meta.section_id"
                                            id="section_id"
                                            label="section_name"
                                            placeholder="Select Section"
                                            :data="sections"
                                            @input="sections => meta.section_id = sections"
                                        >
                                        </search>
                                        <span v-if="errors.section_id" class="invalid-feedback">{{ errors.section_id[0] }}</span>
                                        <div class="field-placeholder">Section</div>
                                    </div>
                                </div>
                                <div class="col-sm-2 mr-2">
                                    <div class="field-wrapper">
                                       <search 
                                            :class="{ 'is-invalid': errors.equipment_group_id }" 
                                            :customClass="{ 'is-invalid': errors.equipment_group_id }" 
                                            :initialize="meta.equipment_group_id" 
                                            id="equipment_group_id"
                                            label="equipment_group_name"
                                            placeholder="Select Equipment Group"
                                            :data="equipment_groups"
                                             @input="equipment_groups => meta.equipment_group_id = equipment_groups">
                                        </search>
										<span v-if="errors.section_id" class="invalid-feedback">{{ errors.equipment_group_id[0] }}</span>
                                        <div class="field-placeholder">Equipment Group</div>
                                    </div>
                                </div>

                                <div class="col-sm-4 ">
									<div class="input-group field-wrapper">
									<input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
									<span class="input-group-text">
										<i class="fas fa-search"></i>
									</span> 
                                </div>
                                </div>
                                <div class="col-sm-4"  style="justify-content: space-between;">
                                   <button type="submit" v-if="status" @click="index" class="btn btn-sm btn-primary btnn btn-block">Search</button>
                                
                                   <button type="submit" v-if="status" @click="reset" class="btn btn-sm btn-primary btnn btn-block">Reset</button>
                                
                                    <a
										class="btn btn-sm btn-primary btnn"										
										:href="$store.state.pdfUrl+'plantWiseBarcodeUIDEquipment?plant_id='+$store.getters.user.plant_id+'&page='+meta.currentPage+'&per_page='+meta.per_page+'&field='+meta.field+'&order_by='+meta.order_by+'&search='+meta.search+'&section_id='+meta.section_id+'&equipment_group_id='+meta.equipment_group_id"
										target="_blank"
										role="button"
									>
										Download
									</a>
                                </div>
                               
                            </div>
						

							<div class="table-responsive tabletop">
								<table class="table table-bordered table-striped">
									<thead>
										<tr>
											<th>#</th>
											<th @click="sort('equipment_uid')">
												Equipment UID
												<span>
													<i v-if="meta.field=='equipment_uid' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
													<i v-else-if="meta.field=='equipment_uid' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
													<i v-else class="fas fa-sort"></i>
												</span>
											</th>
											<th @click="sort('uid')">
												UID
												<span>
													<i v-if="meta.field=='uid' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
													<i v-else-if="meta.field=='uid' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
													<i v-else class="fas fa-sort"></i>
												</span>
											</th>
											<th @click="sort('equipment_name')">
												Equipment Name
												<span>
													<i v-if="meta.field=='equipment_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
													<i v-else-if="meta.field=='equipment_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
													<i v-else class="fas fa-sort"></i>
												</span>
											</th>
											<th @click="sort('plant_name')">
												Plant
												<span>
													<i v-if="meta.field=='plant_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
													<i v-else-if="meta.field=='plant_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
													<i v-else class="fas fa-sort"></i>
												</span>
											</th>
											<th @click="sort('section_name')">
												Section
												<span>
													<i v-if="meta.field=='section_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
													<i v-else-if="meta.field=='section_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
													<i v-else class="fas fa-sort"></i>
												</span>
											</th>
											<th class="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										<tr v-if="equipments.length==0">
											<td colspan="7" class="text-center">No records found</td>
										</tr>
										<tr v-for="(equipment,key) in equipments" :key="key">
											<td>{{ meta.from+key }}</td>
											<td>{{ equipment.equipment_uid }}</td>
											<td>{{ equipment.uid }}</td>
											<td>{{ equipment.equipment_name }}</td>
											<td>{{ equipment.plant.plant_name }}</td>
											<td>{{ equipment.section.section_name }}</td>
											<td class="td-actions">
												<a v-can="'equipments.barcode'" :href="$store.getters.pdfUrl+'barcodeEquipment?equipment_id='+equipment.equipment_id" target="_blank" class="icon blue">
													<i class="icon-file"></i>
												</a>
												<!-- <router-link v-can="'equipments.view'" :to="'/equipments/'+equipment.equipment_id" class="icon green">
													<i class="icon-eye"></i>
												</router-link> -->
												<router-link v-can="'equipments.copy'" :to="'/equipments/'+equipment.equipment_id+'/copy'" class="icon green">
													<i class="icon-copy"></i>
												</router-link>
												<router-link v-can="'equipments.update'" :to="'/equipments/'+equipment.equipment_id+'/edit'" class="icon blue">
													<i class="icon-pencil"></i>
												</router-link>
												<a href="#" v-can="'equipments.delete'" class="icon red" @click.prevent="deleteRow(equipment)">
													<i class="icon-cancel"></i>
												</a>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<div class="form-actions-footer">
								<div class="text-end row">
									<div class="col-sm-2">
										<select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
											<option>10</option>
											<option>15</option>
											<option>20</option>
											<option>25</option>
											<option>30</option>
										</select>
									</div>
									<div class="col-sm-10">
										<Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.btnn{
    margin-top: 0.8rem;
}
</style>
<script>
	import Pagination from "@/components/Pagination.vue";
	let Search = require('@/components/Search.vue').default;
	export default {
		components: { Pagination, Search },
		data() {
			return {
				status: true,
				errors: [],
				sections: [],
				equipment_groups: [],
				equipments: [],
				meta: {
					search: "",
					section_id:"",
					equipment_group_id:"",
					order_by: "asc",
					field: "equipment_name",
					per_page: 10,
					totalRows: 0,
					currentPage: 1,
					lastPage: 1,
					from: 1,
					maxPage: 1,
					plant_id: this.$store.getters.user.plant_id,
				},
				plant: "",
			};
		},
		mounted() {
			this.getSections();
			this.index();
		},
		methods: {
			index() {
				let vm = this;
				let uri = "paginateEquipments?page=" + vm.meta.currentPage + "&search=" + vm.meta.search + "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + "&per_page=" + vm.meta.per_page + "&plant_id=" + vm.meta.plant_id + "&section_id=" + vm.meta.section_id + "&equipment_group_id="+vm.meta.equipment_group_id;
				vm.$store.dispatch("post", { uri: uri })
				.then(function (response) {
					vm.equipments = response.data.data;
					vm.meta.totalRows = response.data.meta.total;
					vm.meta.lastPage = response.data.meta.last_page;
					vm.meta.from = response.data.meta.from;
					vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
			},

			reset()
            {
                let vm = this;
                vm.meta.section_id="";
                vm.meta.equipment_group_id="";   
				vm.meta.search="";            
                vm.index();
            },

			getSections() {
				let vm = this;
				vm.$store.dispatch("post", { uri: "getSections" })
				.then(function (response) {
					vm.sections = response.data.data;                
					vm.getEquipments()
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
			},

			getEquipments() {
				let vm = this;
				vm.$store.dispatch("post", { uri: "getMasterEquipmentGroups", data: vm.meta })
				.then(function (response) {
					vm.equipment_groups = response.data.data;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch("error", error.response.data.message);
				});
			},

			selectSection(e) {
				let vm = this;
				vm.meta.section_id = e;
				vm.index()
			},

			selectEquipmentGroup(e){
                let vm = this;
                vm.meta.equipment_group_id = e;
                vm.index()
            },

			deleteRow(equipment) {
				let vm = this;
				if (confirm("Do you want to delete this entry ?")) {
					let loader = vm.$loading.show();
					vm.$store.dispatch("delete", { uri: "deleteEquipment/" + equipment.equipment_id })
					.then(function () {
						loader.hide();
						vm.$store.dispatch("success", "Equipment is successfully deleted");
						vm.index();
					})
					.catch(function (error) {
						loader.hide();
						vm.errors = error.response.data.errors;
						vm.$store.dispatch("error", error.response.data.message);
					});
				}
			},

			onPageChange(page) {
				this.meta.currentPage = page;
				this.index();
			},

			onPerPageChange() {
				this.meta.currentPage = 1;
				this.index();
			},
			sort(field) {
				this.meta.field = field;
				this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
				this.index();
			},
			search() {
				let vm = this;
				vm.meta.currentPage = 1;
				vm.index();
			},
		},
	};
</script>
