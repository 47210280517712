<template>
<div class="login-container">

            <div class="container-fluid h-100">
            
            <!-- Row start -->
            <div class="row no-gutters h-100">
                <div class="col-12">
                    <div class="login-wrapper">
                        <form action="" @submit.prevent="resetPassword">
                            <div class="login-screen">
                                <div class="login-body pb-4">
                                    <a href="" class="login-logo">                                       
                                        <img src="../../assets/ultratech-cement-logo.png" class="center" alt="ultratech-cement">
                                    </a>
                                    <h6>In order to reset your password, please enter 8 character new password.</h6>
                                    <div class="field-wrapper mb-3">
                                        <input type="password" placeholder="Enter New Password" autocomplete="off" class="form-control" :class="{'is-invalid': errors.new_password}" v-model="reset.new_password" ref="new_password" >
                                          <span v-if="errors.new_password" class="invalid-feedback">{{ errors.new_password[0] }}</span>
                                        <div class="field-placeholder">Enter New Password</div>
                                    </div>
                                    <div class="field-wrapper mb-3">
                                        <input type="password" autocomplete="off"  class="form-control" :class="{'is-invalid': errors.confirm_password}" placeholder="Enter Cofirm Password" v-model="reset.confirm_password">
                                        <span v-if="errors.confirm_password" class="invalid-feedback">{{ errors.confirm_password[0] }}</span>
                                        <div class="field-placeholder">Confirm Password</div>
                                    </div>
                                    <div class="actions">
                                        <button type="submit" class="btn btn-danger ms-auto">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <!-- Row end -->

        
            </div>
        </div>
</template>
<script>
export default{
    data(){
        return{
            reset:{
                  
                   token:'',
                   new_password:'',
                   confirm_password:'',
                   email:'',
                          
                },
                 errors:[]
        }
       
    },

        beforeRouteEnter(to, from, next) {             
			next(vm => {
                 if (to.name == "ResetPassword") {
                    vm.reset.token = to.params.token;
                    vm.reset.email = to.params.email;                   
                } 
               
            });
        },

    methods: {

        
         resetPassword() {
                let vm = this;              
                let loader = vm.$loading.show();
                vm.$store.dispatch('auth',{'uri':'resetPassword','data':vm.reset})
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch('success','Password reset Successfully');
                    vm.$router.push('/login');
                })
                .catch(function (error) {
                    loader.hide();
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            }
    },
}
</script>
<style>
</style>