<template>
<div class="login-container">

            <div class="container-fluid h-100">
            
            <!-- Row start -->
            <div class="row no-gutters h-100">               
                    <div class="col-12">
                    <div class="login-wrapper">
                        <div>
                            <div class="login-screen">
                                <div class="login-body pb-4">
                                    <a href="" class="login-logo">                                       
                                        <img src="../../assets/ultratech-cement-logo.png" class="center" alt="ultratech-cement">
                                    </a>
                                    <h6>In order to access your account, please enter the email id you provided during the registration process.</h6>
                                    <div v-if="status==0">
                                    <div class="field-wrapper mb-3">
                                        <input type="email" placeholder="Enter your emial id" class="form-control" :class="{'is-invalid': errors.email}" v-model="user.email" ref="email">
                                        <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        <div class="field-placeholder">Email ID</div>
                                    </div>
                                    </div>
                                     <!-- <div v-if="status==1">
                                     <div class="field-wrapper mb-3">
                                        <input type="email" placeholder="Enter your emial id" class="form-control" :class="{'is-invalid': errors.email}" v-model="user.email" ref="email">
                                        <span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
                                        <div class="field-placeholder">Email ID</div>
                                    </div> 
                                    <div class="field-wrapper mb-3">
                                        <input type="text" placeholder="Enter your Digit Number" class="form-control" :class="{'is-invalid': errors.code}" v-model="user.code" ref="digit_number" >
                                          <span v-if="errors.code" class="invalid-feedback">{{ errors.code[0] }}</span>
                                        <div class="field-placeholder">Enter 4 Digit Number</div>
                                    </div>
                                      <div class="field-wrapper mb-3">
                                        <input type="password" class="form-control" :class="{'is-invalid': errors.password}" placeholder="Password" v-model="user.password" ref="password" >
                                        <span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
                                        <div class="field-placeholder">Password</div>
                                    </div>
                                       <div class="field-wrapper mb-3">
                                        <input type="password" class="form-control" :class="{'is-invalid': errors.confirm_password}" placeholder="Re-Enter Password" v-model="user.confirm_password" ref="confirm_password">
                                        <span v-if="errors.confirm_password" class="invalid-feedback">{{ errors.confirm_password[0] }}</span>
                                        <div class="field-placeholder">Confirm Password</div>
                                    </div>
                                    </div> -->
                                    <div class="actions">
                                        <button v-if="status==0" type="submit" class="btn btn-danger ms-auto" @click="forgotPassword">Submit</button>
                                        <!-- <button  v-if="status==1" type="submit" class="btn btn-danger ms-auto" @click="resetPassword" >Reset</button> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
</template>
<script>
export default{
    data(){
        return{
            user:{
                    email:'',
                    password:'',
                    code:'',
                    confirm_password:''
                },
                status:0,
                errors:[]
        }
    },
    mounted(){
        console.log(this.$route);
        let vm =this;
        vm.$refs.email.focus();
        vm.status=false;
        },
        methods:{
            forgotPassword() {               
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store.dispatch('auth',{'uri':'forgotPassword','data':vm.user})
                .then(function () {
                    loader.hide();
                    vm.$store.dispatch('success','A reset link has been sent to your email address.');
                    vm.$router.push('/login');
                    vm.status=true;
                    vm.errors=[];
                })
                .catch(function (error) {
                    loader.hide();                  
                     vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },           
        }

}
</script>
<style>
</style>