// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from 'vue-router'
import store from '@/store/index.js';

import Register from '@/views/auth/Register'
import Login from '@/views/auth/Login'
import Forgot from '@/views/auth/Forgot'
import ResetPassword from '@/views/auth/ResetPassword'
import UpdateProfile from '@/views/user/UpdateProfile'
import Section from '@/views/configuration/Section'
import Department from '@/views/configuration/Department'
import Equipment_Type from '@/views/configuration/EquipmentType'
import Function from '@/views/configuration/Function'
import Role from '@/views/configuration/Role'

import Frequency from '@/views/configuration/Frequency'
import Component_Type from '@/views/configuration/ComponentType'
import EquipmentGroup from '@/views/configuration/EquipmentGroup'
import Plant from '@/views/configuration/Plant'
import Check_Type from '@/views/configuration/CheckType'
import Master from '@/views/master/Master'
//import Daviated from '@/views/deviated/Daviated'


import CreateComponent from '@/views/component/create'
import Components from '@/views/component/index'
import ViewComponent from '@/views/component/view'

import CreateUser from '@/views/users/create'
import Users from '@/views/users/index'

import createEquipment from '@/views/equipment/create'
import equipments from '@/views/equipment/index'
import ShowEquipment from '@/views/equipment/show'

import createTemplate from '@/views/template/create'
import templates from '@/views/template/index'

import ShowTemplate from '@/views/template/show'
import Categories from '@/views/configuration/Category'
import Configuration from '@/components/ConfigList'
import checklist from '@/views/checklist/index'

import deviatedComponentReport from '@/views/reports/DeviatedComponentReport'
import complianceReport from '@/views/reports/ComplianceReport'
import severityReport from '@/views/reports/SeverityReport'
import dailyReport from '@/views/reports/DailyReport'

import Home from '@/views/Home'
import Error from '@/views/Error'


import Permissions from '@/views/permission/index.vue'
import CreatePermission from '@/views/permission/create.vue'

import Permissionnew from '@/views/permissionnew.vue'
import SwitchToPlant from '@/views/switchtoplant.vue'

import Escalation from '@/views/configuration/Escalation'
import EscalationConfiguration from '@/views/configuration/EscalationConfiguration'

import Ticket from '@/views/ticket/index.vue'
import CreateTicket from '@/views/ticket/create.vue'
import ViewTicket from '@/views/ticket/view.vue'

const routes = [
    {
        path:'/login',
        name:'Login',
        component:Login,
        meta: { unAuth: true, permission:'All' }    
    },
    {
        path:'/register',
        name:'Register',
        component:Register,
        meta: { unAuth: true } 
    },
    {
        path:'/configuration',
        name:'Configuration',
        component:Configuration,
         meta: { auth: true, permission:'All' }   
    },
    {
        path:'/forgot',
        name:'Forgot',
        component:Forgot,
        meta: { unAuth: true, permission:'All' } 
    },

    {
        path:'/resetpassword/:token/:email',
        name:'ResetPassword',
        component:ResetPassword,
        meta: { unAuth: true, permission:'All' } 
    },

    {
        path:'/',
        name:'Home',
        component:Home,
        meta: { auth: true, permission:'All' }  
    },
    {
        path:'/home',
        name:'Dashboard',
        component:Home,
        meta: { auth: true, permission:'All' }  
    },
    {
        path:'/error',
        name:'Error',
        component:Error,
        meta: { auth: true, permission:'All' }  
    },
    {
        path:'/user/updateprofile',
        name:'UpdateProfile',
        component:UpdateProfile,
        meta: { auth: true, permission:'All' } 
    },
    {
        path:'/role',
        name:'Role',
        component:Role,
        meta: { auth: true, permission:'roles.view' } 
    },
    {
        path:'/frequency',
        name:'Frequency',
        component:Frequency,
        meta: { auth: true, permission:'frequencies.view' } 
    },
    {
        path:'/plant',
        name:'Plant',
        component:Plant,
        meta: { auth: true, permission:'plants.view' } 
    },
    {
        path:'/section',
        name:'Section',
        component:Section,
        meta: { auth: true, permission:'sections.view' } 
    },
    {
        path:'/department',
        name:'Department',
        component:Department,
        meta: { auth: true, permission:'departments.view' } 
    },

    


    //Permissions
	{ 
		path: '/permissions',
        name: 'Permissions.index', 
		component: Permissions, 
        meta: { auth: true, permission:'permissions.view' }   
	},
	{ 
		path: '/permissions/create', 
        name: 'Permissions.Create', 
		component:CreatePermission, 
        meta: { auth: true, permission:'permissions.create' }   
	},
	{ 
		path: '/permissions/:permission_id/edit',
        name:'Permissions.Edit', 
		component:CreatePermission,   
        meta: { auth: true, permission:'permissions.update' }   
	},
    { 
		path: '/permissionsnew',
        name:'Permissions', 
		component:Permissionnew,   
        meta: { auth: true, permission:'All' }   
	},

    {
        path:'/equipment_type',
        name:'Equipment_Type',
        component:Equipment_Type,
        meta: { auth: true, permission:'equipment_types.view' }   
    },
    {
        path:'/master',
        name:'Master',
        component:Master,
        meta: { auth: true, permission:'masters.view' }   
    },
    // {
    //     path:'/deviated',
    //     name:Daviated,
    //     component:Daviated,
    //     meta: { auth: true }
    // },
    
    {
        path:'/function',
        name:'Function',
        component:Function,
        meta: { auth: true, permission:'functionals.view' }   
    },
    {
        path:'/component_type',
        name:'Component_Type',
        component:Component_Type,
        meta: { auth: true, permission:'component_types.view' }   
    },
    {
        path:'/check_type',
        name:'Check_Type',
        component:Check_Type,
        meta: { auth: true, permission:'check_types.view' }   
    },
    {
        path:'/categories',
        name:'Categories',
        component:Categories,
        meta: { auth: true, permission:'categories.view' }   
    },
    {
        path:'/equipment_group',
        name:'EquipmentGroup',
        component:EquipmentGroup,
        meta: { auth: true, permission:'equipment_groups.view' }   
    },

    {
        path:'/components/create',
        name:'Component.Create',
        component:CreateComponent,
        meta: { auth: true, permission:'components.create' }   
    },
    {
        path:'/components',
        name:'Component.Index',
        component:Components,
        meta: { auth: true, permission:'components.view' }   
    },

    {
        path:'/users/create',
        name:'User.Create',
        component:CreateUser,
        meta: { auth: true, permission:'users.create' }   
    },
    {
        path:'/users',
        name:'User.Index',
        component:Users,
        meta: { auth: true, permission:'users.view' }   
    },

    {
        path:'/components/:component_id/copy',
        name:'Component.Copy',
        component:CreateComponent,
        meta: { auth: true, permission:'components.create' }   
    },

    {
        path:'/components/:component_id/edit',
        name:'Component.Edit',
        component:CreateComponent,
        meta: { auth: true, permission:'components.update' }   
    },

    {
        path:'/components/:component_id/view',
        name:'Component.View',
        component:ViewComponent,
        meta: { auth: true, permission:'components.view' }   
    },

    {
        path:'/users/:user_id/edit',
        name:'User.Edit',
        component:CreateUser,
        meta: { auth: true, permission:'users.update' }   
    },

    {
        path:'/equipments/create',
        name:'Equipment.Create',
        component:createEquipment,
        meta: { auth: true, permission:'equipments.create' }   
    },
    {
        path:'/equipments/:equipment_id/edit',
        name:'Equipment.Edit',
        component:createEquipment,
        meta: { auth: true, permission:'equipments.update' }   
    },
    {
        path:'/equipments/:equipment_id/copy',
        name:'Equipment.Copy',
        component:createEquipment,
        meta: { auth: true, permission:'equipments.copy' }   
    },
    {
        path:'/equipments',
        name:'Equipment.Index',
        component:equipments,
        meta: { auth: true, permission:'equipments.view' }   
    },
    {
        path:'/equipments/:equipment_id',
        name:'Equipment.Show',
        component:ShowEquipment,
        meta: { auth: true, permission:'equipments.view' }   
    },

    {
        path:'/templates/create',
        name:'Templates.Create',
        component:createTemplate,
        meta: { auth: true, permission:'templates.create' }   
    },
    {
        path:'/templates',
        name:'Templates.Index',
        component:templates,
        meta: { auth: true, permission:'templates.view' }   
    },
    {
        path:'/templates/:template_id',
        name:'Templates.Show',
        component:ShowTemplate,
        meta: { auth: true, permission:'templates.view' }   
    },
    {
        path:'/templates/:template_id/edit',
        name:'Templates.Edit',
        component:createTemplate,
        meta: { auth: true, permission:'templates.update' }   
    },
    {
        path:'/checklist',
        name:'Checklist.Index',
        component:checklist,
        meta: { auth: true, permission:'checklists.view' }   
    },
    {
        path:'/severityReport',
        name:'Report.SeverityReport',
        component:severityReport,
        meta: { auth: true, permission:'severity_report.view' }   
    },
    {
        path:'/deviatedComponentReport',
        name:'Report.DeviatedComponentReport',
        component:deviatedComponentReport,
        meta: { auth: true, permission:'deviated_component_report.view' }   
    },
    {
        path:'/complianceReport/:department_id',
        name:'Report.ComplianceReport.Edit',
        component:complianceReport,
        meta: { auth: true, permission:'compliance_report.view' } 
    },

    

    {
        path:'/complianceReport',
        name:'Report.ComplianceReport',
        component:complianceReport,
        meta: { auth: true, permission:'compliance_report.view' }   
    },
    {
        path:'/dailyReport',
        name:'Report.DailyReport',
        component:dailyReport,
        meta: { auth: true, permission:'daily_report.view' }   
    },
    {
        path:'/escalation',
        name:'Escalation',
        component:Escalation,
        meta: { auth: true, permission:'escalations.view' } 
    },
    {
        path:'/escalationConfiguration',
        name:'EscalationConfiguration',
        component:EscalationConfiguration,
        meta: { auth: true, permission:'escalationConfigurations.view' } 
    },
  
    {        
		path: '/switchtoplant',
        name:'SwitchToPlant', 
		component:SwitchToPlant,
        meta: { auth: true, permission:'compliance_report.view' } 
    },
    {
        path:'/tickets',
        name:'Ticket.Index',
        component:Ticket,
        meta: { auth: true, permission:'tickets.view' }   
    },

    {
        path:'/tickets/create',
        name:'Ticket.Create',
        component:CreateTicket,
        meta: { auth: true, permission:'tickets.create' }   
    },
    {
        path:'/tickets/:ticket_id/edit',
        name:'Ticket.Edit',
        component:CreateTicket,
        meta: { auth: true, permission:'tickets.update' }   
    },

    {
        path:'/tickets/:ticket_id/view',
        name:'Ticket.View',
        component:ViewTicket,
        meta: { auth: true, permission:'tickets.view' }   
    },
    

]

// const router = createRouter({
//     history: createWebHashHistory(),
//     // history: createWebHistory(process.env.BASE_URL),
//     routes
// })

// router.beforeEach(function(to, _, next) {
// 	if (to.meta.auth && !store.getters.authenticated) {
// 		next('/login');
// 	} 
// 	else if(to.meta.unAuth && store.getters.authenticated) {
// 		next('/home')
// 	}
// 	else {
// 		next();
// 	}
// });

// export default router



const router = createRouter({
	history: createWebHashHistory(),
	routes
})

router.beforeEach(function(to, _, next) {
	if (to.meta.auth && !store.getters.user) {
		next('/login');
	} 
	else if(to.meta.unAuth && store.getters.user) {
		next('/')//pointing to home
	}
	// else {
	// 	next();
	// }
    else {
		let permissions = store.getters.permissions;
		if(to.meta.permission=='All') {
			next();
		}
		else if(permissions.length!=0) {
            let permission = permissions.filter(function (el) {
                return el == to.meta.permission || to.meta.permission=='All';
            });
            if (!permission[0]) {
                next('/error')
            }
            else {
                next();
            }
		}
		else {
			next('/error')
		}
    }    
});
export default router
