<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Users</h5>
                        </div>
                        <div class="card-body">
                        <div class="col-sm-12">
                            
                            <div class="input-group">
                                
                                <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                                
                            </div>
                        </div>     
                            <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('name')">
                                                Name
                                            <span>
                                                    <i v-if="meta.field=='name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th @click="sort('email')">
                                                Email
                                            <span>
                                                    <i v-if="meta.field=='email' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='email' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('mobile_number')">
                                                Mobile No
                                            <span>
                                                    <i v-if="meta.field=='mobile_number' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='mobile_number' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>     
                                            </th>
                                            <th @click="sort('role_name')">
                                                Role
                                            <span>
                                                    <i v-if="meta.field=='role_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='role_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('plant_name')">
                                                Plant
                                            <span>
                                                    <i v-if="meta.field=='plant_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('department_name')">
                                                Department
                                            <span>
                                                    <i v-if="meta.field=='department_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='department_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="users.length==0">
                                            <td  colspan="8" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(user,key) in users" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{ user.name }}</td>
                                            <td>{{ user.email }}</td>
                                            <td>{{ user.mobile_number }}</td>
                                            <td>{{ user.role.role_name }}</td>
                                            <td>{{ user.plant.plant_name}}</td>
                                            <td>{{ user.department.department_name}}</td>
                                            <td class="td-actions">
                                                <a href="#" @click.prevent="resetPassword(user)" v-can="'users.reset_password'" class="icon green">
                                                    <i class="icon-lock"></i>
                                                </a>
                                                <router-link v-can="'users.update'" :to="'/users/'+user.user_id+'/edit'" class="icon blue">
                                                    <i class="icon-pencil"></i>
                                                </router-link>
                                                <a href="#" v-can="'users.delete'" class="icon red" @click.prevent="deleteRow(user)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Pagination from "@/components/Pagination.vue";
    export default {
        components: { Pagination },
        data() {
            return {
                status: true,
                errors: [],
                users: [],
                meta: {
                    search: "",
                    order_by: "asc",
                    field: "name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    plant_id: this.$store.getters.user.plant_id,
                },
            };
        },
        mounted() {
            let vm = this;
            vm.index();
        },
        methods: {

           index() {
				let vm = this;
				let uri = 'paginateUsers?page='+vm.meta.currentPage+'&search='+vm.meta.search+'&order_by='+vm.meta.order_by+'&field='+vm.meta.field+'&per_page='+vm.meta.per_page+'&plant_id='+vm.meta.plant_id;
				vm.$store.dispatch('post',{'uri':uri})
				.then(function (response) {
					vm.users = response.data.data;
					// console.log(vm.users)
					vm.meta.totalRows = response.data.meta.total;
                    vm.meta.lastPage = response.data.meta.last_page;
                    vm.meta.from = response.data.meta.from;
                    vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
				})
				.catch(function (error) {
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			},            

                      

            deleteRow(user) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    vm.$store.dispatch("delete", { uri: "deleteUser/" + user.user_id })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "User is successfully deleted");
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            resetPassword(user) {
                let vm = this;
                if (confirm("Do you want to reset the password ?")) {
                    let loader = vm.$loading.show();
                    vm.$store.dispatch("post", { uri: "resetUserPassword",data:user})
                    .then(function (response) {
                        loader.hide();
                        vm.$store.dispatch("success", "User password has been successfully updated");
                        alert("New Password : " + response.data)
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
                }
            },

            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },

            search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			}  
        },
    };
</script>
