<template>
	<div class="container-fluid h-100">
		<div class="row g-0 h-100">
			<div class="col-12">
				<div class="login-wrapper">
					<form @submit.prevent="login">
						<div class="login-screen">
							<div class="login-body">
								<div class="login-head">
									<a href="#" class="login-logo">
										<img src="../../assets/ultratech-cement-logo.png" alt="Logo" />
									</a>
									<h6>
										Welcome back,<br />
										Please login to your account.
									</h6>
								</div>
								<div class="field-wrapper">
									<input id="email" type="email" class="form-control" :class="{'is-invalid': errors.email}" placeholder="Enter Email ID" v-model="user.email" ref="email" />
									<span v-if="errors.email" class="invalid-feedback">{{ errors.email[0] }}</span>
									<div class="field-placeholder">Email ID <span class="text-danger">*</span></div>
								</div>
								<div class="field-wrapper">
									<input id="password" type="password" class="form-control" :class="{'is-invalid': errors.password}" placeholder="Enter Password" v-model="user.password" />
									<span v-if="errors.password" class="invalid-feedback">{{ errors.password[0] }}</span>
									<div class="field-placeholder">Password <span class="text-danger">*</span></div>
								</div>
								<div class="actions" style="padding-bottom:10px;">
									<!-- <router-link :to="{ path: '/forgot' }">Forgot password?</router-link> -->
									<button id="login" type="submit" class="btn btn-primary">Login</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        data() {
            return {
                user: {
                    email: "",
                    password: "",
                },
                errors: [],
            };
        },

        mounted() {
            this.$refs.email.focus();
        },

        methods: {
            login() {
                let vm = this;
                let loader = vm.$loading.show();
                vm.$store .dispatch("auth", { uri: "login", data: vm.user })
				
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch("success", "Successfully logged in");
					vm.$store.dispatch("setUser", response.data);
					vm.getPermissions()
					//vm.$router.push("/home");
					//window.location.reload();
				})
				.catch(function (error) {
					loader.hide();
					if(error.response.data.message=='Too Many Attempts.') {
						vm.errors = {email:['Too Many Attempts, Login after sometimes']};
					}
					else {
						vm.errors = error.response.data.errors;
					}
					vm.$store.dispatch("error", error.response.data.message);
				});
            },

		

			getPermissions() {
				let vm = this;
				let loader = vm.$loading.show();
               // let uri = {'uri':'getAbilities?user='+vm.$store.getters.user};
					vm.$store.dispatch("post", { uri: "getAbilities", data: vm.$store.getters.user })
				//vm.$store.dispatch('post',uri)
				.then(function (response) {
					loader.hide();
					vm.$store.dispatch('setPermissions',response.data);
					location.reload();
				})
				.catch(function (error) {
					loader.hide();
					vm.errors = error.response.data.errors;
					vm.$store.dispatch('error',error.response.data.message);
				});
			}

        },
    };
</script>	