<template>
    <div class="content-wrapper-scroll">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Templates</h5>
                        </div>
                        <div class="card-body">
                        <div class="row gutters">
                                <div class="col-sm-3 ">
                                      <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.department_id }"
                                            :customClass="{ 'is-invalid': errors.department_id }"
                                            :initialize="meta.department_id"
                                            id="department_id"
                                            label="department_name"
                                            placeholder="Select Department"
                                            :data="departments"
                                            @input="departments => meta.department_id = departments"
                                        >
                                        </search>
                                        <span v-if="errors.department_id" class="invalid-feedback">{{ errors.department_id[0] }}</span>
                                        <div class="field-placeholder">Department</div>
                                    </div>
                                 </div>
                                 <div class="col-sm-3">
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.functional_id }"
                                            :customClass="{ 'is-invalid': errors.functional_id }"
                                            :initialize="meta.functional_id"
                                            id="functional_id"
                                            label="functional_name"
                                            placeholder="Select Functional"
                                            :data="functionals"
                                            @input="functionals => meta.functional_id = functionals"
                                        >
                                        </search>
                                        <span v-if="errors.functional_id" class="invalid-feedback">{{ errors.functional_id[0] }}</span>
                                        <div class="field-placeholder">Functional</div>
                                    </div>
                                 </div> 
                                 <div class="col-sm-3">  
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.category_id }"
                                            :customClass="{ 'is-invalid': errors.category_id }"
                                            :initialize="meta.category_id"
                                            id="category_id"
                                            label="category_name"
                                            placeholder="Select Category"
                                            :data="categories"
                                            @input="categories => meta.category_id = categories"
                                        >
                                        </search>
                                        <span v-if="errors.category_id" class="invalid-feedback">{{ errors.category_id[0] }}</span>
                                        <div class="field-placeholder">Category</div>
                                    </div>                                     
                                </div>
                                <div class="col-sm-3">  
                                    <div class="field-wrapper">
                                        <search
                                            :class="{ 'is-invalid': errors.frequency_id }"
                                            :customClass="{ 'is-invalid': errors.frequency_id }"
                                            :initialize="meta.frequency_id"
                                            id="frequency_id"
                                            label="frequency_name"
                                            placeholder="Select Frequency"
                                            :data="frequencies"
                                            @input="frequencies => meta.frequency_id = frequencies"
                                        >
                                        </search>
                                        <span v-if="errors.frequency_id" class="invalid-feedback">{{ errors.frequency_id[0] }}</span>
                                        <div class="field-placeholder">Frequency</div>
                                    </div>                                    
                                </div>

                                 <div class="col-sm-7">  
                                    <div class="input-group field-wrapper">
                                        <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                                                        <span class="input-group-text">
                                                                            <i class="fas fa-search"></i> </span> &nbsp;
                                    </div>                                    
                                </div>
                             
                                <div class="col-sm-5" style="justify-content: space-between;">
                                   <button type="submit" v-if="status" @click="index" class="btn btn-success btn-block btnn">Search</button>
                                
                                   <button type="submit" v-if="status" @click="reset" class="btn  btn-danger btn-block btnn">Reset</button>
                                
                                    <a v-can="'templates.export'" :href="$store.getters.pdfUrl+'downloadTemplate?plant_id='+$store.getters.user.plant_id+'&page='+meta.currentPage+'&per_page='+meta.per_page+'&field='+meta.field+'&order_by='+meta.order_by+'&department_id='+meta.department_id+'&functional_id='+meta.functional_id+'&category_id='+meta.category_id+'&frequency_id='+meta.frequency_id+'&search='+meta.search" target="_blank" type="button" class="btn btnn  btn-primary">Download</a>
                               
                                     <button type="button" v-can="'templates.import'"  class="btn btnn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal"> Upload</button>
                                </div>
                                
                        </div>
                      
                          
                        <div v-if="errors.length!=0" class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="errors.length==0">
                                            <td colspan="11" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(error,key) in errors" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{ error }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                        <div class="table-responsive tabletop">
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('template_name')">
                                                Template Name
                                                <span>
                                                    <i v-if="meta.field=='template_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='template_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('plant_name')">
                                                Plant
                                                <span>
                                                    <i v-if="meta.field=='plant_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='plant_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('department_name')">
                                                Department
                                                <span>
                                                    <i v-if="meta.field=='department_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='department_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('functional_name')">
                                                Functional
                                                <span>
                                                    <i v-if="meta.field=='functional_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='functional_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('category_name')">
                                                Category
                                                <span>
                                                    <i v-if="meta.field=='category_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='category_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('frequency_name')">
                                                Frequency
                                                <span>
                                                    <i v-if="meta.field=='frequency_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='frequency_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('lat')">
                                                Latitude
                                                <span>
                                                    <i v-if="meta.field=='lat' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='lat' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('long')">
                                                Longitude
                                                <span>
                                                    <i v-if="meta.field=='long' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='long' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th @click="sort('radius')">
                                                Radius
                                                <span>
                                                    <i v-if="meta.field=='radius' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='radius' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                                </span>
                                            </th>
                                            <th class="text-center">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-if="templates.length==0">
                                            <td colspan="11" class="text-center">No records found</td>
                                        </tr>
                                        <tr v-for="(template,key) in templates" :key="key">
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{ template.template_name }}</td>
                                            <td>{{ template.plant.plant_name }}</td>
                                            <td>{{ template.department.department_name }}</td>
                                            <td>{{ template.functional.functional_name }}</td>
                                            <td>{{ template.category.category_name }}</td>
                                            <td>{{ template.frequency.frequency_name }}</td>
                                            <td>{{ template.lat }}</td>
                                            <td>{{ template.long }}</td>
                                            <td>{{ template.radius }}</td>
                                            <td class="td-actions">
                                                <router-link v-can="'templates.view'" :to="'/templates/'+template.template_id" class="icon green">
                                                    <i class="icon-eye"></i>
                                                </router-link>
                                                <a target="_blank" :href="$store.getters.pdfUrl+'downloadTemplateBarcodes?template_id='+template.template_id" v-can="'templates.barcode'" class="icon red">
                                                    <i class="icon-download"></i>
                                                </a>
                                                <router-link v-can="'templates.update'" :to="'/templates/'+template.template_id+'/edit'" class="icon blue">
                                                    <i class="icon-pencil"></i>
                                                </router-link>
                                                <a href="#" v-can="'templates.delete'" class="icon red" @click.prevent="deleteRow(template)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                        </div>
                        <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Upload Templates</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <!-- <div class="col-sm-6">
                                    <div class="input-group">
                                        <a :href="$store.getters.pdfUrl+'downloadTemplate?plant_id='+$store.getters.user.plant_id" target="_blank" class="btn btn-primary btn-block">Download Templates</a>
                                    </div>
                                </div> -->
                            <div class="col-sm-12">
                                <div class="col-6">
                                    <div class="input-group">
                                        <input class="file-input" type="file" id="fileLoader" ref="file" name="file" required />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="onUpload">Upload Template</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.btnn{
    margin-top: 0.5rem;
}
</style>

<script>
    let Search = require("@/components/Search.vue").default;
    import Pagination from "@/components/Pagination.vue";

    export default {
        components: { Pagination, Search },
        data() {
            return {
                status: true,
                errors: [],
                templates: [],
                departments: [],
                functionals: [],
                categories:[],
                frequencies:[],
                meta: {
                    department_id: "",
                    functional_id: "",
                    category_id:"",
                    frequency_id:"",
                    search: "",
                    order_by: "desc",
                    field: "template_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                    plant_id: this.$store.getters.user.plant_id,
                },
            };
        },
        mounted() {
            this.getDepartment();
            this.index();
        },
        methods: {
            index() {
                let vm = this;
                let uri = "templates?page=" + vm.meta.currentPage + "&search=" + vm.meta.search + "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + "&per_page=" + vm.meta.per_page + "&plant_id=" + vm.meta.plant_id + "&department_id=" + vm.meta.department_id + "&functional_id=" + vm.meta.functional_id + "&category_id=" + vm.meta.category_id + "&frequency_id=" + vm.meta.frequency_id ;
                vm.$store
                    .dispatch("post", { uri: uri })
                    .then(function (response) {
                        response.data.data;
                        vm.templates = response.data.data;
                        vm.meta.totalRows = response.data.meta.total;
                        vm.meta.lastPage = response.data.meta.last_page;
                        vm.meta.from = response.data.meta.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
            reset()
            {
                let vm = this;
                vm.meta.department_id="";
                vm.meta.functional_id="";
                vm.meta.category_id="";
                vm.meta.frequency_id="";
                vm.meta.search="";
                vm.index();
            },
           
            getDepartment() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getDepartments" })
                    .then(function (response) {
                        vm.departments = response.data.data;
                        vm.getFunctions();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
          
            getFunctions() {
                let vm = this;
                vm.$store
                    .dispatch("post", { uri: "getFunctionals" })
                    .then(function (response) {
                        vm.functionals = response.data.data;
                        vm.getCategories();
                    })
                    .catch(function (error) {
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },
         
            getCategories() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getCategories" })
                .then(function (response) {
                    vm.categories = response.data.data;
                    vm.getFrequencies();
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
          
             getFrequencies() {
                let vm = this;
                vm.$store.dispatch("post", { uri: "getFrequencies" })
                .then(function (response) {
                    vm.frequencies = response.data.data;
                   
                })
                .catch(function (error) {
                    vm.errors = error.response.data.errors;
                    vm.$store.dispatch("error", error.response.data.message);
                });
            },
            deleteRow(template) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    vm.$store
                        .dispatch("delete", { uri: "deleteTemplate/" + template.template_id })
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Template is successfully deleted");
                            vm.index();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },

            /*  downLoadTempalteNew1(){
           
            axios({
                    url: 'http://192.168.6.70:8080/CheckList/utcl-check-list-laravel-app/public/api/downloadTemplate?plant_id=1', // File URL Goes Here
                    method: 'post',
                    responseType: 'blob',
                }).then((response) => {
                     const url = URL.createObjectURL(new Blob([response.data], {
                            type: 'application/vnd.ms-excel'
                        }))                                        
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'test')
                    document.body.appendChild(link)
                    link.click()
                });
      },
     downLoadTempalteNew(){
                axios({
                            url: 'http://192.168.6.70:8080/CheckList/utcl-check-list-laravel-app/public/api/downloadTemplate?plant_id=1',
                                method: 'post',
                                responseType: 'blob',
                            }).then((res) => {
                                var FILE = window.URL.createObjectURL(new Blob([res.data]));
                                
                                var docUrl = document.createElement('x');
                                docUrl.href = FILE;
                                docUrl.setAttribute('download', 'file.excel');
                                document.body.appendChild(docUrl);
                                docUrl.click();
                            });
            },
*/

            onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },

            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by == "asc" ? "desc" : "asc";
                this.index();
            },
            search() {
                let vm = this;
                vm.meta.currentPage = 1;
                vm.index();
            },

            onUpload() {
                let loader = this.$loading.show({
                    container: this.fullPage ? null : this.$refs.formContainer,
                    canCancel: true,
                    onCancel: this.onCancel,
                });

                const formData = new FormData();
                formData.append("file", this.$refs.file.files[0]);

                let vm = this;
                vm.$store
                    .dispatch("uploadFile", { uri: "importTemplates", data: formData })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "File uploaded successfully");
                        vm.clearSelectedFile();
                        vm.index();
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.message;
                        // let err1="Integrity constraint violation: 1048 Column 'description' cannot be null (SQL: insert into `check_types` (`check_type_name`, `description`, `default_value`, `field_type`, `ucl`, `lcl`, `updated_at`, `created_at`)";

                        if (error.response.data.message == "Call to a member function getClientOriginalExtension() on null") {
                            vm.$store.dispatch("error", "Please Select file");
                        } else {
                            vm.$store.dispatch("error", error.response.data.message);
                        }
                        vm.clearSelectedFile();
                        vm.index();
                    });
            },

            clearSelectedFile() {
                var fileLoader = document.getElementById("fileLoader");
                //clear file value
                if (fileLoader.files.length > 0) {
                    //alert(fileLoader.files[0].name);
                    fileLoader.value = null;
                }
            },
        },
    };
</script>
