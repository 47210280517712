<template>
<!-- Content wrapper scroll start -->
 <div class="content-wrapper-scroll">

    <!-- Content wrapper start -->
    <div class="content-wrapper">

      <!-- form row -->
        <div class="row">
            <div class="col-sm-4">
                <div class="card">
                 <div class="card-header">
                            <h5>New Section</h5>
                        </div>
                    <div class="card-body">
                        <!-- Field wrapper start -->
                        <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.section_name}" placeholder="Name" v-model="section.section_name" ref="name">
                            <span v-if="errors.section_name" class="invalid-feedback">{{ errors.section_name[0] }}</span>
                            <div class="field-placeholder">Section Name <span class="text-danger">*</span>
                            </div>
                        </div>
                        <!-- Field wrapper end -->
                         <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.section_head}" placeholder="Section Head" v-model="section.section_head" ref="section_head">
                            <span v-if="errors.section_head" class="invalid-feedback">{{ errors.section_head[0] }}</span>
                            <div class="field-placeholder">Section Head
                            </div>
                        </div>

                        <div class="field-wrapper">
                            <input class="form-control" type="text" :class="{'is-invalid': errors.section_head_phone}" placeholder="Section Head Phone" v-model="section.section_head_phone" ref="section_head_phone">
                            <span v-if="errors.section_head_phone" class="invalid-feedback">{{ errors.section_head_phone[0] }}</span>
                            <div class="field-placeholder">Phone no
                            </div>
                        </div>
                         <div class="field-wrapper">
                            <input class="form-control" type="email" :class="{'is-invalid': errors.section_head_email}" placeholder="Section Head Email" v-model="section.section_head_email" ref="section_head_email">
                            <span v-if="errors.section_head_email" class="invalid-feedback">{{ errors.section_head_email[0] }}</span>
                            <div class="field-placeholder">Email Id
                            </div>
                        </div>

                        <!-- Field wrapper start -->
                        <div class="field-wrapper">
                            <textarea class="form-control" rows="2" :class="{'is-invalid': errors.description}" placeholder="description" v-model="section.description" ref="description"></textarea>
                            <span v-if="errors.description" class="invalid-feedback">{{ errors.description[0] }}</span>
                            <div class="field-placeholder">Description <span class="text-danger">*</span>
                            </div>
                        </div>
                        <!-- Field wrapper end -->
                       <div class="form-actions-footer">
                                <div class="text-end">
                                    <button class="btn btn-danger" @click="reset">Discard</button>
                                    <button v-can="'sections.create'" class="btn btn-primary ms-1" v-if="status" @click="addSections">Submit</button>
                                    <button v-can="'sections.update'" class="btn btn-primary ms-1" v-else @click="updateSection">Update</button>
                                </div>
                            </div>
                     
                    </div>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="card">
                 <div class="card-header">
                            <h5>Sections</h5>
                        </div>
                    <div class="card-body">
                       
                        <div class="col-sm-12">
                            <div class="input-group">
                                <input class="form-control" type="text" placeholder="Type here and press enter to search" v-model="meta.search" @keyup.enter="search">
                                <span class="input-group-text">
                                    <i class="fas fa-search"></i>
                                </span>
                            </div>
                        </div>

                        <div class="table-responsive" style="margin-top: 10px;">
                           
                                <table class="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th @click="sort('section_name')">
                                                Section
                                            <span>
                                                    <i v-if="meta.field=='section_name' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='section_name' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>
                                            </th>
                                            <th @click="sort('description')">
                                                Description
                                            <span>
                                                    <i v-if="meta.field=='description' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='description' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>    
                                            </th>
                                            <th @click="sort('section_head')">
                                                Head
                                            <span>
                                                    <i v-if="meta.field=='section_head' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='section_head' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('section_head_phone')">
                                                Phone
                                            <span>
                                                    <i v-if="meta.field=='section_head_phone' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='section_head_phone' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span> 
                                            </th>
                                            <th @click="sort('section_head_email')">
                                                Email
                                            <span>
                                                    <i v-if="meta.field=='section_head_email' && meta.order_by=='asc'" class="fas fa-sort-up"></i>
                                                    <i v-else-if="meta.field=='section_head_email' && meta.order_by=='desc'" class="fas fa-sort-down"></i>
                                                    <i v-else class="fas fa-sort"></i>
                                            </span>             
                                            </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                         <tr v-if="sectionss.length==0">
                                            <td  colspan="7" class="text-center">No records found</td>
                                        </tr>
    
                                        <tr v-for="(sections,key) in sectionss" :key="sections.section_id">
                                          
                                            <td>{{ meta.from+key }}</td>
                                            <td>{{sections.section_name}}</td>                                        
                                            <td>{{sections.description}}</td>
                                            <td>{{sections.section_head}}</td>
                                            <td>{{sections.section_head_phone}}</td>
                                            <td>{{sections.section_head_email}}</td>
                                            <td class="td-actions">
                                                <a href="#" v-can="'sections.update'" class="icon green" @click.prevent="edit(sections)">
                                                    <i class="icon-pencil"></i>
                                                </a>
                                                <a href="#" v-can="'sections.delete'" class="icon red" @click.prevent="deleteSection(sections.section_id)">
                                                    <i class="icon-cancel"></i>
                                                </a>
                                            </td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                          </div>   
                          <div class="form-actions-footer">
                                <div class="text-end row">
                                    <div class="col-sm-2">
                                        <select class="form-control" v-model="meta.per_page" @change="onPerPageChange">
                                            <option>10</option>
                                            <option>15</option>
                                            <option>20</option>
                                            <option>25</option>
                                            <option>30</option>
                                        </select>
                                    </div>
                                    <div class="col-sm-10">
                                        <Pagination class="pagination pagination-sm" :maxPage="meta.maxPage" :totalPages="meta.lastPage" :currentPage="meta.currentPage" @pagechanged="onPageChange" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import Pagination from '@/components/Pagination.vue';
export default{
    components: { Pagination },
data(){
    return{
         status: true,
        section:{
            section_name:'',
            description:'',
            section_id:'',
            section_head:'',
            section_head_phone:'',
            section_head_email:'',
        },
        errors:[],
        sectionss:[],
         meta: {
                    search: "",
                    order_by: "asc",
                    field: "section_name",
                    per_page: 10,
                    totalRows: 0,
                    currentPage: 1,
                    lastPage: 1,
                    from: 1,
                    maxPage: 1,
                },
    }
},
    mounted(){
            this.index();
        },
        methods: {

            index() {
                let vm = this;
                 let uri = "sections?page=" + vm.meta.currentPage + "&search=" + vm.meta.search +
                 "&order_by=" + vm.meta.order_by + "&field=" + vm.meta.field + 
                 "&per_page=" + vm.meta.per_page;
                    vm.$store.dispatch("post", { uri:uri})
                    .then(function (response) {
                        response.data.data;
                        console.log(response.data);
                        vm.sectionss = response.data.data;
                        vm.meta.totalRows = response.data.total;
                        vm.meta.lastPage = response.data.last_page;
                        vm.meta.from = response.data.from;
                        vm.meta.maxPage = vm.meta.lastPage >= 3 ? 3 : vm.meta.lastPage;
                        //console.log(vm.sections);
                    })
                    .catch(function (error) {
                        console.log(error)
                    //    vm.errors = error.response.data.errors;
                    // ,    vm.$store.dispatch("error", error.response.data.message);
                    });
            },
             addSections() {
             
                let vm = this;
                let loader = vm.$loading.show();
                //    console.log(vm.unit)
                vm.$store.dispatch("post", { uri: "addSection", data: vm.section })
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Section Added successfully");
                       vm.index();
                     vm. reset();
                      vm.errors=[];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },


            updateSection() {
                let vm = this;
                let loader = vm.$loading.show();
                let uri = { uri: "updateSection/" + vm.section.section_id, data: vm.section };
                vm.$store.dispatch("patch", uri)
                    .then(function () {
                        loader.hide();
                        vm.$store.dispatch("success", "Section is successfully updated");
                       vm.index()
                        vm.reset()
                        vm.status=true;
                         vm.errors=[];
                    })
                    .catch(function (error) {
                        loader.hide();
                        vm.errors = error.response.data.errors;
                        vm.$store.dispatch("error", error.response.data.message);
                    });
            },

            deleteSection(section) {
                let vm = this;
                if (confirm("Do you want to delete this entry ?")) {
                    let loader = vm.$loading.show();
                    let uri = { uri: "deleteSection/" + section };
                    vm.$store
                        .dispatch("delete", uri)
                        .then(function () {
                            loader.hide();
                            vm.$store.dispatch("success", "Section is successfully deleted");
                            vm.index();
                            vm.reset();
                        })
                        .catch(function (error) {
                            loader.hide();
                            vm.errors = error.response.data.errors;
                            vm.$store.dispatch("error", error.response.data.message);
                        });
                }
            },
            edit(sections) {
             
                let vm = this;
                (vm.section = sections), (vm.status = false), vm.$refs.name.focus();
               },
            reset(){
                let vm = this;
                vm.section.section_name = "";
                vm.section.description = "";
                vm.section.section_head="",
                vm.section.section_head_phone="",
                vm.section.section_head_email="",
                vm.$refs.name.focus();
                vm.errors=[];
                vm.status = true;
            },

             onPageChange(page) {
                this.meta.currentPage = page;
                this.index();
            },
            onPerPageChange() {
                this.meta.currentPage = 1;
                this.index();
            },
            sort(field) {
                this.meta.field = field;
                this.meta.order_by = this.meta.order_by=='asc' ? 'desc' : 'asc';
                this.index();
            },
            search() {
				let vm = this;
                vm.meta.currentPage=1;
                vm.index();
			}  
        },

}
</script>
<style>
.btn {
            
            margin-right: 10px;
        }
</style>